import React, { useEffect, useState } from "react";
import axios from "axios";
import { NavLink } from "react-router-dom";
import Cookies from "js-cookie";
import { notification } from "antd";
import { useNavigate } from "react-router";
import useNotification from '../../../useNotification'; // Import the custom hook
import SelectBatch from "./SelectBatch";
import { useGlobalLoader } from "../../../Loader/GlobalLoaderContext";
import { Select } from "antd";

const AddMediaVideo = () => {

  const clearFieldError = (fieldName) => {
    setFormErrors({ ...formErrors, [fieldName]: undefined });
  };

  useEffect(() => {
    const checkToken = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/admin/protected`,
          {
            headers: {
              Authorization: `Bearer ${Cookies.get("jwtoken")}`,
            },

            withCredentials: true, //============new
          }
        );
        const data = await response.data;
        // console.log(data);
      } catch (error) {
        navigate("/");
        // alert("Unauthorized");
      }
    };

    checkToken();
  }, []);
  const { showLoader, hideLoader } = useGlobalLoader();
  const { showNotificationWithMessage } = useNotification();

  const navigate = useNavigate();

  const [Mediacourses, setMediaCourses] = useState([]);
  const [mediaTitle, setMediaTitle] = useState("");

  // const [mediaSelectCourse, setMediaSelectCourse] = useState("");
  const [mediaSelectCourse, setMediaSelectCourse] = useState("all");

  const [mediaVideo, setMediaVideo] = useState(null);
  const [formErrors, setFormErrors] = useState({});

  const [batches, setBatches] = useState([]);
  const [batch, setBatch] = useState([]);

  useEffect(() => {
    getMediaCourses();
  }, []);

  const getMediaCourses = async () => {
    try {
      showLoader();
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/admin/GetMediaCourses`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${Cookies.get("jwtoken")}`,
        },

        withCredentials: true, // Send cookies with the request
      }
      );
      const result = response.data;
      setMediaCourses(result);
    } catch (error) {
      console.error("Error fetching Courses:", error);
    }
    finally {
      hideLoader();
    }
  };

  const validateForm = () => {
    const errors = {};

    if (!mediaTitle) {
      errors.mediaTitle = "Title is required";
    }

    if (!mediaVideo) {
      errors.mediaVideo = "Media Video is required";
    }

    return errors;
  };


  //add media
  const addMedias = async () => {
    // e.preventDefault();

    // if (
    //   !mediaTitle ||
    //   !mediaVideo
    // ) {
    //   showNotificationWithMessage('All fields are required', 'error');
    //   return;
    // }

    const errors = validateForm();

    if (Object.keys(errors).length > 0) {
      // Display validation errors
      setFormErrors(errors);
      return;
    }

    // Clear any previous validation errors
    setFormErrors({});


    const formData = new FormData();
    formData.append("mediaTitle", mediaTitle);
    formData.append("documentType", "video")
    formData.append("mediaSelectCourse", mediaSelectCourse);

    formData.append("mediaVideo", mediaVideo);

    formData.append("batch", batch);
    try {
      showLoader();
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/admin/AddMedia`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${Cookies.get("jwtoken")}`,
          },
          withCredentials: true, // Send cookies with the request
        }
      );
      console.warn(response.data);

      if (response.status === 200) {
        // notification.success({
        //   message: "Media added successfully",
        // });
        // navigate("/MediaVideo");
        showNotificationWithMessage('Media added successfully', 'success');
        setTimeout(() => {
          navigate('/videos');
        }, 1000);
      }
    } catch (error) {
      console.error("Error adding media:", error);
      // notification.error({
      //   message: "Error adding media"
      // });
      showNotificationWithMessage('Error adding media', 'error');

    }
    finally {
      hideLoader();
    }
  }

  const scrollToError = (formErrors, addMedias) => {
    if (formErrors) {
      const errorField = Object.keys(formErrors)[0];
      const field = document.getElementsByName(errorField)[0];
      if (field) {
        field.scrollIntoView({ behavior: "smooth", block: "center" });
      }
      addMedias();
    } else {
      addMedias();
    }
  }

  // const handleCourseChange = (e) => {
  //   // console.log(e.target.value)
  //   setMediaSelectCourse(e.target.value);
  //   setBatch([]);

  //   const selectedCourse = Mediacourses.find(
  //     (course) => course.url === (e.target.value)
  //   );
  //   // console.log(selectedCourse);
  //   if (selectedCourse) {
  //     setBatches(selectedCourse.batchIDs);
  //     // console.log(selectedCourse.batchIDs);
  //   }
  // };

  const handleCourseChange = (e) => {
    const selectedValue = e.target.value;
    console.log("handleCourseChange", selectedValue)

    if (selectedValue === "all" || selectedValue === "subscriber") {
      setMediaSelectCourse(selectedValue);
      setBatches([]);
    } else {
      const selectedCourse = Mediacourses.find(course => course.url === selectedValue);

      if (selectedCourse) {
        setMediaSelectCourse(selectedValue);
        setBatches(selectedCourse.batchIDs || []);
      } else {
        // Clear mediaSelectCourse and batches if course not found
        setMediaSelectCourse("");
        setBatches([]);
      }
    }
  };


  return (
    <>
      <div className="content-wrapper">
        <div
          className="container-full"
        >
          <div className="content-header">
            <div className="d-flex align-items-center">
              <div className="mr-auto">
                <h3 className="page-title">Add Video</h3>
                <div className="d-inline-block align-items-center">
                  <nav>
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item"><NavLink to="/dashboard">Dashboard</NavLink></li>
                      <li className="breadcrumb-item" aria-current="page"><NavLink to="/images">videos</NavLink></li>
                      <li className="breadcrumb-item active" aria-current="page">Add Video</li>
                    </ol>
                  </nav>
                </div>
              </div>

            </div>
          </div>
          <section className="content mt-5">
            <div className="row">

              {/* <form>/ */}
              <div className="col-lg-12 col-12 mx-auto">
                {/* <!-- box1 --> */}

                <div className="box">

                  <div className="box-body boxpadding pt-4">
                    <div className="row pt-3">
                      <div className="col-md-12">
                        <div className="form-group">
                          <label className="labeltext">Title <span className="aesterik">*</span></label>
                          <input
                            type="text"
                            name="title"
                            // className="form-control"
                            className={`form-control ${formErrors.mediaTitle ? "is-invalid" : ""
                              }`}
                            placeholder="title"
                            value={mediaTitle}
                            onChange={(e) => {
                              setMediaTitle(e.target.value);
                              clearFieldError("mediaTitle");
                            }}

                          />
                          {formErrors.mediaTitle && (
                            <div className="invalid-input">
                              {formErrors.mediaTitle}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div className="form-group">
                          <label className="labeltext">Media Video <span className="aesterik">*</span></label>
                          <input
                            type="file"
                            // className="form-control"
                            className={`form-control ${formErrors.mediaVideo ? "is-invalid" : ""
                              }`}
                            accept="video/*"
                            onChange={(e) => {
                              const selectedFile = e.target.files[0];
                              if (selectedFile) {
                                if (selectedFile.size > 50 * 1024 * 1024) {
                                  showNotificationWithMessage('File size should be less than 50MB', 'error');
                                  e.target.value = null;
                                } else {
                                  setMediaVideo(selectedFile);
                                  clearFieldError("mediaVideo");
                                }
                              }
                              // setMediaVideo(e.target.files[0]);
                              // clearFieldError("mediaVideo");
                            }}
                          />
                          {formErrors.mediaVideo && (
                            <div className="invalid-input">
                              {formErrors.mediaVideo}
                            </div>
                          )}
                        </div>
                      </div>


                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="labeltext">Select Course <span className="aesterik">*</span></label>
                          <select value={mediaSelectCourse}
                            onChange={(e) => {
                              setMediaSelectCourse(e.target.value);
                              // console.log(e.target.value);
                              handleCourseChange(e);
                            }
                            } className="form-control">
                            <option value="all">
                              All Students
                            </option>
                            <option value="subscriber">
                              Subscriber Students
                            </option>
                            {Mediacourses.map((media) => (
                              <option key={media.Name} value={media.url}>
                                {media.Name}
                              </option>
                            ))}

                          </select>
                        </div>
                      </div>

                      {/* Batch */}
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="labeltext">
                            Select Batches
                            {/* <span className="aesterik">*</span> */}
                          </label>
                          <Select
                            mode="multiple"
                            style={{ width: "100%" }}
                            placeholder="Please select"
                            value={batch}
                            onChange={(value) => {
                              setBatch(value);
                              // console.log(value);
                            }}
                          >
                            {mediaSelectCourse && (mediaSelectCourse !== "all" && mediaSelectCourse !== "subscriber") ?
                              Mediacourses
                                .find((media) =>
                                  media.url === mediaSelectCourse
                                )
                                .batchIDs?.map((batch) => (
                                  <Select.Option key={batch._id} value={batch._id}>
                                    {batch.batchName}
                                  </Select.Option>

                                ))
                              : null}
                          </Select>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <!-- box1 -->

                  {/* <!-- action --> */}
                <div className="box-footer mb-4 text-center">
                  <button
                    type="submit"
                    className="btn btn-rounded  btn_dark btn-outline btncourse"
                    style={{ padding: '6px 22px' }}
                    // onClick={addMedias}
                    onClick={e => scrollToError(formErrors, addMedias)}
                  >
                    {/* <i className="ti-save-alt"></i>  */}
                    Save
                  </button>
                </div>

                {/* <!-- action --> */}
              </div>
              {/* </form> */}
            </div>
          </section>

          {/* <!-- form --> */}

          {/* 		
		<!-- /.content --> */}
        </div>
      </div>
      {/* <!-- /.content-wrapper --> */}
    </>
  );
};

export default AddMediaVideo;