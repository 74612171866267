import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { NavLink, useNavigate } from "react-router-dom";
import useNotification from "../../../../useNotification";
import { useGlobalLoader } from "../../../../Loader/GlobalLoaderContext";
import Content from "./Content";
import DOMPurify from "isomorphic-dompurify";


const PrivacyPolicy = () => {
    const navigate = useNavigate();
    useEffect(() => {
        const checkToken = async () => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_BACKEND_URL}/admin/protected`,
                    {
                        headers: {
                            Authorization: `Bearer ${Cookies.get("jwtoken")}`,
                        },

                        withCredentials: true,
                    }
                );
                const data = await response.data;
            } catch (error) {
                navigate("/");
            }
        };

        checkToken();
    }, []);

    const { showLoader, hideLoader } = useGlobalLoader();
    const { showNotificationWithMessage } = useNotification();
    const [privacyPolicy, setPrivacyPolicy] = useState("");
    const [id, setId] = useState("");

    const getPrivacyPolicy = async () => {
        try {
            showLoader();
            const response = await axios.get(
                `${process.env.REACT_APP_BACKEND_URL}/admin/getPrivacyPolicy`,
                {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${Cookies.get("jwtoken")}`,
                    },
                    withCredentials: true,
                }
            );
            const data = await response.data.data;
            const privacyPolicies = data.map(item => item.privacyPolicy_content);
            const privacyPoliciesId = data.map(item => item._id);
            setPrivacyPolicy(privacyPolicies.join('\n\n'));
            setId(privacyPoliciesId);
        } catch (error) {
            console.log(error)
            showNotificationWithMessage("Something went wrong", "error");
        } finally {
            hideLoader();
        }
    }
    useEffect(() => {
        getPrivacyPolicy();
    }, [])

    // console.log(id)

    return (
        <>
            <div className="content-wrapper">
                <div className="container-full">
                    <section className="content">
                        <div className="row">
                            <div class="col-12" style={{ marginBottom: 20 }}>
                                <div className="content-header pagetitle-pl">
                                    <div className="d-flex align-items-center">
                                        <div className="mr-auto">
                                            <h3 className="page-title">Privacy & Policy</h3>
                                            <nav>
                                                <ol class="breadcrumb">
                                                    <li class="breadcrumb-item"><NavLink to="/dashboard">Dashboard</NavLink></li>
                                                    <li class="breadcrumb-item" aria-current="page"><NavLink to="privacy-policy">Privacy & Policy</NavLink></li>
                                                </ol>
                                            </nav>
                                        </div>
                                        <div className="mr-end ">
                                            <NavLink
                                                className="waves-effect waves-light btn-block btn btn_dark addcourse"
                                                to={"/update-privacy-policy/" + id}
                                            >
                                                <i className="fa fa-plus mr-10"></i>
                                                Update Privacy & Policy
                                            </NavLink>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 mx-auto">
                                <div className="box" style={{ marginTop: 50 }}>
                                    <div className="box-body p-5">
                                        <Content
                                            HTML={DOMPurify.sanitize(privacyPolicy)}
                                            className="courses_content"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </>
    )
}
export default PrivacyPolicy