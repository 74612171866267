import React, { useEffect, useState } from "react";
import axios from "axios";
import { NavLink } from "react-router-dom";
import Cookies from "js-cookie";
import { Select, notification } from "antd";
import { useParams, useNavigate } from "react-router";
import useNotification from "../../../useNotification"; // Import the custom hook
import { useGlobalLoader } from "../../../Loader/GlobalLoaderContext";
import { set } from "react-hook-form";

const UpdateMedia = () => {
  const clearFieldError = (fieldName) => {
    setFormErrors({ ...formErrors, [fieldName]: undefined });
  };
  // const getInputClassName = (fieldName) => {
  //     return `form-control ${formErrors[fieldName] ? "is-invalid" : ""}`;
  // };

  useEffect(() => {
    const checkToken = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/admin/protected`,
          {
            headers: {
              Authorization: `Bearer ${Cookies.get("jwtoken")}`,
            },

            withCredentials: true, //============new
          }
        );
        const data = await response.data;
        // console.log(data);
      } catch (error) {
        navigate("/");
        // alert("Unauthorized");
      }
    };

    checkToken();
  }, []);

  const { showLoader, hideLoader } = useGlobalLoader();
  const { showNotificationWithMessage } = useNotification();

  // const [media, setMedia] = useState([])
  const [mediaType, setMediaType] = useState();

  const navigate = useNavigate();

  const [Mediacourses, setMediaCourses] = useState([]);

  const [mediaTitle, setMediaTitle] = useState("");
  const [mediaImage, setMediaImage] = useState("");
  const [mediaSelectCourse, setMediaSelectCourse] = useState("all");
  const [mediaURL, setMediaURL] = useState("");
  const [mediaDocument, setMediaDocument] = useState();
  const [mediaVideo, setMediaVideo] = useState();

  const [batches, setBatches] = useState([]);
  const [batch, setBatch] = useState([]);


  const [formErrors, setFormErrors] = useState({
    mediaTitle: "",
    // mediaSelectCourse: '',
    // mediaImage: '',
    // mediaDocument: '',
    // mediaVideo: '',
  });

  useEffect(() => {
    getMedia();
  }, []);

  const params = useParams();

  const getMedia = async () => {
    try {
      showLoader();
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/admin/GetEditMedia/${params.id}`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("jwtoken")}`,
          },

          withCredentials: true, // Send cookies with the request
        }
      );
      const data = await response.data;

      // setMedia(data);
      // setMediaCourses(data.Mediacourses);
      setMediaTitle(data.mediaTitle);
      setMediaURL(data.mediaURL);
      // console.log("????", data.mediaSelectCourse === "" ? "all" : data.mediaSelectCourse)
      setMediaSelectCourse(data.mediaSelectCourse === "" ? "all" : data.mediaSelectCourse);
      setMediaType(data.mediaType);
      setBatch(data.batch.map((batch) => batch._id));

      if (!response.status === 200) {
        const error = new Error(response.error);
        throw error;
      }
    } catch (error) {
      console.error("Error fetching media:", error);
    } finally {
      hideLoader();
    }
  };

  useEffect(() => {
    getMediaCourses();
  }, []);

  const getMediaCourses = async () => {
    try {
      showLoader();
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/admin/GetMediaCourses`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("jwtoken")}`,
          },

          withCredentials: true, // Send cookies with the request
        }
      );
      const result = response.data;
      // console.log(result);
      setMediaCourses(result);
    } catch (error) {
      console.error("Error fetching Courses:", error);
    } finally {
      hideLoader();
    }
  };

  const scrollToError = (formErrors, updateMedias) => {
    if (formErrors) {
      const errorField = Object.keys(formErrors)[0];
      const field = document.getElementsByName(errorField)[0];
      if (field) {
        field.scrollIntoView({ behavior: "smooth", block: "center" });
      }
      updateMedias();
    } else {
      updateMedias();
    }
  };

  const updateMedias = async (e) => {
    // e.preventDefault();
    const errors = {};
    if (!mediaTitle) {
      errors.mediaTitle = "Title is required";
    }
    // if (!mediaSelectCourse) {
    //     errors.mediaSelectCourse = 'Select a course';
    // }

    setFormErrors(errors);
    // If there are errors, stop further processing
    if (Object.keys(errors).length > 0) {
      return;
    }
    const formData = new FormData();
    formData.append("mediaTitle", mediaTitle);
    formData.append("mediaSelectCourse", mediaSelectCourse);
    formData.append("mediaURL", mediaURL);
    formData.append("batch", batch);

    if (mediaType === "image") {
      formData.append("mediaImage", mediaImage);
    } else if (mediaType === "document") {
      formData.append("mediaDocument", mediaDocument);
    } else if (mediaType === "video") {
      formData.append("mediaVideo", mediaVideo);
    } else {
      alert("something went wrong");
    }

    try {
      showLoader();
      const response = await axios.put(
        `${process.env.REACT_APP_BACKEND_URL}/admin/UpdateMedia/${params.id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${Cookies.get("jwtoken")}`,
          },
          withCredentials: true, // Send cookies with the request
        }
      );

      // notification.success({
      //     message: "media Updated successfully",
      // });

      // { mediaType === "image" ? navigate("/MediaImage") : mediaType === "video" ? navigate("/MediaVideo") : navigate("/MediaDocs") }

      showNotificationWithMessage("media Updated successfully", "success");
      setTimeout(() => {
        {
          mediaType === "image"
            ? navigate("/images")
            : mediaType === "video"
              ? navigate("/videos")
              : navigate("/documents");
        }
      }, 1000);
    } catch (error) {
      console.error(error);
      showNotificationWithMessage("Something went wrong", "error");
    } finally {
      hideLoader();
    }
  };

  const handleCourseChange = (e) => {
    // console.log(e.target.value)
    // setMediaSelectCourse(e.target.value);

    const selectedCourse = Mediacourses.find(
      (course) => course.url === (e.target.value)
    );
    // console.log(selectedCourse);
    if (selectedCourse) {
      setBatches(selectedCourse.batchIDs);
      // console.log(selectedCourse.batchIDs);
    }
    setMediaSelectCourse(e.target.value);
    setBatch([]);
  };
  // useEffect(() => {
  //   setBatch([]);
  // }, [mediaSelectCourse]);

  return (
    <>
      <div className="content-wrapper">
        <div className="container-full">
          <div className="content-header">
            <div className="d-flex align-items-center">
              <div className="mr-auto">
                <h3 className="page-title">
                  {mediaType === "image"
                    ? "Update Image"
                    : mediaType === "video"
                      ? "Update Video"
                      : "Update Document"}
                </h3>
                <div className="d-inline-block align-items-center">
                  <nav>
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <NavLink to="/dashboard">Dashboard</NavLink>
                      </li>
                      <li className="breadcrumb-item" aria-current="page">
                        Media
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        {mediaType === "image"
                          ? "Update Image"
                          : mediaType === "video"
                            ? "Update Video"
                            : "Update Document"}
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
          <section className="content mt-5">
            <div className="row">
              {/* <div className="col-lg-12 mb-3">
                      <h5 className="box-title"> <h4 className="box-title">
                                            {mediaType === "image" ? "Update Image" : mediaType === "video" ? "Update Video" : "Update Document"}
                                       
                                        </h4></h5>
                      </div> */}
              {/* <form>/ */}
              <div className="col-lg-12 col-12 mx-auto">
                {/* <!-- box1 --> */}

                <div className="box boxpadding">
                  {/* <div className="box-header with-border">
                                        <h4 className="box-title">
                                            {mediaType === "image" ? "Update Image" : mediaType === "video" ? "Update Video" : "Update Document"}
                                       
                                        </h4>
                            
                                    </div> */}

                  <div className="row pt-3 pb-3">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="labeltext">
                          Title <span className="aesterik">*</span>
                        </label>
                        <input
                          type="text"
                          name="title"
                          // className="form-control"
                          className={`form-control ${formErrors.mediaTitle ? "is-invalid" : ""
                            }`}
                          placeholder="title"
                          value={mediaTitle}
                          onChange={(e) => {
                            setMediaTitle(e.target.value);
                            clearFieldError("mediaTitle");
                          }}
                        />
                        {formErrors.mediaTitle && (
                          <div className="invalid-input">
                            {formErrors.mediaTitle}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="labeltext">Select Course</label>

                        <select
                          value={mediaSelectCourse}
                          onChange={(e) => {
                            // setMediaSelectCourse(e.target.value);
                            handleCourseChange(e);
                            clearFieldError("mediaSelectCourse");
                          }}
                          className="form-control"
                        // className={`form-control ${formErrors.mediaSelectCourse ? 'is-invalid' : ''
                        //     }`}
                        >
                          <option selected value="all">
                            All Students
                          </option>
                          <option value="subscriber">
                            Subscriber Students
                          </option>

                          {Mediacourses.map((media) => (
                            <option key={media.Name} value={media.url}>
                              {media.Name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="form-group">
                        <label className="labeltext">
                          Select Batches
                          {/* <span className="aesterik">*</span> */}
                        </label>
                        <Select

                          mode="multiple"
                          style={{ width: "100%" }}
                          placeholder="Please select"
                          value={batch}
                          onChange={(value) => {
                            setBatch(value);
                            // console.log(value);
                          }}
                        >
                          {mediaSelectCourse ?
                            Mediacourses
                              .find((media) =>
                                media.url === mediaSelectCourse
                              )?.batchIDs.map((batch) => (
                                <Select.Option key={batch._id} value={batch._id}>
                                  {batch.batchName}
                                </Select.Option>

                              ))
                            : null}
                        </Select>

                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="form-group">
                        <label className="labeltext">
                          {mediaType === "image"
                            ? "Image"
                            : mediaType === "video"
                              ? "Video"
                              : "Document (.pdf only)"}
                          {/* Media Image */}
                        </label>
                        <input
                          type="file"
                          accept={
                            mediaType === "image"
                              ? "image/*"
                              : mediaType === "video"
                                ? "video/*"
                                : ".pdf"
                          }
                          name="mediaImage    "
                          className="form-control"
                          // className={getInputClassName("mediaImage")}
                          placeholder="Title"
                          onChange={(e) => {
                            {
                              if (mediaType === "image") {
                                const selectedImage = e.target.files[0];
                                if (selectedImage) {
                                  if (selectedImage.size > 5 * 1024 * 1024) {
                                    showNotificationWithMessage("File size should be less than 5MB", "error");
                                    return;
                                  } else {
                                    setMediaImage(selectedImage);
                                  }
                                }
                                // setMediaImage(e.target.files[0]);

                              } else if (mediaType === "video") {
                                const selectedVideo = e.target.files[0];
                                if (selectedVideo) {
                                  if (selectedVideo.size > 50 * 1024 * 1024) {
                                    showNotificationWithMessage("File size should be less than 50MB", "error");
                                    return;
                                  } else {
                                    setMediaVideo(selectedVideo);
                                  }
                                  // setMediaVideo(e.target.files[0]);
                                }
                              } else {
                                const selectedDocument = e.target.files[0];
                                if (selectedDocument) {
                                  if (selectedDocument.size > 20 * 1024 * 1024) {
                                    showNotificationWithMessage("File size should be less than 20MB", "error");
                                    return;
                                  } else {
                                    setMediaDocument(selectedDocument);
                                  }
                                }
                                // setMediaDocument(e.target.files[0]);
                              }
                            }
                          }}
                        />
                        {/* {mediaType === "image" && formErrors.mediaImage && (
                                                    <div className="invalid-input">{formErrors.mediaImage}</div>
                                                )}
                                                {mediaType === "video" && formErrors.mediaVideo && (
                                                    <div className="invalid-input">{formErrors.mediaVideo}</div>
                                                )}
                                                {mediaType === "document" && formErrors.mediaDocument && (
                                                    <div className="invalid-input">{formErrors.mediaDocument}</div>
                                                )} */}
                      </div>
                    </div>
                  </div>
                </div>
                {/* <!-- box1 -->
    
                      
    
    
                    
    
    
                      {/* <!-- action --> */}
                <div className="box-footer mb-4 text-center">
                  <button
                    type="submit"
                    className="btn btn-rounded  btn_dark btn-outline btncourse"
                    style={{ padding: '6px 22px' }}
                    // onClick={updateMedias}
                    onClick={(e) => scrollToError(formErrors, updateMedias)}
                  >
                    {/* <i className="ti-save-alt"></i>  */}
                    Update
                  </button>
                </div>

                {/* <!-- action --> */}
              </div>
              {/* </form> */}
            </div>
          </section>

          {/* <!-- form --> */}

          {/* 		
            <!-- /.content --> */}
        </div>
      </div>
      {/* <!-- /.content-wrapper --> */}
    </>
  );
};

export default UpdateMedia;
