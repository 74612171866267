// import { useForm } from "react-hook-form";
import React, { useState, useRef, useMemo, useEffect } from "react";
import { NavLink } from "react-router-dom";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import JoditEditor from "jodit-react";
import Cookies from "js-cookie";
import ImageCroper from "../../ImageCroper/ImageCroper";
import { ErrorMessage, Formik } from "formik";
// import "jodit/build/jodit.min.css";
// import { useForm } from "react-hook-form";
// import parse from "html-react-parser";

import useNotification from "../../../useNotification"; // Import the custom hook
import { useGlobalLoader } from "../../../Loader/GlobalLoaderContext";
import { Button, Select, Switch } from "antd";
import AddCategory from "../addCategory/AddCategoryModal";
// import AddBatches from "../addCategory/AddBatchesModal";
import { Color } from "@rc-component/color-picker";

import { Space } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { Option } from "antd/es/mentions";


const AddCourse = () => {



  useEffect(() => {
    const checkToken = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/admin/protected`,
          {
            headers: {
              Authorization: `Bearer ${Cookies.get("jwtoken")}`,
            },

            withCredentials: true, //============new
          }
        );
        const data = await response.data;
        // console.log(data);
      } catch (error) {
        navigate("/");
        // alert("Unauthorized");
      }
    };

    checkToken();
  }, []);

  const { showLoader, hideLoader } = useGlobalLoader();
  const { showNotificationWithMessage } = useNotification();

  const editor = useRef(null);
  const [content, setContent] = useState("");
  const [title, setTitle] = useState("");
  const [support, setSupport] = useState("");
  const [category, setCategory] = useState("");
  const [sub_category, setSubCategory] = useState("");
  const [short_desc, setShortDesc] = useState("");
  const [duration, setDuration] = useState("");
  const [batches, setBatches] = useState("");
  const [price, setPrice] = useState("");
  const [discount_price, setDiscountPrice] = useState("");
  const [tag, setTag] = useState("");
  // const [date, setDate] = useState('');
  const [meta_title, setMetaTitle] = useState("");
  const [meta_keyword, setMetaKeyword] = useState("");
  const [meta_desc, setMetaDesc] = useState("");
  const [image, setImage] = useState(null);
  const [posterImage, setPosterImage] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [cropingImage, setCropingImage] = useState(false);
  const [aspectRatio, setAspectRatio] = useState(1.9);
  const [cropPosterImage, setPosterImageToCrop] = useState(false);
  const [display_name, setDisplayName] = useState("");
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedSubCategory, setSelectedSubCategory] = useState(null);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [showBatchModal, setShowBatchModal] = useState(false);


  // const [selectedItems, setSelectedItems] = useState([]);
  // const [batchName, setBatchName] = useState([""]);

  const [error, setError] = useState();
  const navigate = useNavigate();

  const [validationEnabled, setValidationEnabled] = useState(false);

  const imageHandler = (image, posterImage) => {
    setCropingImage(image);
    setPosterImageToCrop(posterImage);
    setShowModal(true);
  };

  const scrollToError = (errors, handleSubmit) => {
    if (errors) {
      const errorField = Object.keys(errors)[0];
      const field = document.getElementsByName(errorField)[0];
      if (field) {
        field.scrollIntoView({ behavior: "smooth", block: "center" });
      }
      handleSubmit();
    } else {
      handleSubmit();
    }
  };

  const subCategoriesOptions = useMemo(() => {
    const category = categories.find((cat) => cat.value === selectedCategory);
    // console.log("??????", category);
    return category
      ? category.subcategories.map((sub) => ({ label: sub, value: sub }))
      : [];
  }, [selectedCategory, categories]);

  const editorConfig = useMemo(() => {
    return {
      readonly: false,
      placeholder: "Start typing...",
      responsive: true,
    };
  }, []);

  const getCategories = async () => {
    try {
      showLoader();
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/admin/categories`,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${Cookies.get("jwtoken")}`,
          },
          withCredentials: true, // Send cookies with the request
        }
      );
      setCategories(
        response.data.data.map((cate) => {
          return {
            label: cate.category,
            value: cate._id,
            subcategories: cate.subcategories,
          };
        })
      );
      // console.log("respocn", response);
    } catch (e) {
      console.log(error);
    } finally {
      hideLoader();
    }
  };

  useEffect(() => {
    getCategories();
  }, []);

  // const {option} = Select;
  const EditDeleteOptions = ({ option, category }) => (
    <Space className="space">
      <div className="optioncategory">
        {/* <span className="optioncategory">{option}</span> */}
      </div>

      <Button
        type="button"
        onClick={(e) => {
          e.stopPropagation();
          // console.log(category)
          handleEditCate(option.value);
          setDropdownVisible(false);
        }}
      >
        <i className="fa fa-pencil-square-o text-info"></i>
      </Button>

      <Button
        type="button"
        onClick={(e) => {
          e.stopPropagation();
          // console.log(option, category);
          handleDeleteCate(option.value);
        }}
      >
        <i className="fa fa-trash-o text-danger"></i>
      </Button>
    </Space>
  );

  const handleDeleteCate = async (value) => {
    try {
      setDropdownVisible(false);
      // console.log("value", value);
      const response = await axios.delete(
        `${process.env.REACT_APP_BACKEND_URL}/admin/deleteCategory/${value}`,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${Cookies.get("jwtoken")}`,
          },
          withCredentials: true, // Send cookies with the request
        }
      );
      if (response?.response?.status === 400) {
        showNotificationWithMessage(
          "You can't delete this category as it is already assigned to a course",
          "error"
        );
      }
      if (response?.status === 200) {
        showNotificationWithMessage("Category deleted successfully", "success");
      } else {
        showNotificationWithMessage("Something went wrong", "error");
      }
      setSelectedCategory("");
      setSelectedSubCategory("");
      getCategories();
    } catch (error) {
      if (error.response.status === 400) {
        showNotificationWithMessage(
          "You can't delete this category as it is already assigned to a course",
          "error"
        );
      }
      console.log(error);
    }
  };

  const [editCategoryData, setEditCategoryData] = useState(null);
  const handleEditCate = async (value) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/admin/getCategoriesEdit/${value}`,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${Cookies.get("jwtoken")}`,
          },
          withCredentials: true, // Send cookies with the request
        }
      );
      // console.log("respocn", response);
      // setSelectedCategory(response.data.data._id)
      // setCategory(response.data.data.category)
      // setSubCategory(response.data.data.subcategories)

      setEditCategoryData({
        _id: response.data.data._id,
        category: response.data.data.category,
        subcategories: response.data.data.subcategories,
      });

      setShowModal(true);
    } catch (error) {
      console.log(error);
    }
  };

  //getBatches
  // const getBatches = async () => {
  //   try {
  //     const response = await axios.get(
  //       `${process.env.REACT_APP_BACKEND_URL}/admin/GetBatches`,
  //       {
  //         headers: {
  //           "Content-Type": "multipart/form-data",
  //           Authorization: `Bearer ${Cookies.get("jwtoken")}`,
  //         },
  //         withCredentials: true, // Send cookies with the request
  //       }
  //     );
  //     console.log("getBatches", response.data.data);
  //     setBatchName(response.data.data);

  //   } catch (error) {
  //     console.error("Error fetching batches:", error);
  //     setBatchName([]);
  //   }
  // };
  // useEffect(() => {
  //   getBatches();
  // }, []);
  // const filteredOptions = batchName.filter((o) => !selectedItems.includes(o));

  return (
    <>
      <div className="content-wrapper">
        <div className="container-full">
          <div className="content-header">
            <div className="d-flex align-items-center">
              <div className="mr-auto">
                <h3 className="page-title">Add Course</h3>
                <div className="d-inline-block align-items-center">
                  <nav>
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <NavLink to="/dashboard">Dashboard</NavLink>
                      </li>
                      <li className="breadcrumb-item" aria-current="page">
                        {" "}
                        <NavLink to="/courselist"> Course</NavLink>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        Add Course
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
          <section className="content mt-4">
            <div className="row">
              {/* <form onSubmit={handleSubmit}> */}

              <Formik
                initialValues={{
                  title: "",
                  // batchName: "",
                  support: "",
                  category: "",
                  sub_category: "",
                  short_desc: "",
                  duration: "",
                  batches: "",
                  price: "",
                  discount_price: "",
                  tag: "",
                  meta_title: "",
                  meta_keyword: "",
                  meta_desc: "",
                  course_content: "",
                  image: null,
                  posterImage: null,
                }}
                validate={(values) => {
                  const errors = {};
                  let scroll = false;

                  if (!values.title) {
                    errors.title = "Please enter a title";
                  }

                  if (!values.category) {
                    errors.category = "Please select or add category";
                  }

                  // if (!values.sub_category) {
                  //   errors.sub_category = "Please enter a sub category";
                  // }

                  if (!values.duration) {
                    errors.duration = "Please enter a valid duration";
                  }

                  if (!values.batches) {
                    errors.batches = "Please enter a validlength";
                  }
                  if (!values.support) {
                    errors.support = "Please enter a valid support";
                  }
                  if (
                    !values.image ||
                    values.image == "" ||
                    values.image == null ||
                    values.image == undefined
                  ) {
                    errors.image = "Please Upload an Image";
                  }

                  if (
                    !values.posterImage ||
                    values.posterImage == "" ||
                    values.posterImage == null ||
                    values.posterImage == undefined
                  ) {
                    errors.posterImage = "Please Upload A Poster Image";
                  }
                  if (!validationEnabled) {
                    // if (!values.batchName) {
                    //   errors.batchName = "Please Select a batch";
                    // }
                    if (!values.price) {
                      errors.price = "Please enter a valid price";
                    }
                    if (!values.discount_price) {
                      errors.discount_price =
                        "Please enter a valid discount price";
                    }
                    if (!values.short_desc) {
                      errors.short_desc = "Please enter a short description";
                    }
                    if (!values.tag) {
                      errors.tag = "Please enter a tag";
                    }
                    if (!values.meta_title) {
                      errors.meta_title = "Please enter a meta title";
                    }
                    if (!values.meta_keyword) {
                      errors.meta_keyword = "Please enter a meta keyword";
                    }
                    if (!values.meta_desc) {
                      errors.meta_desc = "Please enter a meta description";
                    }

                    if (!values.course_content) {
                      errors.course_content =
                        "Please enter a course Description";
                    }
                  }
                  return errors;
                }}
                onSubmit={async (values) => {
                  const formData = new FormData();

                  // formData.append("display_name", values.display_name);
                  // formData.append("batchName", selectedItems);
                  formData.append("title", values.title);
                  formData.append("support", values.support);
                  formData.append("category", values.category);
                  formData.append("sub_category", values.sub_category);
                  formData.append("short_desc", values.short_desc);
                  formData.append("duration", values.duration);
                  formData.append("batches", values.batches);
                  formData.append("price", values.price);
                  formData.append("discount_price", values.discount_price);
                  formData.append("tag", values.tag);
                  formData.append("meta_title", values.meta_title);
                  formData.append("meta_keyword", values.meta_keyword);
                  formData.append("meta_desc", values.meta_desc);
                  formData.append("course_content", content);
                  formData.append("image", image);
                  formData.append("posterImage", posterImage);

                  try {
                    showLoader();
                    // console.log()
                    const response = await axios.post(
                      `${process.env.REACT_APP_BACKEND_URL}/admin/AddCourses`,
                      formData,
                      {
                        headers: {
                          "Content-Type": "multipart/form-data",
                          Authorization: `Bearer ${Cookies.get("jwtoken")}`,
                        },
                        withCredentials: true, // Send cookies with the request
                      }
                    );
                    // console.warn(response.data);
                    // alert("Successfully added");
                    // navigate("/CourseList");
                    showNotificationWithMessage(
                      "Course added successfully",
                      "success"
                    );
                    setTimeout(() => {
                      navigate("/courselist");
                    }, 1000);
                  } catch (error) {
                    console.error(error);
                    showNotificationWithMessage(
                      "Something went wrong",
                      "error"
                    );
                  } finally {
                    hideLoader(); // Hide the global loader
                  }
                }}
              >
                {({
                  values,
                  handleChange,
                  handleBlur,
                  setFieldValue,
                  handleSubmit,
                  errors,
                }) => (
                  <div className="col-lg-12 col-12 mx-auto addcoursemargin">
                    {/* <!-- box1 --> */}

                    <div className="box">
                      <div className="box-body boxpadding pt-4">
                        <div className="form-group">
                          <label className="labeltext">Upcoming Course</label>
                          &nbsp;&nbsp;
                          <Switch
                            checked={validationEnabled}
                            onChange={() =>
                              setValidationEnabled(!validationEnabled)
                            }
                          />
                          <div>
                            (if you want to add upcoming course then enable this
                            switch)
                          </div>
                        </div>

                        <div className="row pt-3">
                          <div className="col-md-12">
                            <div className="form-group">
                              <label id="Title" className="labeltext">
                                Title
                                <span className="aesterik">*</span>
                              </label>
                              <input
                                type="text"
                                name="title"
                                className="form-control"
                                placeholder="Title"
                                value={values.title}
                                onChange={handleChange}
                              />
                              <ErrorMessage
                                className="text-danger"
                                name="title"
                                component="div"
                              />
                            </div>
                          </div>

                          {/* batches start */}

                          {/* <div className="col-md-9 offset-md-1">
                            <div className="form-group">
                              <label className="labeltext">
                                Batches <span className="aesterik">*</span>
                              </label>

                              <select  className="form-control">
                                <option >Batches</option>
                                <option>Batch One</option>
                                <option>Batch Two</option>
                                <option>Batch Three</option>
                                <option>Batch Four</option>
                                <option>Batch Five</option>
                              </select>

                              <ErrorMessage
                                className="text-danger"
                                name="category"
                                component="div"
                              />
                            </div> */}
                            {/* <div className="form-group">
                              <label className="labeltext">
                                Batches <span className="aesterik">*</span>
                              </label>

                              <Select
                                mode="multiple"
                                // className="form-control"
                                value={selectedItems}
                                onChange={(values) => {
                                  setSelectedItems(values);
                                }}
                                style={{ width: '100%' }}
                                options={batchName.map((item) => ({
                                  value: item.batchName,
                                }))}
                              />
                              <ErrorMessage
                                className="text-danger"
                                name="batchName"
                                component="div"
                              />
                            </div> */}
                          {/* </div> */}

                          {/* <div className="col-md-2">
                            <Button
                              type="primary"
                              onClick={(e) => {
                                setShowBatchModal(true);
                                setEditCategoryData(null);
                              }}
                              className="marginbtn addcat"
                            >
                              Add new Batch{" "}
                              <i
                                className="fa fa-plus pl-2"
                                aria-hidden="true"
                              ></i>
                            </Button>
                          </div> */}
                          {/* batches  end*/}

                          <div className="col-md-4">
                            <div className="form-group">

                              <label className="labeltext">
                                Category <span className="aesterik">*</span>
                              </label>
                              <Select
                                style={{ width: "100%" }}
                                options={categories.map((category) => ({
                                  value: category.value,
                                  label: category.label,
                                }))}
                                onChange={(value) => {
                                  setSelectedCategory(value);
                                  setSelectedSubCategory("");
                                  setFieldValue("sub_category", "");
                                  setFieldValue("category", value);
                                }}
                                value={selectedCategory}
                                onDropdownVisibleChange={(visible) =>
                                  setDropdownVisible(visible)
                                }
                                open={dropdownVisible}
                                dropdownRender={(menu) => (
                                  <div>
                                    {/* {console.log("asdf",categories)} */}
                                    {categories.map((category) => (
                                      <div
                                        key={category.value}
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                          padding: "4px 8px",
                                          cursor: "pointer",
                                        }}
                                        onClick={() => {
                                          // console.log(category);
                                          setSelectedCategory(category?.value);
                                          setDropdownVisible(false);
                                          setSelectedSubCategory("");
                                          setFieldValue("sub_category", "");
                                          setFieldValue(
                                            "category",
                                            category?.value
                                          );
                                        }}
                                        className="course_category"
                                      >
                                        <span>{category.label}</span>
                                        <EditDeleteOptions
                                          option={category}
                                          category={category.label}
                                        // onEdit={(e) => {
                                        //   console.log(category)
                                        //   // handleEditCate(category?.value);
                                        // }}
                                        // onDelete={(e) => {
                                        //   handleDeleteCate(category?.value);
                                        // }}
                                        />
                                      </div>
                                    ))}
                                  </div>
                                )}
                              />

                              <ErrorMessage
                                className="text-danger"
                                name="category"
                                component="div"
                              />
                            </div>
                          </div>

                          <div className="col-md-2">
                            <Button
                              type="primary"
                              // style={{ marginTop: "2.5rem" }}
                              onClick={(e) => {
                                setShowModal(true);
                                setEditCategoryData(null);
                              }}
                              className="marginbtn addcat"
                            >
                              Add new Category{" "}
                              <i
                                className="fa fa-plus pl-2"
                                aria-hidden="true"
                              ></i>
                            </Button>
                          </div>

                          <div className="col-md-5 offset-md-1 mt-2 pb-2">
                            <div className="form-group">
                              <label id="Sub_category" className="labeltext">
                                Sub Category
                              </label>
                              <Select
                                style={{ width: "100%" }}
                                options={subCategoriesOptions}
                                value={selectedSubCategory}
                                onChange={(value) => {
                                  // console.log(value);
                                  setSelectedSubCategory(value);
                                  setFieldValue("sub_category", value);
                                }}
                              />
                              <ErrorMessage
                                className="text-danger"
                                name="sub_category"
                                component="div"
                              />
                            </div>
                          </div>

                          <div className="col-md-12 mt-2 pb-2">
                            <div className="form-group">
                              <label id="Short_desc" className="labeltext">
                                Short Description
                                {!validationEnabled && (
                                  <span className="aesterik">*</span>
                                )}
                              </label>
                              <textarea
                                rows="2"
                                name="short_desc"
                                className="form-control"
                                placeholder="Short Description"
                                value={values.short_desc}
                                onChange={handleChange}
                              ></textarea>

                              <ErrorMessage
                                className="text-danger"
                                name="short_desc"
                                component="div"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <!-- box1 -->


        <!-- box2 --> */}

                    <div className="box">
                      <div className="box-body boxpadding">
                        <div className="row pt-2">
                          <div className="col-md-4 mt-2">
                            <div className="form-group">
                              <label id="Duration" className="labeltext">
                                Duration (In Months)
                                <span className="aesterik">*</span>
                              </label>
                              <input
                                type="number"
                                name="duration"
                                className="form-control"
                                placeholder="Duration"
                                value={values.duration}
                                onChange={handleChange}
                              />
                              <ErrorMessage
                                className="text-danger"
                                name="duration"
                                component="div"
                              />
                            </div>
                          </div>
                          <div className="col-md-4 mt-2">
                            <div className="form-group">
                              <label id="Batches" className="labeltext">
                                length (In Hours)
                                <span className="aesterik">*</span>
                              </label>
                              <input
                                type="number"
                                name="batches"
                                className="form-control"
                                placeholder="length"
                                value={values.batches}
                                onChange={handleChange}
                              />
                              <ErrorMessage
                                className="text-danger"
                                name="batches"
                                component="div"
                              />
                            </div>
                          </div>

                          <div className="col-md-4 mt-2">
                            <div className="form-group">
                              <label id="Support" className="labeltext">
                                support (In Months)
                                <span className="aesterik">*</span>
                              </label>
                              <input
                                type="number"
                                name="support"
                                className="form-control"
                                placeholder="Support"
                                value={values.support}
                                onChange={handleChange}
                              />
                              <ErrorMessage
                                className="text-danger"
                                name="support"
                                component="div"
                              />
                            </div>
                          </div>

                          <div className="col-md-4 mt-2">
                            <div className="form-group">
                              <label id="Price" className="labeltext">
                                {" "}
                                Price
                                {!validationEnabled && (
                                  <span className="aesterik">*</span>
                                )}
                              </label>
                              <input
                                type="number"
                                name="price"
                                className="form-control"
                                placeholder="Price"
                                value={values.price}
                                onChange={handleChange}
                              />
                              <ErrorMessage
                                className="text-danger"
                                name="price"
                                component="div"
                              />
                            </div>
                          </div>
                          <div className="col-md-4 mt-2">
                            <div className="form-group">
                              <label id="Discount_price" className="labeltext">
                                Discount Price
                                {!validationEnabled && (
                                  <span className="aesterik">*</span>
                                )}
                              </label>
                              <input
                                type="number"
                                name="discount_price"
                                className="form-control"
                                placeholder="Discount Price"
                                value={values.discount_price}
                                onChange={handleChange}
                              />
                              <ErrorMessage
                                className="text-danger"
                                name="discount_price"
                                component="div"
                              />
                            </div>
                          </div>

                          <div className="col-md-4 mt-2">
                            <div className="form-group">
                              <label id="Tag" className="labeltext">
                                Tags
                                {!validationEnabled && (
                                  <span className="aesterik">*</span>
                                )}
                              </label>
                              <input
                                type="text"
                                name="tag"
                                className="form-control"
                                placeholder="Tags"
                                value={values.tag}
                                onChange={handleChange}
                              />
                              <ErrorMessage
                                className="text-danger"
                                name="tag"
                                component="div"
                              />
                            </div>
                          </div>

                          <div className="col-md-6 mt-2">
                            <div className="form-group">
                              <label id="Image" className="labeltext">
                                Thumb Image
                                <span className="aesterik">*</span>
                              </label>
                              <input
                                type="file"
                                name="image"
                                className="form-control"
                                accept="image/*"
                                onChange={(e) => {
                                  const selectedThumbImage = e.target.files[0];
                                  if (selectedThumbImage) {
                                    if (
                                      selectedThumbImage.size >
                                      5 * 1024 * 1024
                                    ) {
                                      showNotificationWithMessage(
                                        "Image size should be less than 5MB",
                                        "error"
                                      );
                                      e.target.value = null;
                                    } else {
                                      setImage(selectedThumbImage);
                                      setFieldValue(
                                        "image",
                                        selectedThumbImage
                                      );
                                    }
                                  }
                                  // setImage(e.target.files[0]);
                                  // setFieldValue("image", e.target.files[0]);
                                }}
                              />
                              <ErrorMessage
                                className="text-danger"
                                name="image"
                                component="div"
                              />
                            </div>
                          </div>

                          <div className="col-md-6 mt-2">
                            <div className="form-group">
                              <label id="PosterImage" className="labeltext">
                                Poster Image
                                <span className="aesterik">*</span>
                              </label>
                              <input
                                type="file"
                                name="posterImage"
                                className="form-control"
                                accept="image/*"
                                onChange={(e) => {
                                  const selectedPosterImage = e.target.files[0];
                                  if (selectedPosterImage) {
                                    if (
                                      selectedPosterImage.size >
                                      5 * 1024 * 1024
                                    ) {
                                      showNotificationWithMessage(
                                        "Image size should be less than 5MB",
                                        "error"
                                      );
                                      e.target.value = null;
                                    } else {
                                      setPosterImage(selectedPosterImage);
                                      setFieldValue(
                                        "posterImage",
                                        selectedPosterImage
                                      );
                                    }
                                    // setPosterImage(e.target.files[0]);
                                    // setFieldValue("posterImage", e.target.files[0]);
                                  }
                                }}
                              />

                              <ErrorMessage
                                className="text-danger"
                                name="posterImage"
                                component="div"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* box3 */}

                    <div className="box">
                      <div className="box-body boxpadding">
                        <div className="row pt-2">
                          <div className="col-md-12 mt-2">
                            <div className="form-group">
                              <label id="Course_content" className="labeltext">
                                Description
                                {!validationEnabled && (
                                  <span className="aesterik">*</span>
                                )}
                              </label>
                              <JoditEditor
                                name="course_content"
                                ref={editor}
                                value={content}
                                config={editorConfig}
                                onChange={(newContent) => {
                                  setContent(newContent);
                                  setFieldValue("course_content", newContent);
                                }}
                              />

                              <ErrorMessage
                                className="text-danger"
                                name="course_content"
                                component="div"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* box3 */}

                    <div className="box">
                      <div className="box-body boxpadding">
                        <div className="row pt-2">
                          <div className="col-md-6 mt-2">
                            <div className="form-group">
                              <label id="Meta_title" className="labeltext">
                                Meta Title
                                {!validationEnabled && (
                                  <span className="aesterik">*</span>
                                )}
                              </label>
                              <input
                                type="text"
                                name="meta_title"
                                className="form-control"
                                placeholder="Meta Title"
                                value={values.meta_title}
                                onChange={handleChange}
                              />
                              <ErrorMessage
                                className="text-danger"
                                name="meta_title"
                                component="div"
                              />
                            </div>
                          </div>
                          <div className="col-md-6 mt-2">
                            <div className="form-group">
                              <label id="Meta_keyword" className="labeltext">
                                Meta Keywords
                                {!validationEnabled && (
                                  <span className="aesterik">*</span>
                                )}
                              </label>
                              <input
                                type="text"
                                name="meta_keyword"
                                className="form-control"
                                placeholder="Keywords"
                                value={values.meta_keyword}
                                onChange={handleChange}
                              />
                              <ErrorMessage
                                className="text-danger"
                                name="meta_keyword"
                                component="div"
                              />
                            </div>
                          </div>

                          <div className="col-md-12 mt-2">
                            <div className="form-group">
                              <label className="labeltext">
                                Meta Description{" "}
                                {!validationEnabled && (
                                  <span className="aesterik">*</span>
                                )}
                              </label>
                              <textarea
                                rows="2"
                                name="meta_desc"
                                className="form-control"
                                placeholder="Meta Description"
                                value={values.meta_desc}
                                onChange={handleChange}
                              ></textarea>
                              <ErrorMessage
                                className="text-danger"
                                name="meta_desc"
                                component="div"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <!-- box4 --> */}

                    {/* <!-- action --> */}
                    <div className="box-footer mb-4 text-center">
                      <button
                        type="submit"
                        onClick={(e) => scrollToError(errors, handleSubmit)}
                        // onSubmit={handleSubmit}
                        className="btn btn-rounded btn_dark btn-outline btncourse"
                        style={{ padding: "6px 22px" }}
                      >
                        {/* <i className="ti-save-alt"></i>  */}
                        Save
                      </button>
                    </div>

                    {/* <!-- action --> */}
                  </div>
                )}
              </Formik>
              {/* </form> */}
            </div>
          </section>

          {/* <!-- form --> */}

          {/* 		
		<!-- /.content --> */}
          <AddCategory
            editCategoryData={editCategoryData}
            getCategories={getCategories}
            showModal={showModal}
            setShowModal={setShowModal}
            setSelectedCategory={setSelectedCategory}
            setCat={setSelectedCategory}
          />

          {/* <AddBatches
            // editCategoryData={editCategoryData}
            // getBatches={getBatches}
            showBatchModal={showBatchModal}
            setShowBatchModal={setShowBatchModal}
          // setSelectedCategory={setSelectedCategory}
          // setCat={setSelectedCategory}
          /> */}
        </div>

        {/* <ImageCroper
            aspectRatio={aspectRatio}
            imagetoCrop={cropingImage}
            showModal={showModal}
            setImage={cropPosterImage ? setPosterImage : setImage}
            setShowModal={setShowModal}
          /> */}
      </div>
      {/* <!-- /.content-wrapper --> */}
    </>
  );
};

export default AddCourse;
