import React, { useEffect, useMemo, useRef, useState } from "react";
import axios from "axios";
import { NavLink } from "react-router-dom";
import Cookies from "js-cookie";
import { useNavigate, useParams } from "react-router";
import useNotification from "../../../../useNotification";
import { useGlobalLoader } from "../../../../Loader/GlobalLoaderContext";
import JoditEditor from "jodit-react";


const UpdatePrivacyPolicy = () => {

    useEffect(() => {
        const checkToken = async () => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_BACKEND_URL}/admin/protected`,
                    {
                        headers: {
                            Authorization: `Bearer ${Cookies.get("jwtoken")}`,
                        },

                        withCredentials: true,
                    }
                );
                const data = await response.data;
            } catch (error) {
                navigate("/");
            }
        };
        checkToken();
    }, []);

    const navigate = useNavigate();
    const { showLoader, hideLoader } = useGlobalLoader();
    const { showNotificationWithMessage } = useNotification();
    const [privacyPolicyContent, setPrivacyPolicyContent] = useState("");
    const params = useParams();
    const editor = useRef(null);

    useEffect(() => {
        getprivacyPolicy();
    }, [params]);

    const getprivacyPolicy = async () => {
        try {
            showLoader();

            const response = await axios.get(
                `${process.env.REACT_APP_BACKEND_URL}/admin/getEditPrivacyPolicyById/${params.id}`,
                {
                    headers: {
                        Authorization: `Bearer ${Cookies.get("jwtoken")}`,
                    },
                    withCredentials: true,
                }
            );
            const result = response.data;
            console.log(result)
            if (result && Object.keys(result).length > 0) {
                setPrivacyPolicyContent(result.privacyPolicy_content);
            }
        } catch (error) {
            showNotificationWithMessage('Something went wrong', 'error');
        }
        finally {
            hideLoader();
        }
    };

    const handleSubmit = async () => {
        try {
            // console.log("privacyPolicyContent", privacyPolicyContent)

            const response = await axios.put(
                `${process.env.REACT_APP_BACKEND_URL}/admin/updatePrivacyPolicy/${params.id}`,
                { privacyPolicy_content: privacyPolicyContent },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${Cookies.get("jwtoken")}`,
                    },
                    withCredentials: true, // Send cookies with the request
                }
            );
            if (response.status === 200) {
                showNotificationWithMessage("Privacy policy updated successfully", "success");
                navigate("/privacy-policy");
            }
        } catch (error) {
            console.error("Error updating privacy policy:", error);
            showNotificationWithMessage("Error updating privacy policy", "error");
        } finally {
            hideLoader();
        }
    };

    const editorConfig = useMemo(() => {
        return {
            readonly: false,
            placeholder: "Start typings...",
            responsive: true,
        };
    }, []);

    // console.log("privacyPolicyContent", privacyPolicyContent)

    return (
        <>
            <div className="content-wrapper">
                <div className="container-full">
                    <div className="content-header">
                        <div className="d-flex align-items-center">
                            <div className="mr-auto">
                                <h3 className="page-title">Update Privacy & Policy</h3>
                                <div className="d-inline-block align-items-center">
                                    <nav>
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item">
                                                <NavLink to="/dashboard">Dashboard</NavLink>
                                            </li>
                                            <li class="breadcrumb-item" aria-current="page"><NavLink to="privacy-policy">Privacy & Policy</NavLink></li>
                                            <li
                                                className="breadcrumb-item active"
                                                aria-current="page"
                                            >
                                                Update Privacy Policy
                                            </li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                    <section className="content mt-5">
                        <div className="row">
                            <div className="col-lg-12 col-12 mx-auto">
                                <div className="box">
                                    <div className="box">
                                        <div className="box-body boxpadding">
                                            <div className="row pt-2">
                                                <div className="col-md-12 mt-2">
                                                    <div className="form-group">
                                                        <label className="labeltext">Privacy & Policy
                                                        </label>
                                                        <JoditEditor
                                                            name="course_content"
                                                            ref={editor}
                                                            value={privacyPolicyContent}
                                                            config={editorConfig}
                                                            onChange={(newContent) => {
                                                                setPrivacyPolicyContent(newContent)
                                                            }
                                                            }
                                                        />

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="box-footer mb-4 text-center">
                                    <button
                                        type="submit"
                                        className="btn btn-rounded  btn_dark btn-outline btncourse"
                                        style={{ padding: "6px 22px" }}
                                        onClick={handleSubmit}
                                    >
                                        Save
                                    </button>
                                </div>
                            </div>
                        </div>
                    </section>
                </div >
            </div >
        </>
    );
}

export default UpdatePrivacyPolicy
