import React from "react";
import { Outlet } from "react-router-dom";

const LoginMain = () => {
  return (
    <>
      <body
        className="hold-transition light-skin sidebar-mini theme-primary fixed adminanimation"
        style={{
          backgroundImage: `url("../admin-assets/images/loginbg2.jpeg")`,
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      >
        <div className="wrapper loginwrapper">
          <Outlet />
        </div>
      </body>
    </>
  );
};

export default LoginMain;
