import { notification } from "antd";
import axios from "axios";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import useNotification from '../../../useNotification'; // Import the custom hook
import Swal from 'sweetalert2';
import { useGlobalLoader } from "../../../Loader/GlobalLoaderContext";

const ViewBanner = () => {

  const navigate = useNavigate();
  useEffect(() => {
    const checkToken = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/admin/protected`,
          {
            headers: {
              Authorization: `Bearer ${Cookies.get("jwtoken")}`,
            },

            withCredentials: true, //============new
          }
        );
        const data = await response.data;
        // console.log(data);
      } catch (error) {
        navigate("/");
        // alert("Unauthorized");
      }
    };

    checkToken();
  }, []);

  const { showLoader, hideLoader } = useGlobalLoader();
  const { showNotificationWithMessage } = useNotification();

  const [banner, setBanner] = useState("");
  const [bannerPage, setBannerPage] = useState([]);
  // const [BannerImage, setBannerImage] = useState("image");

  useEffect(() => {
    getBanner();
  }, []);

  // console.log(BannerImage);

  const getBanner = async () => {
    try {
      showLoader();
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/admin/GetBanner`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("jwtoken")}`,
          },

          withCredentials: true, // Send cookies with the request
        }
      );
      const data = await response.data;
      setBanner(data);
      //  const data = response.data.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
      //  setBanner(data);

      if (!response.status === 200) {
        const error = new Error(response.error);
        throw error;
      }
    } catch (error) {
      console.error("Error fetching banner:", error);
    }
    finally {
      hideLoader();
    }
  };

  //delete banner
  const deleteBanner = async (id) => {

    try {
      // Show the confirmation popup
      const confirmed = await Swal.fire({
        title: 'Are you sure?',
        text: 'You are about to delete this banner.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
      });

      if (!confirmed.isConfirmed) {
        return; // If user cancels, do nothing
      }

      showLoader();
      const response = await axios.delete(
        `${process.env.REACT_APP_BACKEND_URL}/admin/DeleteBanner/${id}`,
        {
          headers: {
            Authorization: `Bearer ${Cookies.get('jwtoken')}`,
          },
          withCredentials: true, // Send cookies with the request
        }
      );

      if (response.data) {
        // notification.warning({
        //   message: "Record is deleted",
        // });
        showNotificationWithMessage('Record is deleted', 'success');
      }
    } catch (error) {
      // notification.warning({
      //   message: "Something went wrong",
      // });
      showNotificationWithMessage('Something went wrong', 'error');
    }
    finally {
      hideLoader();
    }
  };



  return (
    <>
      <div className="content-wrapper">
        <div className="container-full">
          {/* <!-- Content Header (Page header) --> */}
          <div className="content-header" style={{ marginTop: 0 }}>           
            <div className="d-flex align-items-center">
              <div className="mr-auto">
                <h3 className="page-title">Banner List</h3>
                <nav>
							<ol class="breadcrumb">
								<li class="breadcrumb-item"><NavLink to="/dashboard">Dashboard</NavLink></li>
							<li class="breadcrumb-item" aria-current="page">Banner</li>	
								<li class="breadcrumb-item active" aria-current="page">Banner List</li>
							</ol>
						</nav>
              </div>
              {/* <div className="mr-end">
                <NavLink
                  className="waves-effect waves-light btn-block btn btn-primary addcourse"
                  to="/add-banner"
                >
                  <i className="fa fa-plus mr-15"></i>Add Banner
                </NavLink>
              </div> */}
            </div>
          </div>

          {/* <!-- Main content --> */}
          <section className="content mt-4">
            <div className="row">
              <div className="col-9 mx-auto">
                <div className="box" style={{ marginTop: 20 }}>
                  <div className="box-body">
                    <div className="table-responsive">
                      <table
                        id="productorder"
                        className="table table-hover no-wrap product-order"
                        data-page-size="10"
                      >
                        {/* <thead> */}
                        <thead>
                          <tr  className="text-center">
                            <th>Sr No.</th>
                            <th>Banner Page</th>
                            <th>Banner Image</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {banner.length > 0 ? (
                            banner.map((bannerimg, index) => (
                              <tr className="text-center" key={bannerimg._id}>
                                <td>{index + 1}</td>
                                <td>{bannerimg.bannerPage}</td>

                                <td>
                                  <img
                                    src={bannerimg.bannerURL}
                                    alt=""
                                    width={80}
                                  />
                                </td>

                                <td>
                                  <NavLink
                                    to={"/update-banner/" + bannerimg._id}
                                    className="btn btn-circle btn-info btn-xs mr-10"
                                    data-toggle="tooltip"
                                    data-original-title="Edit"
                                  >
                                    <i class="ti-marker-alt"></i>
                                  </NavLink>

                                  {/* <NavLink
                                    to="javascript:void(0)"
                                    className="btn btn-circle btn-danger btn-xs"
                                    title=""
                                    data-toggle="tooltip"
                                    data-original-title="Delete"
                                    onClick={() => {
                                      deleteBanner(bannerimg._id);
                                    }}
                                  >
                                    <i class="ti-trash"></i>
                                  </NavLink> */}
                                </td>
                              </tr>
                            ))
                          ) : (
                            <h4 className="pl-4 pt-4 loading">Loading.....</h4>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* <!-- /.content --> */}
        </div>
      </div>
    </>
  );
};

export default ViewBanner;
