import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { getAdminInfo, routes } from "../../../utills/routes";
import axios from 'axios';
import { NavLink, useNavigate } from "react-router-dom";
import Cookies from 'js-cookie';
import useNotification from '../../../useNotification'; // Import the custom hook

import { useGlobalLoader } from "../../../Loader/GlobalLoaderContext";

const AdminProfile = () => {

  const navigate = useNavigate();
  useEffect(() => {
    const checkToken = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/admin/protected`,
          {
            headers: {
              Authorization: `Bearer ${Cookies.get("jwtoken")}`,
            },

            withCredentials: true, //============new
          }
        );
        const data = await response.data;
        // console.log(data);
      } catch (error) {
        navigate("/");
        // alert("Unauthorized");
      }
    };

    checkToken();
  }, []);

  const { showNotificationWithMessage } = useNotification();
  const { showLoader, hideLoader } = useGlobalLoader();

  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [password, setPassward] = useState();
  const [id, setId] = useState();

  useEffect(() => {
    const adminId = Cookies.get('adminId'); // Define the adminId variable

    if (!adminId) {
      navigate('/');
      return;
    }

    // Fetch admin information using the adminId variable
    const getAdminInfo = async () => {
      try {
        showLoader();
        const res = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/admin/GetAdminInfo/${adminId}`,
          {
            headers: {
              Authorization: `Bearer ${Cookies.get("jwtoken")}`,
            },
            withCredentials: true,
          }
        );

        const result = res.data.adminInfo;
        // console.log(result);

        setEmail(result.email);
        setName(result.name);
        setPassward(result.password);
        setId(result._id);
      } catch (err) {
        console.log(err);
      } finally {
        hideLoader();
      }
    };

    getAdminInfo();
  }, []);

  // const getAdminInfo = async () => {
  //   try {
  //     showLoader();
  //     const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/admin/GetAdminInfo`,

  //       {
  //         headers: {

  //           Authorization: `Bearer ${Cookies.get("jwtoken")}`,
  //         },
  //         withCredentials: true, // Send cookies with the request
  //       }
  //     )


  //     const result = res.data.adminInfo[0];
  //     console.log(result);

  //     setEmail(result.email);
  //     setName(result.name);
  //     setPassward(result.password);
  //     setId(result._id);


  //   } catch (err) {
  //     console.log(err);
  //   }
  //   finally {
  //     hideLoader();
  //   }
  // }
  // useEffect(() => {
  //   getAdminInfo();
  // }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      showLoader();
      // console.log(id);
      let response = await axios.put(`${process.env.REACT_APP_BACKEND_URL}/admin/UpdateAdminInfo/${id}`,
        { email, name, password },
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("jwtoken")}`,
          },
          withCredentials: true, // Send cookies with the request
        }
      );

      if (response.status === 200) {
        showNotificationWithMessage('Profile Updated successfully', 'success');

      }

    } catch (err) {
      // console.log(err);
      showNotificationWithMessage('Something went wrong', 'error');

    }
    finally {
      hideLoader();
    }
  };

  return (
    <>
      <div className="content-wrapper">
        <div
          className="container-full"

        >

          <div className="content-header" style={{ marginTop: 10 }}>

            <div className="d-flex align-items-center">
              <div className="mr-auto">
                <h3 className="page-title">Profile</h3>
                <nav>
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item"><NavLink to="/dashboard">Dashboard</NavLink></li>
                    <li class="breadcrumb-item" aria-current="page">Profile</li>
                    <li class="breadcrumb-item active" aria-current="page">Admin Profile</li>
                  </ol>
                </nav>
              </div>

            </div>
          </div>
          <section className="content" style={{ marginTop: 30 }}>
            <div className="row">
              {/* <div class="box bg-primary-light">
						<div class="box-body d-flex px-0">
							<div class="flex-grow-1 p-30 flex-grow-1 bg-img dask-bg bg-none-md" style={{
            backgroundImage: `url(https://eduadmin-template.multipurposethemes.com/bs4/images/svg-icon/color-svg/custom-1.svg)`,
            backgroundSize: "auto 100%",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "right bottom",
          }}>

								<div class="row">
									<div class="col-12 col-xl-7">
										<h2 className="welcome">Welcome back, <br/><strong className="welcome">Learnovation Admin</strong></h2>

								
									</div>
								
								</div>
							</div>
						</div>
					</div>
               */}
              <div className="col-lg-7 col-12 mx-auto">
                <form
                  onSubmit={handleSubmit}
                >
                  {/* <!-- box1 --> */}

                  <div className="box">
                    {/* <div className="box-header with-border">
                      <h4 className="box-title">My Profile</h4>
                    </div> */}
                    <div className="box-body boxpadding p-3">
                      <div className="row profile">
                        <div className="col-md-12 mt-2">
                          <div className="form-group">
                            <label className="labeltext">Email</label>
                            <input
                              type="text"
                              name="email"
                              className="form-control"
                              placeholder="Email"
                              value={email}
                              onChange={(e) => {
                                setEmail(e.target.value);
                              }}
                            />

                          </div>
                        </div>
                        <div className="col-md-12 mt-2">
                          <div className="form-group">
                            <label className="labeltext">Name</label>
                            <input
                              type="text"
                              name="name"
                              className="form-control"
                              placeholder="Name"
                              value={name}
                              onChange={(e) => {
                                setName(e.target.value);
                              }}
                            />

                          </div>
                        </div>

                        <div className="col-md-12 mt-2">
                          <div className="form-group">
                            <label className="labeltext">Password</label>
                            <input
                              type="password"
                              name="password"
                              className="form-control"
                              placeholder="Password"
                              value={password}
                              onChange={(e) => {
                                setPassward(e.target.value);
                              }}
                            />

                          </div>
                        </div>




                      </div>
                    </div>
                  </div>
                  {/* <!-- box1 --> */}








                  {/* <!-- action --> */}
                  <div className="box-footer text-center mb-4">
                    <button
                      type="submit"
                      className="btn btn-rounded  btn_dark btn-outline btncourse"
                      style={{ padding: '6px 22px' }}
                    >
                      {/* <i className="ti-save-alt"></i>  */}
                      Update
                    </button>
                  </div>

                  {/* <!-- action --> */}
                </form>
              </div>

            </div>
          </section>
        </div>

        {/* <!-- form --> */}

        {/* 		
      <!-- /.content --> */}
      </div >


    </>
  )
}

export default AdminProfile