import React, { useEffect, useState } from "react";
import "../../../admin-assets/css/style.css";
import "../../../admin-assets/css/skin_color.css";
import "../../../admin-assets/css/vendors_css.css";
import axios from "axios";
import Cookies from "js-cookie";
import { NavLink, useNavigate } from "react-router-dom";
import { notification } from "antd";
import { Button, Input, Space, Table, Checkbox } from "antd";
import Highlighter from "react-highlight-words";
import Swal from "sweetalert2";
import { useGlobalLoader } from "../../../Loader/GlobalLoaderContext";

import useNotification from "../../../useNotification"; // Import the custom hook

const DashboardHome = () => {
  const { showNotificationWithMessage } = useNotification();
  const { showLoader, hideLoader } = useGlobalLoader();
  const [course, setCourse] = useState([]); //=================new [
  const [student, setStudent] = useState([]);
  const [enquiry, setEnquiry] = useState([]);
  const navigate = useNavigate();
  // Protected route function

  useEffect(() => {
    const checkToken = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/admin/protected`,
          {
            headers: {
              Authorization: `Bearer ${Cookies.get("jwtoken")}`,
            },

            withCredentials: true, //============new
          }
        );
        const data = await response.data;
        // console.log(data);
      } catch (error) {
        navigate("/");
        // alert("Unauthorized");
      }
    };

    checkToken();
  }, []);

  //get courses
  const getCourses = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/admin/GetCoursesAtAdminDash`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("jwtoken")}`,
          },
          withCredentials: true,
        }
      );
      const data = await response.data;
      setCourse(data);
    } catch (error) {
      // notification.warning({
      // 	message: "Something went wrong",
      // });
      showNotificationWithMessage("Something went wrong", "error");
    }
  };
  useEffect(() => {
    getCourses();
  }, []);






  //get students
  const getStudents = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/admin/GetStudentAtAdminDash`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("jwtoken")}`,
          },
          withCredentials: true,
        }
      );
      const data = await response.data;
      setStudent(data);
    } catch (error) {
      // notification.warning({
      // 	message: "Something went wrong",
      // });
      showNotificationWithMessage("Something went wrong", "error");
    }
  };

  useEffect(() => {
    getStudents();
  }, []);

  //get enquiries
  const getEnquiries = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/admin/GetEnquiriesAtAdminDash`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("jwtoken")}`,
          },
          withCredentials: true,
        }
      );
      const data = await response.data;
      setEnquiry(data);
    } catch (error) {
      // notification.warning({
      // 	message: "Something went wrong",
      // });
      showNotificationWithMessage("Something went wrong", "error");
    }
  };

  useEffect(() => {
    getEnquiries();
  }, []);

  function titleCase(str) {
    // str = str.toLowerCase().split(" ");
    // for (let i = 0; i < str.length; i++) {
    //   str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
    // }
    // return str.join(" ");
  }

  //count courses
  const [coursesCount, setCoursesCount] = useState("");
  const getCoursesCount = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/admin/CountCourses`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("jwtoken")}`,
          },
          withCredentials: true,
        }
      );
      const data = await response.data;
      // console.log("cccccccccc", data);
      setCoursesCount(data);
    } catch (error) {
      showNotificationWithMessage("Something went wrong", "error");
    }
  };
  useEffect(() => {
    getCoursesCount();
  }, []);

  //count students
  const [studentsCount, setStudentsCount] = useState("");
  const getStudentsCount = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/admin/CountStudents`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("jwtoken")}`,
          },
          withCredentials: true,
        }
      );
      const data = await response.data;
      // console.log("sssssss", data);
      setStudentsCount(data);
    } catch (error) {
      showNotificationWithMessage("Something went wrong", "error");
    }
  };
  useEffect(() => {
    getStudentsCount();
  }, []);

  // countCourseEnquiry
  const [courseEnquiryCount, setCourseEnquiryCount] = useState("");
  const getCourseEnquiryCount = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/admin/CountCourseEnquiry`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("jwtoken")}`,
          },
          withCredentials: true,
        }
      );
      const data = await response.data;
      // console.log("eeeeeeee", data);
      setCourseEnquiryCount(data);
    } catch (error) {
      showNotificationWithMessage("Something went wrong", "error");
    }
  };
  useEffect(() => {
    getCourseEnquiryCount();
  }, []);



  return (
    <>
      <div className="content-wrapper">
        <div className="container-full" style={{ backgroundColor: "#ffffff" }}>
          {/* <!-- Main content --> */}
          <section className="content">

            {/* counter */}

            <div className="row">



              <div className="col-12">
                <div className="box no-shadow mb-0 bg-transparent">
                  <div className="box-header no-border px-0">
                    <h4 className="welcomefont">Hi, Welcome 👋</h4>



                  </div>
                </div>
              </div>

            </div>


            <div className="row">
              <div className="col-lg-4 col-md-6 col-12">
                <div className="box pull-up counterbox">
                  <div class="box-body">
                    <div className="row ">
                      <div className="col-lg-6">
                        <img src="../../admin-assets/images/stock.png" className="pt-4" alt="" />
                      </div>
                      <div className="col-lg-6">
                        <div className="pt-4">
                          <h5 className="mb-0 countertitle">{coursesCount}</h5>
                          <p className="font-size-12 mb-0 countertext">Courses</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>


              <div className="col-lg-4 col-md-6 col-12">
                <div className="box pull-up counterbox counterbox1">
                  <div class="box-body">
                    <div className="row">
                      <div className="col-lg-6">
                        <img src="../../admin-assets/images/user-icon.png" className="pt-4" alt="" />
                      </div>
                      <div className="col-lg-6">
                        <div className="pt-4">
                          <h5 className="mb-0 countertitle countertitle1">{studentsCount}</h5>
                          <p className="font-size-12 mb-0 countertext countertext1">Students</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>


              <div className="col-lg-4 col-md-6 col-12">
                <div className="box pull-up counterbox counterbox2">
                  <div class="box-body">
                    <div className="row">
                      <div className="col-lg-6">
                        <img src="../../admin-assets/images/fund.png" className="pt-4" alt="" />
                      </div>
                      <div className="col-lg-6">
                        <div className="pt-4">
                          <h5 className="mb-0 countertitle countertitle2">{courseEnquiryCount}</h5>
                          <p className="font-size-12 mb-0 countertext countertext2">Enquiries</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>


              {/* <div className="col-lg-4 col-md-6 col-12">
					<div className="box pull-up counterbox counterbox1">
						<div className="box-body d-flex">
            <div className="p-2 bd-highlight"><img src="../../admin-assets/images/user.png" alt="" class="align-self-end"/></div>
            <div className="p-2 bd-highlight"><div className="d-flex flex-column flex-grow-1">
								<h5 className="box-title font-size-16 mb-2 countertitle countertitle1">4</h5>
                <p className="countertext countertext1">Students</p>
							</div>
            </div>
							
						</div>
					</div>
				</div> */}


              {/* <div className="col-lg-4 col-md-6 col-12">
					<div className="box pull-up counterbox counterbox1">
						<div className="box-body"> */}


              {/* <div className="row">
              <div className="col-lg-4 text-center pl-2">
              <div className="icon bg-primary-light rounded-circle text-center pl-20 pt-3">											
										<img src="../../admin-assets/images/user2.png" alt="" className="align-self-end "/>
										</div>
              </div>
              <div className="col-lg-8">
              <div className="ml-15 pl-20 pt-4">											
											<h5 className="mb-0 countertitle countertitle1">4</h5>
											<p className="font-size-12 mb-0 countertext countertext1">Students</p>
										</div>
              </div>
            </div> */}
              {/* <div className="d-flex align-items-center">
										<div className="icon bg-primary-light rounded-circle text-center">											
										<img src="../../admin-assets/images/user.png" alt="" className="align-self-end"/>
										</div>
										<div className="ml-15">											
											<h5 className="mb-0 countertitle countertitle1">4</h5>
											<p className="font-size-12 mb-0 countertext countertext1">Students</p>
										</div>
									</div> */}

              {/* </div>
					</div>
				</div> */}

              {/* 
        <div className="col-lg-4 col-md-6 col-12">
					<div className="box pull-up counterbox counterbox2">
						<div className="box-body">
            <div className="row">
              <div className="col-lg-4 text-center pl-2">
              <div className="icon bg-primary-light rounded-circle text-center pl-20 pt-3">											
										<img src="../../admin-assets/images/enquiry1.png" alt="" className="align-self-end "/>
										</div>
              </div>
              <div className="col-lg-8">
              <div className="ml-15 pl-20 pt-4">											
											<h5 className="mb-0 countertitle countertitle2">1</h5>
											<p className="font-size-12 mb-0 countertext countertext2">Course Enquiry</p>
										</div>
              </div>
            </div> */}
              {/* <div className="d-flex align-items-center">
										<div className="icon bg-primary-light rounded-circle text-center">											
										<img src="../../admin-assets/images/bullish.png" alt="" className="align-self-end "/>
										</div>
										<div className="ml-15">											
											<h5 className="mb-0 countertitle countertitle2">1</h5>
											<p className="font-size-12 mb-0 countertext countertext2">Course Enquiry</p>
										</div>
									</div> */}

              {/* </div>
					</div>
				</div> */}


              {/* <div className="col-lg-4 col-md-6 col-12">
					<div className="box pull-up counterbox counterbox2">
						<div className="box-body d-flex">
            <div className="p-2 bd-highlight"><img src="../../admin-assets/images/bullish.png" alt="" class="align-self-end "/></div>
            <div className="p-2 bd-highlight"><div className="d-flex flex-column flex-grow-1">
								<h5 className="box-title font-size-16 mb-2 countertitle countertitle2">1</h5>
							<p className="countertext countertext2">Course Enquiry</p>
							</div></div>
							
						</div>
					</div>
				</div> */}








            </div>

            {/* counter */}


            {/* <div className="row">  
              <div className="col-12">
                <div className="box no-shadow mb-0 bg-transparent">
                  <div className="box-header no-border px-0">
                    <h4 className="box-title">Popular Courses</h4>
                  
                    <div className="box-controls pull-right d-md-flex d-none">
                    <NavLink
                  className="waves-effect waves-light btn btn_dark btndash"
                  to="/courselist"
                >
               View All
                </NavLink>
                    </div>
                  </div>
                </div>
              </div>




              {course.length > 0 ? (
                course.map((course, index) => (
                  <div className="col-lg-3 col-md-6 col-12">
                    <div className="box pull-up p-3 dashboardcourse">
                      <div class="d-flex flex-row">
                        <div class="p-2">
                          <img
                            src={course.image}
                            alt=""
                            className="align-self-end imgborder img-fluid"
                          />
                        </div>
                        <div class="py-2 px-2 dtitle align-items-center">{course.Name}</div>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <h6 className="pl-4 pt-4 nodatafound">No Data Found</h6>
              )}

            </div> */}

            {/* student section  */}

            <div className="row fx-element-overlay mt-4">


              <div className="col-12">
                <div class="box">
                  <div class="box-body">
                    <div className="box no-shadow mb-0 bg-transparent">
                      <div className="box-header no-border px-0">
                        <h4 className="box-title pl-3">Students List</h4>
                        {/* <ul className="box-controls pull-right d-md-flex d-none">
                      <li>
                        <NavLink
                          to="/Students"
                          className="waves-effect waves-light btn-block btn btn_dark addcourse"
                        >
                          View All
                        </NavLink>
                      </li>
                    </ul> */}

                        <div className="box-controls pull-right d-md-flex d-none">
                          <NavLink
                            className="waves-effect waves-light btn btn_dark btndash"
                            to="/students"
                          >
                            View All
                          </NavLink>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="table-responsive">
                    <table
                      id="example"
                      className="table table-hover display nowrap margin-top-10 w-p100"
                    >
                      <thead className="tablehead">
                        <tr>
                          <th className="srno">Sr No.</th>
                          <th>Email</th>
                          <th>Name</th>

                          <th>Phone</th>
                          <th>City</th>
                          <th>Date</th>

                        </tr>
                      </thead>
                      <tbody>
                        {student.length > 0 ? (
                          student.map((student, index) => (
                            <tr key={student._id}>
                              <td className="srno">{index + 1}</td>
                              <td><NavLink className="acolor" to={`mailto:${student.email}`}>{student.email}</NavLink></td>
                              <td>{student.name}</td>
                              {/* <td>{student.email}</td> */}
                              {/* <td>{student.phoneNumber}</td> */}

                              <td>
                                <NavLink className="acolor" to={`tel:${student.phoneNumber}`}>
                                  {student.phoneNumber}
                                </NavLink>
                              </td>
                              <td>
                                <NavLink className="acolor" to={`tel:${student.city}`}>
                                  {student.city}
                                </NavLink>
                              </td>

                              <td>
                                {new Date(student.date).toLocaleDateString(
                                  "en-GB"
                                )}
                              </td>



                            </tr>
                          ))
                        ) : (
                          <h6 className="pl-4 pt-4 nodatafound">No Data Found</h6>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            {/* Enquiry section */}

            <div className="row fx-element-overlay mt-4 mb-4">
              {/* <div className="col-12">
                <div className="box no-shadow mb-0 bg-transparent">
                  <div className="box-header no-border px-0">
                    <h4 className="box-title">Course Enquiries</h4>

                    <div className="box-controls pull-right d-md-flex d-none">
                    <NavLink
                  className="waves-effect waves-light btn btn_dark btndash"
                  to="/course-enquiry"
                >
               View All
                </NavLink>
                    </div>


                  </div>
                </div>
              </div> */}

              <div className="col-12">
                <div class="box">
                  <div class="box-body">
                    <div className="box no-shadow mb-0 bg-transparent">
                      <div className="box-header no-border px-0">
                        <h4 className="box-title pl-3">Course Enquiries</h4>

                        <div className="box-controls pull-right d-md-flex d-none">
                          <NavLink
                            className="waves-effect waves-light btn btn_dark btndash"
                            to="/course-enquiry"
                          >
                            View All
                          </NavLink>
                        </div>


                      </div>
                    </div>

                    <div class="table-responsive">
                      <table
                        id="example"
                        class="table table-hover display nowrap margin-top-10 w-p100"
                      >
                        <thead className="tablehead">
                          <tr>
                            <th className="srno">Sr No.</th>
                            <th>Name</th>
                            <th>Course Name</th>
                            <th>Email</th>
                            <th>Phone</th>
                            <th>Date</th>

                          </tr>
                        </thead>
                        <tbody>
                          {enquiry.length > 0 ? (
                            enquiry.map((enquiry, index) => (
                              <tr key={enquiry._id}>
                                <td className="srno">{index + 1}</td>
                                <td>{enquiry.studentname}</td>
                                <td>{enquiry.title}</td>
                                {/* <td>{enquiry.email}</td> */}
                                {/* <td>{enquiry.phone}</td> */}

                                <td><NavLink className="acolor" to={`mailto:${enquiry.email}`}>{enquiry.email}</NavLink></td>

                                <td>
                                  <NavLink className="acolor" to={`tel:${enquiry.phone}`}>
                                    {enquiry.phone}
                                  </NavLink>
                                </td>
                                <td>
                                  {new Date(enquiry.createdAt).toLocaleDateString(
                                    "en-GB"
                                  )}
                                </td>

                              </tr>
                            ))
                          ) : (
                            <h6 className="pl-4 pt-4 nodatafound">No Data Found</h6>
                          )}
                        </tbody>
                      </table>
                    </div>
                    {/* <div className="box-footer no-border px-0">
                  

                    <div className="box-controls pull-right d-md-flex d-none">
                    <NavLink
                  className="waves-effect waves-light btn btn_dark btndash"
                  to="/course-enquiry"
                >
               View All
                </NavLink>
                    </div>


                  </div> */}
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* <!-- /.content --> */}
        </div>
      </div>
    </>
  );
};

export default DashboardHome;
