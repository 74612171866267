import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { NavLink, useNavigate } from "react-router-dom";
import { Button, Input, Space, Table, notification } from "antd";
import useNotification from "../../../../useNotification";
import Swal from "sweetalert2";
import { SearchOutlined } from "@ant-design/icons";
import { useGlobalLoader } from "../../../../Loader/GlobalLoaderContext";
import Highlighter from "react-highlight-words";

const ViewGalleryImg = () => {
    const navigate = useNavigate();
    useEffect(() => {
        const checkToken = async () => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_BACKEND_URL}/admin/protected`,
                    {
                        headers: {
                            Authorization: `Bearer ${Cookies.get("jwtoken")}`,
                        },

                        withCredentials: true, //============new
                    }
                );
                const data = await response.data;
            } catch (error) {
                navigate("/");
            }
        };

        checkToken();
    }, []);

    const { showLoader, hideLoader } = useGlobalLoader();
    const { showNotificationWithMessage } = useNotification();

    const [searchText, setSearchText] = useState("");
    const [searchedColumn, setSearchedColumn] = useState("");
    const searchInput = useRef(null);

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
            close,
        }) => (
            <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                    }
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: "block" }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({ closeDropdown: false });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} />
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ""}
                />
            ) : (
                text
            ),
    });
    const [columns, setColumns] = useState([
        { title: "Sr No.", dataIndex: "index", key: "index", className: "srno" },
        {
            title: "Title",
            dataIndex: "title",
            key: "title",
            ...getColumnSearchProps("title"),
        },

        {
            title: "Image", dataIndex: "image", key: "image",
            render: (text) => <img src={text} style={{ height: "70px", objectFit: "cover", maxWidth: "70px" }} alt="image" />
        },
        {
            title: "Action",
            dataIndex: "action",
            key: "action",
            //   render: () => <a>Delete</a>,
        },
    ]);
    const [data, setData] = useState([]);
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText("");
    };

    const getMedia = async () => {
        try {
            showLoader();
            const response = await axios.get(
                `${process.env.REACT_APP_BACKEND_URL}/admin/GetGallery`,
                {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${Cookies.get("jwtoken")}`,
                    },
                    withCredentials: true,
                }
            );
            const data = await response.data.data;
            // console.log(data)
            let newData = data.map((item, index) => {
                return {
                    index: index + 1,
                    title: item.title,
                    image: item.galleryImageURL,
                    action: (
                        <div className="btn-group">
                            <NavLink
                                to={"/update-gallery/" + item._id}
                                className="btn-circle btn-info btn-xs mr-10 pl-2"
                                data-toggle="tooltip"
                                data-original-title="Edit"
                            >
                                <i class="ti-marker-alt"></i>
                            </NavLink>
                            <button
                                type="button"
                                className="btn-circle btn-danger btn-xs"

                                onClick={() => deleteMedia(item._id)}
                            >
                                <i className="fa fa-trash"></i>
                            </button>
                        </div>
                    ),
                };
            });
            setData(newData);
            if (!response.status === 200) {
                const error = new Error(response.message);
                throw error;
            }
        } catch (error) {
            console.error("Error fetching media:", error);
        }
        finally {
            hideLoader();
        }
    };

    const deleteMedia = async (id) => {
        try {
            const confirmed = await Swal.fire({
                title: "Are you sure?",
                text: "Are you sure you want to delete this image?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, delete it!",
            });

            if (!confirmed.isConfirmed) {
                return;
            }
            showLoader();
            const response = await axios.delete(
                `${process.env.REACT_APP_BACKEND_URL}/admin/DeleteGallery/${id}`,
                {
                    headers: {
                        Authorization: `Bearer ${Cookies.get("jwtoken")}`,
                    },
                    withCredentials: true, // Send cookies with the request
                }
            );
            if (response.data) {
                getMedia();
                showNotificationWithMessage("Image is deleted", "success");
            }
        } catch (error) {
            showNotificationWithMessage("Something went wrong", "error");
        }
        finally {
            hideLoader();
        }
    };

    useEffect(() => {
        getMedia();
    }, []);

    return (
        <>
            <div className="content-wrapper">
                <div className="container-full">
                    <section className="content">
                        <div className="row">
                            <div class="col-12" style={{ marginBottom: 20 }}>
                                <div className="content-header pagetitle-pl">
                                    <div className="d-flex align-items-center">
                                        <div className="mr-auto">
                                            <h3 className="page-title">Gallery</h3>
                                            <nav>
                                                <ol class="breadcrumb">
                                                    <li class="breadcrumb-item"><NavLink to="/dashboard">Dashboard</NavLink></li>
                                                    <li class="breadcrumb-item" aria-current="page"><NavLink to="/gallery">Gallery</NavLink></li>
                                                    <li class="breadcrumb-item active" aria-current="page">Gallery List</li>
                                                </ol>
                                            </nav>
                                        </div>
                                        <div className="mr-end">
                                            <NavLink
                                                className="waves-effect waves-light btn-block btn btn_dark addcourse"
                                                to="/add-gallery"
                                            >
                                                <i className="fa fa-plus mr-10"></i>
                                                Add Gallery Images
                                            </NavLink>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-10 mx-auto">
                                <div className="box" style={{ marginTop: 50 }}>
                                    <div className="box-body">
                                        <Table
                                            className="customTable"
                                            columns={columns}
                                            dataSource={data}
                                            pagination={{ pageSize: 5 }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* <!-- /.content --> */}
                </div>
            </div>
        </>
    )
}
export default ViewGalleryImg