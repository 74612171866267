import React, { useEffect, useState } from 'react'
import { notification } from 'antd';
import axios from 'axios';
import Cookies from 'js-cookie';
import { NavLink, useNavigate } from 'react-router-dom';
import useNotification from '../../../useNotification'; // Import the custom hook
import Swal from 'sweetalert2';
import { useGlobalLoader } from "../../../Loader/GlobalLoaderContext";

const ViewTestimonial = () => {
  useEffect(() => {
    const checkToken = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/admin/protected`,
          {
            headers: {
              Authorization: `Bearer ${Cookies.get("jwtoken")}`,
            },

            withCredentials: true, //============new
          }
        );
        const data = await response.data;
        console.log(data);
      } catch (error) {
        navigate("/");
        // alert("Unauthorized");
      }
    };

    checkToken();
  }, []);

  const { showLoader, hideLoader } = useGlobalLoader();
  const { showNotificationWithMessage } = useNotification();

  const [testimonial, setTestimonial] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    getTestimonial();
  }, [])

  const getTestimonial = async () => {
    try {
      showLoader();
      const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/admin/GetTestimonial`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("jwtoken")}`,
          },
          withCredentials: true,
        }
      );
      const data = await res.data;
      setTestimonial(data)
    } catch (error) {
      console.error("Error fetching Testimonials", error);
      navigate("/");

    }
    finally {
      hideLoader();
    }
  };

  // delete testimonial
  const deleteTestimonial = async (id) => {
    try {
      // Show the confirmation popup
      const confirmed = await Swal.fire({
        title: 'Are you sure?',
        text: 'You are about to delete this testimonial.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
      });

      if (!confirmed.isConfirmed) {
        return; // If user cancels, do nothing
      }

      showLoader();
      const response = await axios.delete(
        `${process.env.REACT_APP_BACKEND_URL}/admin/DeleteTestimonial/${id}`,
        {
          headers: {
            Authorization: `Bearer ${Cookies.get('jwtoken')}`,
          },
          withCredentials: true, // Send cookies with the request
        }
      );

      if (response.data) {
        // notification.warning({
        //   message: "Record is deleted",
        // });
        getTestimonial();
        showNotificationWithMessage('Testimonial is deleted', 'success');
      }
    } catch (error) {
      // notification.warning({
      //   message: "Something went wrong",
      // });
      showNotificationWithMessage('Something went wrong', 'error');
    }
    finally {
      hideLoader();
    }
  };


  return (
    <>
      <div className="content-wrapper">
        <div className="container-full">
          {/* <!-- Content Header (Page header) --> */}
          {/* <div className="content-header">
            <div className="d-flex align-items-center">
              <div className="mr-auto">
                <h3 className="page-title">Testimonial List</h3>
                <nav>
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item"><NavLink to="/dashboard">Dashboard</NavLink></li>
                    <li className="breadcrumb-item" aria-current="page">Testimonial</li>
                    <li className="breadcrumb-item active" aria-current="page">Testimonial List</li>
                  </ol>
                </nav>
              </div>
              <div className="mr-end">
                <NavLink
                  className="waves-effect waves-light btn-block btn btn_dark addcourse"
                  to="/add-testimonial"
                >
                  <i className="fa fa-plus mr-15"></i>Add Testimonial
                </NavLink>
              </div>
            </div>
          </div> */}

          {/* <!-- Main content --> */}
          <section className="content">
            <div className="row">

            <div class="col-12"  style={{ marginBottom: 20 }}>														
            <div className="content-header pagetitle-pl">
            <div className="d-flex align-items-center">
              <div className="mr-auto">
              <h3 className="page-title">Testimonial List</h3>
                <nav>
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><NavLink to="/dashboard">Dashboard</NavLink></li>
                    <li className="breadcrumb-item" aria-current="page">Testimonial</li>
                    <li className="breadcrumb-item active" aria-current="page">Testimonial List</li>
                  </ol>
                </nav>
              </div>
              <div className="mr-end">
              <NavLink
                  className="waves-effect waves-light btn-block btn btn_dark addcourse"
                  to="/add-testimonial"
                  style={{ padding: '6px 22px' }}
                >
                  <i className="fa fa-plus mr-10"></i>
                  Add Testimonial
                </NavLink>
              </div>
            </div>
          </div>
				</div>
              <div className="col-12">
                <div className="box" style={{ marginTop: 50 }}>
                  <div className="box-body">
                    <div className="table-responsive">
                      <table
                        id="productorder"
                        className="table table-hover no-wrap product-order"
                        data-page-size="10"
                      >
                        {/* <thead> */}
                        <thead>
                          <tr>
                            <th className='srno'>Sr No.</th>
                            <th>Title</th>
                            <th>Name</th>
                            <th>Profession</th>

                            <th>Image</th>

                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {testimonial.length > 0 ? (
                            testimonial.map((testimonial, index) => (
                              <tr  key={testimonial._id}>
                                <td className='srno'>{index + 1}</td>
                                <td>{testimonial.testiTitle}</td>
                                <td>{testimonial.testiName}</td>
                                <td>{testimonial.testiProfession}</td>
                                {/* <td>{testimonial.testiInfo}</td> */}

                                <td>
                                  <img
                                    src={testimonial.testiImageURL}
                                    alt="Blog Image"
                                    width="80"
                                  />
                                </td>


                                <td>
                                  <NavLink
                                    to={"/update-testimonial/" + testimonial._id}
                                    className="btn btn-circle btn-info btn-xs mr-10"
                                    data-toggle="tooltip"
                                    data-original-title="Edit"
                                  >
                                    <i className="ti-marker-alt"></i>
                                  </NavLink>
                                  <NavLink
                                    to="javascript:void(0)"
                                    className="btn btn-circle btn-danger btn-xs"
                                    title=""
                                    data-toggle="tooltip"
                                    data-original-title="Delete"
                                    onClick={() => {
                                      deleteTestimonial(testimonial._id);
                                    }}
                                  >
                                    <i className="ti-trash"></i>
                                    
                                  </NavLink>


                                </td>
                              </tr>
                            ))
                          ) : (
                            <h4 className="pl-4 pt-4 loading">Loading.....</h4>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* <!-- /.content --> */}
        </div>
      </div>
    </>
  )
}

export default ViewTestimonial