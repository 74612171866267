import { notification } from "antd";
import axios from "axios";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { Button, Input, Space, Table } from "antd";
import { NavLink, useNavigate } from "react-router-dom";
import useNotification from "../../../useNotification"; // Import the custom hook
import Swal from "sweetalert2";
import { useGlobalLoader } from "../../../Loader/GlobalLoaderContext";
import debounce from "../../../Debounce";

const ViewBlogList = () => {
  useEffect(() => {
    const checkToken = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/admin/protected`,
          {
            headers: {
              Authorization: `Bearer ${Cookies.get("jwtoken")}`,
            },

            withCredentials: true, //============new
          }
        );
        const data = await response.data;
        // console.log(data);
      } catch (error) {
        navigate("/");
        // alert("Unauthorized");
      }
    };

    checkToken();
  }, []);

  const { showLoader, hideLoader } = useGlobalLoader();
  const { showNotificationWithMessage } = useNotification();
  const [searchValue, setSearchValue] = useState([]);

  const [blogs, setBlogs] = useState([]);

  const navigate = useNavigate();

  // columns
  const [columns, setColumns] = useState([
    { title: "Sr No.", dataIndex: "index", key: "index", className: "srno" },
    {
      title: "Title",
      dataIndex: "blogTitle",
      key: "blogTitle",
    },
    {
      title: "Thumb Image",
      dataIndex: "blogThumbImageURL",
      key: "blogThumbImageURL",
    },
    {
      title: "Poster Image",
      dataIndex: "blogDetailImageURL",
      key: "blogDetailImageURL",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
    },
  ]);
  const [data, setData] = useState([]);

  // columns

  useEffect(() => {
    getBlogs();
  }, []);
  const getBlogs = async () => {
    try {
      showLoader();

      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/admin/GetBlog`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("jwtoken")}`,
          },
          withCredentials: true, // Send cookies with the request
        }
      );
      const data = await response.data;
      setBlogs(data);
      let newData = [];
      data.map((item, index) => {
        newData.push({
          key: item._id,
          index: index + 1,
          blogTitle: item.blogTitle,
          blogThumbImageURL: (
            <>
              <img src={item.blogThumbImageURL} alt="Blog Image" width="80" />
            </>
          ),
          blogDetailImageURL: (
            <>
              <img src={item.blogDetailImageURL} alt="Blog Image" width="80" />
            </>
          ),
          action: (
            <>
              <NavLink
                to={"/update-blog/" + item._id}
                className="btn btn-circle btn-info btn-xs mr-10"
                data-toggle="tooltip"
                data-original-title="Edit"
              >
                <i className="ti-marker-alt"></i>
              </NavLink>
              <NavLink
                to="javascript:void(0)"
                className="btn btn-circle btn-danger btn-xs"
                title=""
                data-toggle="tooltip"
                data-original-title="Delete"
                onClick={() => {
                  deleteBlog(item._id);
                }}
              >
                <i className="ti-trash"></i>
              </NavLink>
            </>
          ),
        });
      });
      setData(newData);

      if (!response.status === 200) {
        const error = new Error(response.error);
        throw error;
      }
    } catch (error) {
      console.error("Error fetching blogs:", error);
      navigate("/");
    } finally {
      hideLoader();
    }
  };

  //delete blog
  const deleteBlog = async (id) => {
    try {
      // Show the confirmation popup
      const confirmed = await Swal.fire({
        title: "Are you sure?",
        text: "You are about to delete this blog.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      });

      if (!confirmed.isConfirmed) {
        return; // If user cancels, do nothing
      }

      showLoader();
      const response = await axios.delete(
        `${process.env.REACT_APP_BACKEND_URL}/admin/DeleteBlog/${id}`,
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("jwtoken")}`,
          },
          withCredentials: true, // Send cookies with the request
        }
      );

      if (response.data) {
        // notification.warning({
        //   message: "Record is deleted",
        // });
        getBlogs();
        showNotificationWithMessage("blog is deleted", "success");
      }
    } catch (error) {
      // notification.warning({
      //   message: "Something went wrong",
      // });
      showNotificationWithMessage("Something went wrong", "error");
    } finally {
      hideLoader();
    }
  };

  // search function   ========================

  const searchblog = debounce(async (event) => {
    try {
      const inputValue = document.querySelector('input[name="search"]').value;
      if (inputValue) {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/admin/SearchBlog/${inputValue}`,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (response) {
          let data = response.data;
          setBlogs(data);
          let newData = [];
          data.map((item, index) => {
            newData.push({
              key: item._id,
              index: index + 1,
              blogTitle: item.blogTitle,
              blogThumbImageURL: (
                <>
                  <img
                    src={item.blogThumbImageURL}
                    alt="Blog Image"
                    width="80"
                  />
                </>
              ),
              blogDetailImageURL: (
                <>
                  <img
                    src={item.blogDetailImageURL}
                    alt="Blog Image"
                    width="80"
                  />
                </>
              ),
              action: (
                <>
                  <NavLink
                    to={"/update-blog/" + item._id}
                    className="btn btn-circle btn-info btn-xs mr-10"
                    data-toggle="tooltip"
                    data-original-title="Edit"
                  >
                    <i className="ti-marker-alt"></i>
                  </NavLink>
                  <NavLink
                    to="javascript:void(0)"
                    className="btn btn-circle btn-danger btn-xs"
                    title=""
                    data-toggle="tooltip"
                    data-original-title="Delete"
                    onClick={() => {
                      deleteBlog(item._id);
                    }}
                  >
                    <i className="ti-trash"></i>
                  </NavLink>
                </>
              ),
            });
          });
          setData(newData);
          // console.log(response);
        }
      } else {
        getBlogs();
      }
    } catch (error) {
      showNotificationWithMessage("something went wrong", "error");
      console.error(error);
    }
  }, 2000);

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault(); // prevent default behavior
      searchblog(searchValue);
    }
  };

  return (
    <>
      <div className="content-wrapper">
        <div className="container-full">
          {/* <!-- Content Header (Page header) --> */}
          {/* <div className="content-header">
            <div className="d-flex align-items-center">
              <div className="mr-auto">
                <h3 className="page-title">Blog List</h3>
                <nav>
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item"><NavLink to="/dashboard">Dashboard</NavLink></li>
                    <li className="breadcrumb-item" aria-current="page"><NavLink to="/blog">Blog</NavLink></li>
                    <li className="breadcrumb-item active" aria-current="page">Blog List</li>
                  </ol>
                </nav>
              </div>
              <div className="mr-end">
                <NavLink
                  className="waves-effect waves-light btn-block btn btn_dark addcourse"
                  to="/add-blog"
                >
                  <i className="fa fa-plus mr-15"></i>Add Blog
                </NavLink>
              </div>
            </div>
          </div> */}

          {/* <!-- Main content --> */}
          <section className="content">
            <div className="row">
              <div class="col-12" style={{ marginBottom: 20 }}>
                <div className="content-header pagetitle-pl">
                  <div className="d-flex align-items-center">
                    <div className="mr-auto">
                      <h3 className="page-title">Blog List</h3>
                      <nav>
                        <ol className="breadcrumb">
                          <li className="breadcrumb-item">
                            <NavLink to="/dashboard">Dashboard</NavLink>
                          </li>
                          <li className="breadcrumb-item" aria-current="page">
                            <NavLink to="/blog">Blog</NavLink>
                          </li>
                          <li
                            className="breadcrumb-item active"
                            aria-current="page"
                          >
                            Blog List
                          </li>
                        </ol>
                      </nav>
                    </div>
                    <div className="mr-end">
                      <NavLink
                        className="waves-effect waves-light btn-block btn btn_dark addcourse"
                        to="/add-blog"
                        style={{ padding: "6px 22px" }}
                      >
                        <i className="fa fa-plus mr-10"></i>
                        Add Blog
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12">
                <div className="box" style={{ marginTop: 50 }}>
                  <div className="box-body">
                    <div className="row align-item center">
                      <div className="col-6 mx-auto">
                        <div
                          class="app-menu"
                          style={{ marginTop: 30, marginBottom: 30 }}
                        >
                          <div class="search-bx ml-5">
                            <div class="input-group">
                              <input
                                type="search"
                                className="form-control searchcourse"
                                name="search"
                                value={searchValue}
                                placeholder="Search"
                                aria-label="Search"
                                aria-describedby="button-addon2"
                                onChange={(e) => {
                                  setSearchValue(e.target.value);
                                  searchblog(e.target.value);
                                }}
                                onKeyPress={handleKeyPress}
                              />
                              <div class="input-group-append appendsearch">
                                <button
                                  class="btn"
                                  type="submit"
                                  id="button-addon3"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    searchblog(searchValue);
                                  }}
                                >
                                  <i class="ti-search"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="table-responsive">
                      <Table
                        className="customTableBlog"
                        columns={columns}
                        dataSource={data}
                        pagination={{ pageSize: 10 }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* <!-- /.content --> */}
        </div>
      </div>
    </>
  );
};

export default ViewBlogList;
