// Debounce.js
export default function debounce(func, delay) {
    // console.log("Debounce.js called");
    let timeoutId;
    return function (...args) {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
            func(...args);
        }, delay);
    };
}
