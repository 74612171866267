import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";

const ServiceCard = ({
  title,
  imageStyles,
  backgrondColor,
  img,
  shortdescription,
  textStyles,
  id
}) => {
  const [mobile, setMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth < 991) {
      setMobile(true);
    }
  }, [window]);

  return (
    // <div className="block">
    //   <img
    //     src={img}
    //     alt=""
    //     className="img-fluid card_radius"
    //   />
    //   <div className="row align-items-center" style={textStyles}>
    //     <div className="col col-lg-7 col-md-7">
    //       <div className="item_content">
    //         <h2 className="title_text">{title}</h2>
    //         <p className="spara">
    //           {shortdescription}
    //         </p>
    //         <NavLink className="btn btn_primary servicemt" to="/Servicedetail">
    //           <span>
    //             <small>Learn More</small>
    //             <small>Learn More</small>
    //           </span>
    //           <i className="far fa-long-arrow-right ml-2"></i>
    //         </NavLink>
    //       </div>
    //     </div>
    //   </div>
    // </div>

    mobile ? (
      <>
        <div
          style={{
            background: backgrondColor,
            borderRadius: "30px",
            display:'flex',
            flexDirection:'column',
            alignItems:'center',
            padding:'2rem'
          }}
          className="block"
        >
          <div className="row align-items-center" >
            <div className="col" style={{marginBottom:'2rem'}}>
              <div className="item_content">
                <h2 className="title_text">{title}</h2>
                <p className="spara">{shortdescription}</p>
                <NavLink
                  className="btn btn_primary servicemt"
                  to={`/Servicedetail/${id}`}
                >
                  <span>
                    <small>Learn More</small>
                    <small>Learn More</small>
                  </span>
                  <i className="far fa-long-arrow-right ml-2"></i>
                </NavLink>
              </div>
            </div>
          </div>
          <img src={img} alt="" className="img-fluid" style={{width:'15rem',}} />
        </div>
      </>
    ) : (
      <>
        <div
          style={{
            background: backgrondColor,
            borderRadius: "30px",
            minHeight:"560px",
          }}
          className="block"
        >
          <img src={img} alt="" className="img-fluid" style={imageStyles} />
          <div className="row align-items-center" style={textStyles}>
            <div className="col">
              <div className="item_content">
                <h2 className="title_text">{title}</h2>
                <p className="spara">{shortdescription}</p>
                <NavLink
                  className="btn btn_primary servicemt"
                  to={`/Servicedetail/${id}`}
                >
                  <span>
                    <small>Learn More</small>
                    <small>Learn More</small>
                  </span>
                  <i className="far fa-long-arrow-right ml-2"></i>
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  );
};

export default ServiceCard;
