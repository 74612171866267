import React, { useEffect, useState } from "react";
import axios from "axios";
import { NavLink } from "react-router-dom";
import Cookies from "js-cookie";
import { Select, notification } from "antd";
import { useNavigate, useParams } from "react-router";
import useNotification from "../../../../useNotification";
import { useGlobalLoader } from "../../../../Loader/GlobalLoaderContext";

const UpdateGalleryImg = () => {

    useEffect(() => {
        const checkToken = async () => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_BACKEND_URL}/admin/protected`,
                    {
                        headers: {
                            Authorization: `Bearer ${Cookies.get("jwtoken")}`,
                        },

                        withCredentials: true,
                    }
                );
                const data = await response.data;
            } catch (error) {
                navigate("/");
            }
        };
        checkToken();
    }, []);

    const clearFieldError = (fieldName) => {
        setFormErrors({ ...formErrors, [fieldName]: undefined });
    };

    const navigate = useNavigate();
    const { showLoader, hideLoader } = useGlobalLoader();
    const { showNotificationWithMessage } = useNotification();
    const [title, setTitle] = useState("");
    const [galleryImage, setgalleryImage] = useState(null);
    const params = useParams();

    const getGalleryImg = async () => {
        try {
            showLoader();

            const response = await axios.get(
                `${process.env.REACT_APP_BACKEND_URL}/admin/GetEditGallery/${params.id}`,
                {
                    headers: {
                        Authorization: `Bearer ${Cookies.get("jwtoken")}`,
                    },
                    withCredentials: true, // Send cookies with the request
                }
            );
            const result = response.data.data;
            setgalleryImage(result.galleryImageURL);
            setTitle(result.title);
        } catch (error) {
            showNotificationWithMessage('Something went wrong', 'error');
        }
        finally {
            hideLoader();
        }
    };

    useEffect(() => {
        getGalleryImg();
    }, []);

    const [formErrors, setFormErrors] = useState({});

    const validateForm = () => {
        const errors = {};
        if (!title) {
            errors.title = "Title is required";
        }
        if (!galleryImage) {
            errors.galleryImage = " Image is required";
        }
        return errors;
    };

    const UpdateGalleryImg = async () => {
        const errors = validateForm();
        if (Object.keys(errors).length > 0) {
            setFormErrors(errors);
            return;
        }
        setFormErrors({});

        const formData = new FormData();
        formData.append("title", title);
        formData.append("galleryImage", galleryImage);

        try {
            showLoader();
            const response = await axios.put(
                `${process.env.REACT_APP_BACKEND_URL}/admin/UpdateGallery/${params.id}`,
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        Authorization: `Bearer ${Cookies.get("jwtoken")}`,
                    },
                    withCredentials: true, // Send cookies with the request
                }
            );
            console.warn(response.data);

            if (response.status === 200) {
                showNotificationWithMessage("Image update successfully", "success");
                setTimeout(() => {
                    navigate("/gallery");
                }, 1000);
            }
        } catch (error) {
            console.error("Error updating image:", error);
            showNotificationWithMessage("Error updating image", "error");
        } finally {
            hideLoader();
        }
    };

    const scrollToError = (formErrors, UpdateGalleryImg) => {
        if (formErrors) {
            const errorField = Object.keys(formErrors)[0];
            const field = document.getElementsByName(errorField)[0];
            if (field) {
                field.scrollIntoView({ behavior: "smooth", block: "center" });
            }
            UpdateGalleryImg();
        } else {
            UpdateGalleryImg();
        }
    };

    return (
        <>
            <div className="content-wrapper">
                <div className="container-full">
                    <div className="content-header">
                        <div className="d-flex align-items-center">
                            <div className="mr-auto">
                                <h3 className="page-title">Update Gallery Image</h3>
                                <div className="d-inline-block align-items-center">
                                    <nav>
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item">
                                                <NavLink to="/dashboard">Dashboard</NavLink>
                                            </li>
                                            <li className="breadcrumb-item" aria-current="page">
                                                <NavLink to="/gallery">Gallery</NavLink>
                                            </li>
                                            <li
                                                className="breadcrumb-item active"
                                                aria-current="page"
                                            >
                                                Update Gallery Image
                                            </li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                    <section className="content mt-5">
                        <div className="row">
                            <div className="col-lg-12 col-12 mx-auto">
                                <div className="box">
                                    <div className="box-body boxpadding pt-4">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label className="labeltext pt-3">
                                                        Title <span className="aesterik">*</span>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        name="title"
                                                        className="form-control"
                                                        placeholder="title"
                                                        value={title}
                                                        onChange={(e) => {
                                                            setTitle(e.target.value);
                                                            clearFieldError("title"); // Clear error
                                                        }}
                                                    />
                                                    {formErrors.title && (
                                                        <div className="invalid-input">
                                                            {formErrors.title}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>

                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label className="labeltext">
                                                        Image <span className="aesterik">*</span>
                                                    </label>
                                                    <input
                                                        type="file"
                                                        name="image"
                                                        className="form-control"
                                                        placeholder="Image"
                                                        accept="image/*"
                                                        onChange={(e) => {
                                                            const selectedFile = e.target.files[0];
                                                            if (selectedFile) {
                                                                if (selectedFile.size > 5 * 1024 * 1024) {
                                                                    showNotificationWithMessage(
                                                                        "File size should be less than 5MB",
                                                                        "error"
                                                                    );
                                                                    e.target.value = null;
                                                                } else {
                                                                    setgalleryImage(selectedFile);
                                                                    clearFieldError("galleryImage");
                                                                }
                                                            }
                                                        }}
                                                    />
                                                    {formErrors.galleryImage && (
                                                        <div className="invalid-input">
                                                            {formErrors.galleryImage}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="col-md-6 mt-3">
                                                <img src={galleryImage} alt="" width={80} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="box-footer mb-4 text-center">
                                    <button
                                        type="submit"
                                        className="btn btn-rounded  btn_dark btn-outline btncourse"
                                        style={{ padding: "6px 22px" }}
                                        onClick={(e) => scrollToError(formErrors, UpdateGalleryImg)}
                                    >
                                        Save
                                    </button>
                                </div>
                            </div>
                        </div>
                    </section>
                </div >
            </div >
        </>
    );
}

export default UpdateGalleryImg
