import React, { useState, useRef, useMemo } from "react";
import { NavLink } from "react-router-dom";
import JoditEditor from "jodit-react";
import { useNavigate } from "react-router-dom";
import { notification } from "antd";
import axios from "axios";
import Cookies from "js-cookie";

import { useGlobalLoader } from "../../../Loader/GlobalLoaderContext";

import useNotification from '../../../useNotification'; // Import the custom hook
import { useEffect } from "react";
import { set } from "react-hook-form";

const AddBlog = () => {

    useEffect(() => {
        const checkToken = async () => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_BACKEND_URL}/admin/protected`,
                    {
                        headers: {
                            Authorization: `Bearer ${Cookies.get("jwtoken")}`,
                        },

                        withCredentials: true, //============new
                    }
                );
                const data = await response.data;
                // console.log(data);
            } catch (error) {
                navigate("/");
                // alert("Unauthorized");
            }
        };

        checkToken();
    }, []);

    const { showLoader, hideLoader } = useGlobalLoader();
    const { showNotificationWithMessage } = useNotification();

    const editor = useRef(null);

    const [blogTitle, setBlogTitle] = useState("");
    // const [shortInfo, setShortInfo] = useState("");
    const [blogAuthor, setBlogAuthor] = useState("");
    const [blogContent, setBlogContent] = useState("");
    const [blogThumbImage, setBlogThumbImage] = useState("")
    const [blogDetailImage, setBlogDetailImage] = useState("")
    const [errors, setErrors] = useState({});

    const navigate = useNavigate();

    const handleSubmit = async (e) => {

        // showLoader(); // Show the global loader

        // e.preventDefault();
        // if (!blogTitle || 
        //     // !shortInfo || 
        //     !blogAuthor || 
        //     !blogContent || 
        //     !blogThumbImage || 
        //     !blogDetailImage) {
        //     setError(true)
        //     return false;
        // }

        // setLoading(true);

        const formErrors = {};

        if (!blogTitle) {
            formErrors.blogTitle = "Blog Title is required";
        }

        if (!blogAuthor) {
            formErrors.blogAuthor = "Blog Author Name is required";
        }

        if (!blogContent) {
            formErrors.blogContent = "Blog Content is required";
        }

        if (!blogThumbImage) {
            formErrors.blogThumbImage = "Thumbnail Image is required";
        }

        if (!blogDetailImage) {
            formErrors.blogDetailImage = "Detail Page Image is required";
        }

        setErrors(formErrors);

        if (Object.keys(formErrors).length > 0) {
            return;
        }


        const formData = new FormData();
        formData.append("blogTitle", blogTitle);
        // formData.append("shortInfo", shortInfo);
        formData.append("blogAuthor", blogAuthor);
        formData.append("blogContent", blogContent);
        formData.append("blogThumbImage", blogThumbImage);
        formData.append("blogDetailImage", blogDetailImage);

        try {
            showLoader();

            const response = await axios.post(
                `${process.env.REACT_APP_BACKEND_URL}/admin/AddBlog`,
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        Authorization: `Bearer ${Cookies.get("jwtoken")}`,
                    },
                    withCredentials: true, // Send cookies with the request
                }
            );
            const data = await response.data;
            // console.log(data);
            if (response.status === 200) {
                // notification.success({
                //     message: "Blog added successfully",
                // });
                // navigate("/ViewBlogList");

                showNotificationWithMessage('Blog added successfully', 'success');
                setTimeout(() => {
                    navigate('/blog');
                }, 1000);
            }

        } catch (error) {
            console.error("Error adding blog:", error);
            // notification.error({
            //     message: "Error adding blog ",
            // });
            showNotificationWithMessage('Error adding blog', 'error');

        }
        finally {
            // setLoading(false);
            hideLoader(); // Hide the global loader
        }


    }


    // useEffect(()=>{
    //     showLoader()
    // },[])

    const editorConfig = {
        responsive: true,
        // buttons: buttons,
        // uploader: {
        //     insertImageAsBase64URI: true,
        // },
    };


    const scrollToError = (errors, handleSubmit) => {
        if (errors) {
            const errorField = Object.keys(errors)[0];
            const field = document.getElementsByName(errorField)[0];
            if (field) {
                field.scrollIntoView({ behavior: "smooth", block: "center" });
            }
            handleSubmit();
        } else {
            handleSubmit();
        }
    }

    return (
        <>
            <div className="content-wrapper">
                <div
                    className="container-full"

                >
                    <div className="content-header">
                        <div className="d-flex align-items-center">
                            <div className="mr-auto">
                                <h3 className="page-title">Add Blog</h3>
                                <div className="d-inline-block align-items-center">
                                    <nav>
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item"><NavLink to="/dashboard">Dashboard</NavLink></li>
                                            <li className="breadcrumb-item" aria-current="page"><NavLink to="/blog">Blog</NavLink></li>
                                            <li className="breadcrumb-item active" aria-current="page">Add Blog</li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>

                        </div>
                    </div>
                    <section className="content mt-5">
                        <div className="row">


                            <div className="col-lg-12 col-12 mx-auto">
                                {/* <form
                                    // onSubmit={handleSubmit}
                                    // onSubmit={e => scrollToError(errors, handleSubmit)}
                                > */}
                                {/* <!-- box1 --> */}

                                <div className="box">

                                    <div className="box-body boxpadding pt-5">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="labeltext">Blog Title <span className="aesterik">*</span></label>
                                                    <input
                                                        type="text"
                                                        name="blogTitle"
                                                        // className="form-control"
                                                        className={`form-control ${errors.blogTitle ? "is-invalid" : ""
                                                            }`}
                                                        placeholder="Blog Title"
                                                        value={blogTitle}
                                                        onChange={(e) => {
                                                            setBlogTitle(e.target.value);
                                                            setErrors({ ...errors, blogTitle: "" });
                                                        }}
                                                    />
                                                    {errors.blogTitle && (
                                                        <span className="invalid-input">
                                                            {errors.blogTitle}
                                                        </span>
                                                    )}
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="labeltext">Blog Author Name <span className="aesterik">*</span></label>
                                                    <input
                                                        type="text"
                                                        name="blogAuthor"
                                                        // className="form-control"
                                                        className={`form-control ${errors.blogAuthor ? "is-invalid" : ""
                                                            }`}
                                                        placeholder="Blog Author"
                                                        value={blogAuthor}
                                                        onChange={(e) => {
                                                            setBlogAuthor(e.target.value);
                                                            setErrors({ ...errors, blogAuthor: "" });
                                                        }}
                                                    />
                                                    {errors.blogAuthor && (
                                                        <span className="invalid-input">
                                                            {errors.blogAuthor}
                                                        </span>
                                                    )}
                                                </div>
                                            </div>


                                            <div className="col-md-6 mt-4">
                                                <div className="form-group">
                                                    <label className="labeltext">Thumbnail Image <span className="aesterik">*</span></label>
                                                    <input
                                                        type="file"
                                                        // name="blogThumbImage"
                                                        // className="form-control"
                                                        className={`form-control ${errors.blogThumbImage ? "is-invalid" : ""
                                                            }`}
                                                        onChange={(e) => {
                                                            // setBlogThumbImage(e.target.files[0]);
                                                            // setErrors({ ...errors, blogThumbImage: "" });
                                                            const selectedblogThumbImage = e.target.files[0];
                                                            if (selectedblogThumbImage) {
                                                                if (selectedblogThumbImage.size > 5 * 1024 * 1024) {
                                                                    showNotificationWithMessage('Image size should be less than 5MB', 'error');
                                                                    e.target.value = null;
                                                                } else {
                                                                    setBlogThumbImage(selectedblogThumbImage);
                                                                    setErrors({ ...errors, blogThumbImage: "" });
                                                                }

                                                            }
                                                        }
                                                        }
                                                    />
                                                    {errors.blogThumbImage && (
                                                        <span className="invalid-input">
                                                            {errors.blogThumbImage}
                                                        </span>
                                                    )}
                                                </div>
                                            </div>

                                            <div className="col-md-6 mt-4">
                                                <div className="form-group">
                                                    <label className="labeltext">Detail Page Image <span className="aesterik">*</span></label>
                                                    <input
                                                        type="file"
                                                        // name="blogThumbImage"
                                                        // className="form-control"
                                                        className={`form-control ${errors.blogDetailImage ? "is-invalid" : ""
                                                            }`}
                                                        onChange={(e) => {
                                                            // setBlogDetailImage(e.target.files[0]);
                                                            // setErrors({ ...errors, blogDetailImage: "" });
                                                            const selectedblogDetailImage = e.target.files[0];
                                                            if (selectedblogDetailImage) {
                                                                if (selectedblogDetailImage.size > 5 * 1024 * 1024) {
                                                                    showNotificationWithMessage('Image size should be less than 5MB', 'error');
                                                                    e.target.value = null;
                                                                } else {
                                                                    setBlogDetailImage(selectedblogDetailImage);
                                                                    setErrors({ ...errors, blogDetailImage: "" });
                                                                }

                                                            }
                                                        }}
                                                    />
                                                    {errors.blogDetailImage && (
                                                        <span className="invalid-input">
                                                            {errors.blogDetailImage}
                                                        </span>
                                                    )}

                                                </div>
                                            </div>

                                            <div className="col-md-12 mt-4 mb-3">
                                                <div className="form-group">
                                                    <label className="labeltext"> Blog Description <span className="aesterik">*</span></label>
                                                    {useMemo(
                                                        () => (
                                                            <JoditEditor
                                                                ref={editor}
                                                                config={editorConfig}
                                                                value={blogContent}
                                                                onChange={(newContent) => {
                                                                    setBlogContent(newContent);
                                                                    setErrors({ ...errors, blogContent: "" });
                                                                }
                                                                }
                                                            />

                                                        ),
                                                        []
                                                    )}
                                                    {errors.blogContent && (
                                                        <span className="invalid-input">{errors.blogContent}</span>
                                                    )}
                                                </div>
                                            </div>



                                            {/* <div className="col-md-12 mt-2">
                                                    <div className="form-group">
                                                        <label>Short Info</label>
                                                        <textarea
                                                            rows="2"
                                                            name="shortInfo"
                                                            className="form-control"
                                                            placeholder="short Info"
                                                            value={shortInfo}
                                                            onChange={(e) => {
                                                                setShortInfo(e.target.value);
                                                            }}
                                                        ></textarea>
                                                      
                                                    </div>
                                                </div> */}
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- box1 --> */}


                                {/* // <!-- box2 -->  */}

                                {/* <div className="box">
                                        <div className="box-body">
                                            <div className="row">
                                                <div className="col-md-12 mt-2">
                                                    <div className="form-group">
                                                        <label> Blog Description</label>
                                                        {useMemo(
                                                            () => (
                                                                <JoditEditor
                                                                    ref={editor}
                                                                    config={editorConfig}
                                                                    value={blogContent}
                                                                    onChange={(newContent) =>
                                                                        setBlogContent(newContent)
                                                                    }
                                                                />
                                                            ),
                                                            []
                                                        )}
                                                      
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}

                                {/* box3 */}
                                {/* 
                                    <div className="box">
                                        <div className="box-body">
                                            <div className="row">
                                                <div className="col-md-6 mt-2">
                                                    <div className="form-group">
                                                        <label>Blog Author Name</label>
                                                        <input
                                                            type="text"
                                                            name="blogAuthor"
                                                            className="form-control"
                                                            placeholder="Blog Author"
                                                            value={blogAuthor}
                                                            onChange={(e) => {
                                                                setBlogAuthor(e.target.value);
                                                            }}
                                                        />
                                                  
                                                    </div>
                                                </div>


                                            </div>
                                        </div>
                                    </div> */}
                                {/* <!-- box4 --> */}


                                {/* <div className="box">
                                        <div className="box-body">
                                            <div className="row">
                                                <div className="col-md-6 mt-2">
                                                    <div className="form-group">
                                                        <label>Blog Thumbnail Image</label>
                                                        <input
                                                            type="file"
                                                        
                                                            className="form-control"
                                                            onChange={(e) => {
                                                                setBlogThumbImage(e.target.files[0]);
                                                            }}
                                                        />
                                                       
                                                    </div>
                                                </div>

                                                <div className="col-md-6 mt-2">
                                                    <div className="form-group">
                                                        <label>Blog Detail Page Image</label>
                                                        <input
                                                            type="file"
                                                          
                                                            className="form-control"
                                                            onChange={(e) => {
                                                                setBlogDetailImage(e.target.files[0]);
                                                            }}
                                                        />
                                                      
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div> */}


                                {/* <!-- action --> */}
                                <div className="box-footer mb-4 text-center">
                                    <button
                                        type="submit"
                                        onClick={e => scrollToError(errors, handleSubmit)}
                                        className="btn btn-rounded  btn_dark btn-outline btncourse"
                                        style={{ padding: '6px 22px' }}
                                    >
                                        {/* <i className="ti-save-alt"></i>  */}
                                        Save
                                    </button>
                                </div>

                                {/* <!-- action --> */}
                                {/* </form> */}
                            </div>

                        </div>
                    </section>
                </div>

                {/* <!-- form --> */}

                {/* 		
      <!-- /.content --> */}
            </div >

            {/* Display the loader while loading */}
            {/* {true && (
                <div className="loader-container justify-content-center">
                    <Spin />
                </div>
            )} */}


        </>
    )
}

export default AddBlog