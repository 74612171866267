import { notification } from 'antd';
import axios from 'axios';
import { NavLink } from "react-router-dom";
import Cookies from 'js-cookie';
import React from 'react'
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useNotification from '../../../useNotification'; // Import the custom hook
import { useEffect } from 'react';


const AddServiceImg = () => {
    useEffect(() => {
        const checkToken = async () => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_BACKEND_URL}/admin/protected`,
                    {
                        headers: {
                            Authorization: `Bearer ${Cookies.get("jwtoken")}`,
                        },

                        withCredentials: true, //============new
                    }
                );
                const data = await response.data;
                // console.log(data);
            } catch (error) {
                navigate("/");
                // alert("Unauthorized");
            }
        };

        checkToken();
    }, []);

    const { showNotificationWithMessage } = useNotification();

    const navigate = useNavigate();
    const [serviceImage, setServiceImage] = useState(null);
    const [short_desc, setShort_desc] = useState('');

    const [errors, setErrors] = useState({
        serviceImage: "",
        short_desc: "",

    });

    const validateForm = () => {
        const newErrors = {
            serviceImage: "",
            short_desc: "",
        };
        let isValid = true;

        if (!serviceImage) {
            newErrors.serviceImage = "Please upload an Image";
            isValid = false;
        }

        if (!short_desc) {
            newErrors.shortInfo = "Please enter a short description";
            isValid = false;
        }


        setErrors(newErrors);

        return isValid;
    };

    const addServiceImg = async () => {

        if (!validateForm()) {
            return;
        }

        // if (!serviceImage) {
        //     showNotificationWithMessage('All fields are required', 'error');
        //     return;
        // }

        const formData = new FormData();
        formData.append("serviceImage", serviceImage);
        formData.append("short_desc", short_desc);

        try {
            const response = await axios.post(
                `${process.env.REACT_APP_BACKEND_URL}/admin/AddServiceImage`,
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        Authorization: `Bearer ${Cookies.get("jwtoken")}`,
                    },
                    withCredentials: true, // Send cookies with the request
                }
            );
            if (response.status === 200) {
                // notification.success({
                //     message: "Service Image added successfully",
                // });
                //   navigate("/ViewServiceImg");

                showNotificationWithMessage('Service Content added successfully', 'success');
                setTimeout(() => {
                    navigate('/service-content');
                }, 1000);
            }

        } catch (error) {
            // notification.error({
            //     message: "Error adding Service Image",
            // });

            showNotificationWithMessage('Error adding Service Image', 'error');

        }
    }

    const scrollToError = (errors, addServiceImg) => {
        if (errors) {
            const errorField = Object.keys(errors)[0];
            const field = document.getElementsByName(errorField)[0];
            if (field) {
                field.scrollIntoView({ behavior: "smooth", block: "center" });
            }
            addServiceImg();
        } else {
            addServiceImg();
        }
    }

    return (
        <>
            <div className="content-wrapper">
                <div className="container-full">
                    <div className="content-header">
                        <div className="d-flex align-items-center">
                            <div className="mr-auto">
                                <h3 className="page-title">Add Service</h3>
                                <div className="d-inline-block align-items-center">
                                    <nav>
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item"><NavLink to="/dashboard">Dashboard</NavLink></li>
                                            <li className="breadcrumb-item" aria-current="page"><NavLink to="/service-content">Service</NavLink></li>
                                            <li className="breadcrumb-item active" aria-current="page"><NavLink to="/dashboard">Add Service</NavLink></li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>

                        </div>
                    </div>
                    <section className="content">
                        <div className="row">

                            {/* <form>/ */}
                            <div className="col-lg-12 col-12 mx-auto">
                                {/* <!-- box1 --> */}

                                <div className="box">

                                    <div className="box-body boxpadding">
                                        <div className="row">

                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label>
                                                        short Description
                                                    </label>
                                                    <textarea
                                                        rows="2"
                                                        name="short_desc"
                                                        // className="form-control"
                                                        className={`form-control ${errors.short_desc ? "is-invalid" : ""
                                                            }`}
                                                        placeholder="short description"
                                                        value={short_desc}
                                                        onChange={(e) => {
                                                            setShort_desc(e.target.value);
                                                            setErrors({ ...errors, short_desc: "" });
                                                        }}
                                                    ></textarea>
                                                    {errors.short_desc && (
                                                        <div className="invalid-input">
                                                            {errors.short_desc}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>

                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label>
                                                        Service Page Image
                                                        <span className="text-danger ml-1">
                                                            {/* (Size - 1920 x 400)* */}
                                                        </span>
                                                    </label>
                                                    <input
                                                        type="file"
                                                        className="form-control"
                                                        name="serviceImage"
                                                        onChange={(e) => {
                                                            setServiceImage(e.target.files[0]);
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- box1 -->
                
                  {/* <!-- action --> */}
                                <div className="box-footer mb-4">
                                    <button
                                        type="submit"
                                        className="btn btn-rounded btn_dark btn-outline btncourse"
                                        // onClick={addServiceImg}
                                        onClick={e => scrollToError(errors, addServiceImg)}
                                        style={{ padding: 10 }}
                                    >
                                        <i className="ti-save-alt"></i> Save
                                    </button>
                                </div>

                                {/* <!-- action --> */}
                            </div>
                            {/* </form> */}
                        </div>
                    </section>

                    {/* <!-- form --> */}

                    {/* 		
		<!-- /.content --> */}
                </div>
            </div>
            {/* <!-- /.content-wrapper --> */}
        </>
    )
}

export default AddServiceImg