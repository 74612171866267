import { notification } from 'antd';
import axios from 'axios';
import { NavLink } from "react-router-dom";
import Cookies from 'js-cookie';
import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useNotification from '../../../useNotification'; // Import the custom hook
import { useGlobalLoader } from "../../../Loader/GlobalLoaderContext";
import { set } from 'react-hook-form';


const UpdateServiceImg = () => {
    useEffect(() => {
        const checkToken = async () => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_BACKEND_URL}/admin/protected`,
                    {
                        headers: {
                            Authorization: `Bearer ${Cookies.get("jwtoken")}`,
                        },

                        withCredentials: true, //============new
                    }
                );
                const data = await response.data;
                console.log(data);
            } catch (error) {
                navigate("/");
                // alert("Unauthorized");
            }
        };

        checkToken();
    }, []);

    const { showLoader, hideLoader } = useGlobalLoader();

    const { showNotificationWithMessage } = useNotification();

    const [serviceImage, setServiceImage] = useState(null);
    const [short_desc, setShort_desc] = useState("");

    const params = useParams();
    const navigate = useNavigate();

    const [errors, setErrors] = useState({});

    const getServiceImg = async () => {
        try {
            showLoader();

            const response = await axios.get(
                `${process.env.REACT_APP_BACKEND_URL}/admin/GetEditServiceImage/${params.id}`,
                {
                    headers: {
                        Authorization: `Bearer ${Cookies.get("jwtoken")}`,
                    },
                    withCredentials: true, // Send cookies with the request
                }
            );
            const result = response.data;
            setServiceImage(result.servicePageURL);
            setShort_desc(result.short_desc);
        } catch (error) {
            // notification.warning({
            //     message: "something went wrong",
            // });
            showNotificationWithMessage('Something went wrong', 'error');

        }
        finally {
            hideLoader();
        }
    };

    useEffect(() => {
        getServiceImg();
    }, []);

    const validateForm = () => {
        const newErrors = {};

        if (!short_desc.trim()) {
            newErrors.short_desc = "Short Description is required";
        }

        if (!serviceImage) {
            newErrors.serviceImage = "Service Page Image is required";
        }

        setErrors(newErrors);

        return Object.keys(newErrors).length === 0; // Form is valid if there are no errors
    };

    //update service image
    const updateServiceImg = async (e) => {
        e.preventDefault();

        if (!validateForm()) {
            return;
        }

        const formData = new FormData();
        formData.append("serviceImage", serviceImage);
        formData.append("short_desc", short_desc);
        try {
            showLoader();

            const response = await axios.put(
                `${process.env.REACT_APP_BACKEND_URL}/admin/UpdateServiceImage/${params.id}`,
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        Authorization: `Bearer ${Cookies.get("jwtoken")}`,
                    },
                    withCredentials: true, // Send cookies with the request
                }
            );
            // notification.success({
            //     message: "Service Image Updated Successfully",
            // });
            // navigate("/ViewServiceImg");
            showNotificationWithMessage('Service content Updated Successfully', 'success');
            setTimeout(() => {
                navigate('/service-content');
            }, 1000);

        } catch (error) {
            // notification.warning({
            //     message: "something went wrong",
            // });
            showNotificationWithMessage('Something went wrong', 'error');

        }
        finally {
            hideLoader();
        }
    }


    return (
        <>
            <div className="content-wrapper">
                <div className="container-full">
                    <div className="content-header">
                        <div className="d-flex align-items-center">
                            <div className="mr-auto">
                                <h3 className="page-title">Update Service</h3>
                                <div className="d-inline-block align-items-center">
                                    <nav>
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item"><NavLink to="/dashboard">Dashboard</NavLink></li>
                                            <li className="breadcrumb-item" aria-current="page"><NavLink to="/service-content">Service</NavLink></li>
                                            <li className="breadcrumb-item active" aria-current="page">Update Service</li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>

                        </div>
                    </div>
                    <section className="content mt-5">
                        <div className="row">

                            {/* <form>/ */}
                            <div className="col-lg-12 col-12 mx-auto">
                                {/* <!-- box1 --> */}

                                <div className="box">

                                    <div className="box-body boxpadding pt-4">
                                        <div className="row pt-4">

                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label className='labeltext'>
                                                        short Description <span className="aesterik">*</span>
                                                    </label>
                                                    <textarea
                                                        rows="2"
                                                        name="short_desc"
                                                        // className="form-control"
                                                        className={`form-control ${errors.short_desc ? "is-invalid" : ""}`}
                                                        placeholder="short description"
                                                        value={short_desc}
                                                        onChange={(e) => {
                                                            setShort_desc(e.target.value);
                                                            setErrors({ ...errors, short_desc: "" });
                                                        }}
                                                    ></textarea>
                                                    {errors.short_desc && (
                                                        <div className="invalid-input">{errors.short_desc}</div>
                                                    )}
                                                </div>
                                            </div>


                                            <div className="col-md-6 mt-2">
                                                <div className="form-group">
                                                    <label className='labeltext'>
                                                        Service Page Image <span className="aesterik">*</span>
                                                        <span className="text-danger ml-1">
                                                            {/* (Size - 1920 x 400)* */}
                                                        </span>
                                                    </label>
                                                    <input
                                                        type="file"
                                                        className="form-control"
                                                        // className={`form-control ${errors.serviceImage ? "is-invalid" : ""}`}
                                                        name="serviceImage"
                                                        onChange={(e) => {
                                                            // setServiceImage(e.target.files[0]);
                                                            const selectedServiceimg = e.target.files[0];
                                                            if (selectedServiceimg) {
                                                                if (selectedServiceimg.size > 5 * 1024 * 1024) {
                                                                    showNotificationWithMessage("File size should be less than 5MB", "error");
                                                                    e.target.value = null;
                                                                }
                                                                else {
                                                                    setServiceImage(selectedServiceimg);
                                                                }
                                                            }

                                                        }}
                                                    />
                                                    {/* {errors.serviceImage && (
                                                        <div className="invalid-input">{errors.serviceImage}</div>
                                                    )} */}
                                                </div>

                                            </div>
                                            <div className="col-md-6 mt-3">
                                                <img src={serviceImage} alt="" width={80} />
                                            </div>


                                        </div>
                                    </div>
                                </div>
                                {/* <!-- box1 -->
        
          {/* <!-- action --> */}
                                <div className="box-footer mb-4 text-center">
                                    <button
                                        type="submit"
                                        className="btn btn-rounded btn_dark btn-outline btncourse"
                                        onClick={updateServiceImg}
                                        style={{ padding: '6px 22px' }}
                                    >
                                        {/* <i className="ti-save-alt"></i> */}
                                        Update
                                    </button>
                                </div>

                                {/* <!-- action --> */}
                            </div>
                            {/* </form> */}
                        </div>
                    </section>

                    {/* <!-- form --> */}

                    {/* 		
<!-- /.content --> */}
                </div>
            </div>
            {/* <!-- /.content-wrapper --> */}
        </>
    )
}

export default UpdateServiceImg