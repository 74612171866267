import { Route, Routes, useNavigate } from "react-router-dom";
import React, { useEffect } from "react";

import HomeWeb from "./pages/website/HomeWeb";
import Services from "./pages/website/Services";
import Servicedetail from "./pages/website/Servicedetail";
import Courses from "./pages/website/Courses";
// import Course from "./pages/website/Course";
import Coursedetail from "./pages/website/Coursedetail";
import About from "./pages/website/About";
import Blog from "./pages/website/Blog";
import Blogdetail from "./pages/website/Blogdetail";
import Contact from "./pages/website/Contact";
import Login from "./pages/website/Login";
import EnquiryForm from "./components/website/Coursedetail/EnquiryForm";
import Signup from "./pages/website/Signup";
import Main from "./components/website/Main";


// student dashboard

import MyMedia from "./pages/website/MyMedia";
import MyCourses from "./components/website/StudentDashboard/MyCourses";
import Home from "./components/website/StudentDashboard/Dashboard/Home";

import MyProfile from "./pages/website/MyProfile";



// ---------------aos animation

import Aos from "aos";
import "aos/dist/aos.css";

//-------------- admin panel--------------------

import AdminMain from "./components/admin/AdminMain";
import DashboardHome from "./components/admin/Dashboard/DashboardHome";
import AddCourse from "./components/admin/Courses/AddCourse";
import CourseList from "./components/admin/Courses/CourseList";
import UpdateCourse from "./components/admin/Courses/UpdateCourse";
import Enquiries from "./components/admin/Enquiry/Enquiries";
import Students from "./components/admin/students/Students";
import AddMedia from "./components/admin/Media/AddMediaImage";
import MediaDocs from "./components/admin/Media/MediaDocs";
import AddMediaDocs from "./components/admin/Media/AddMediaDocs";
import MediaVideo from "./components/admin/Media/MediaVideo";
import AddMediaVideo from "./components/admin/Media/AddMediaVideo";
import MediaImage from "./components/admin/Media/MediaImage";
import AddMediaImage from "./components/admin/Media/AddMediaImage";
import UpdateMedia from "./components/admin/Media/UpdateMedia";
import AddBanner from "./components/admin/UiManagement/AddBanner";
import ViewBanner from "./components/admin/UiManagement/ViewBanner";

// login form
import LoginMain from "./components/adminLogin/LoginMain";
import AdminAuthLogin from "./components/adminLogin/AdminAuthLogin";
import PrivateComponent from "./components/admin/PrivateComponents";
import HomePage from "./components/website/Home/HomePage";
import Servicedemo from "./components/website/Service/Servicedemo";
import EditStudent from "./pages/admin/editStudent";
import UpdateBanner from "./components/admin/UiManagement/UpdateBanner";
import AddBlog from "./components/admin/Blogs/AddBlog";
import ViewBlogList from "./components/admin/Blogs/ViewBlogList";
import UpdateBlog from "./components/admin/Blogs/UpdateBlog";

import Mediademo from "./components/Mymedia/Mediademo";
import ImageCroper from "./components/ImageCroper/ImageCroper";
import AddServiceImg from "./components/admin/UiManagement/AddServiceImg";
import ViewServiceImg from "./components/admin/UiManagement/ViewServiceImg";
import UpdateServiceImg from "./components/admin/UiManagement/UpdateServiceImg";
import ForgetPassword from "./components/website/passwordReset/ForgetPassword";
import PasswordReset from "./components/website/passwordReset/PasswordReset";
import ViewServices from "./components/admin/Services/ViewServices";
import AddService from "./components/admin/Services/AddServices";

import AddTestimonial from "./components/admin/Testimonial/AddTestimonial";
import ViewTestimonial from "./components/admin/Testimonial/ViewTestimonial";
import UpdateTestimonial from "./components/admin/Testimonial/UpdateTestimonial";
import ContactEnquires from "./components/admin/Enquiry/ContactEnquires";
import EditServices from "./components/admin/Services/EditService";
import ServeyEnquires from "./components/admin/Enquiry/serveyEnquiry";
import AdminProfile from "./components/admin/AdminProfile/AdminProfile";
import Filter from "./components/admin/Filter/Filter";
import AddGalleryImg from "./components/admin/UiManagement/Gallery/AddGalleryImg";
import ViewGalleryImg from "./components/admin/UiManagement/Gallery/ViewGalleryImg";
import UpdateGalleryImg from "./components/admin/UiManagement/Gallery/UpdateGalleryImg";
import PrivacyPolicy from "./components/admin/UiManagement/PrivacyPolicy/PrivacyPolicy";
import AddPrivacyPolicy from "./components/admin/UiManagement/PrivacyPolicy/AddPrivacyPolicy";
import UpdatePrivacyPolicy from "./components/admin/UiManagement/PrivacyPolicy/UpdatePrivacyPolicy";
import BookAssessmentEnquiry from "./components/admin/Enquiry/BookAssessmentEnquiry";
import PopUpModal from "./components/admin/UiManagement/PopUP/PopUpModal";
import UpdatePopUpModal from "./components/admin/UiManagement/PopUP/UpdatePopUpModal";
import AddPopUpModal from "./components/admin/UiManagement/PopUP/AddPopUpModal";




function App() {
  useEffect(() => {
    Aos.init({
      duration: "1000",
    });
    // console.log(process.env.REACT_APP_BACKEND_URL)
  }, []);
  return (
    <div className="App">
      {/* website */}
      <Routes>

        {/* website end*/}

        {/* adminroute */}

        {/* <Route element={<PrivateComponent />}> */}
        <Route path="/" exact element={<LoginMain />}>
          <Route path="" element={<AdminAuthLogin />} />
        </Route>
        <Route path="" exact element={<AdminMain />}>
          <Route path="/dashboard" element={<DashboardHome />} />

          <Route path="/add-course" element={<AddCourse />} />
          <Route path="/courselist" element={<CourseList />} />
          <Route path="/updatecourse/:id" element={<UpdateCourse />} />
          <Route path="course-enquiry" element={<Enquiries />} />
          <Route path="contact-enquiry" element={<ContactEnquires />} />
          <Route path="servey-enquiry" element={<ServeyEnquires />} />
          <Route path="students" element={<Students />} />
          <Route path="/admin/editstudent/:id" element={<EditStudent />} />
          {/* <Route path="/AddMedia/:mediaType" element={<AddMedia />} /> */}
          <Route path="documents" element={<MediaDocs />} />
          <Route path="add-document" element={<AddMediaDocs />} />
          <Route path="videos" element={<MediaVideo />} />
          <Route path="add-video" element={<AddMediaVideo />} />
          <Route path="images" element={<MediaImage />} />
          <Route path="add-image" element={<AddMediaImage />} />
          {/* <Route path="update-media" element={<UpdateMedia />} /> */}
          <Route path="/update-media/:id" element={<UpdateMedia />} />

          <Route path="add-banner" element={<AddBanner />} />
          <Route path="banner" element={<ViewBanner />} />
          <Route path="/update-banner/:id" element={<UpdateBanner />} />

          <Route path="add-blog" element={<AddBlog />} />
          <Route path="blog" element={<ViewBlogList />} />
          <Route path="/update-blog/:id" element={<UpdateBlog />} />
          {/* <Route path="/updateblog/:id" element={<UpdateBlog />} /> */}

          <Route path="add-service" element={<AddService />} />
          <Route path="service" element={<ViewServices />} />
          <Route path="/update-service/:id" element={<EditServices />} />


          {/* <Route path="AddBanner" element={<AddBanner />} />
          <Route path="ViewBanner" element={<ViewBanner />} />
          <Route path="/UpdateBanner/:id" element={<UpdateBanner />} /> */}

          <Route path="service-content" element={<ViewServiceImg />} />
          <Route path="add-service-content" element={<AddServiceImg />} />
          <Route path="/update-service-content/:id" element={<UpdateServiceImg />} />

          <Route path="add-testimonial" element={<AddTestimonial />} />
          <Route path="testimonial" element={<ViewTestimonial />} />
          <Route path="/update-testimonial/:id" element={<UpdateTestimonial />} />

          <Route path="/gallery" element={<ViewGalleryImg />} />
          <Route path="/add-gallery" element={<AddGalleryImg />} />
          <Route path="/update-gallery/:id" element={<UpdateGalleryImg />} />

          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/add-privacy-policy" element={<AddPrivacyPolicy />} />
          <Route path="/update-privacy-policy/:id" element={<UpdatePrivacyPolicy />} />

          <Route path="/Book-Assessment-Enquiry" element={<BookAssessmentEnquiry />} />

          <Route path="/popUp-modal" element={<PopUpModal />} />
          <Route path="/add-popUp-modal" element={<AddPopUpModal />} />
          <Route path="/update-popUp-modal/:id" element={<UpdatePopUpModal />} />




          {/* admin profile */}
          <Route path="admin-profile" element={<AdminProfile />} />
          <Route path="filter" element={<Filter />} />

        </Route>


        {/* adminroute end */}
        {/* adminroute end */}



      </Routes>
    </div >
  );
}

export default App;
