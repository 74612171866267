import { Table, Button, Modal, Select, Form, Input, DatePicker } from "antd";
import { useState } from "react";
import styles from "./styles.module.css";
import axios from "axios";
import { useGlobalLoader } from "../../Loader/GlobalLoaderContext";
// import useNotification from 'antd/es/notification/useNotification';
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import useNotification from "../../useNotification";
import { useFormik } from "formik";
import * as Yup from "yup";
import { DollarOutlined, UserSwitchOutlined } from "@ant-design/icons";
import dayjs from 'dayjs';
import moment from 'moment';

export default function SubscriptionDetail(props) {
  const { Option } = Select;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentSubscription, setCurrentSubscription] = useState(null);
  const navigate = useNavigate();
  const { showLoader, hideLoader } = useGlobalLoader();
  const { showNotificationWithMessage } = useNotification();
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [amountPaid, setAmountPaid] = useState();
  const showEditModal = (subscription) => {
    setCurrentSubscription(subscription);
    setIsModalVisible(true);
  };

  const [selectedBatch, setSelectedBatch] = useState(null);

  const validationSchema = Yup.object({
    amountPaid: Yup.number()
      .min(0, "Amount should be greater than 0")
      .max(
        currentSubscription
          ? currentSubscription.balance
          : Number.POSITIVE_INFINITY,
        "Amount paid should not exceed the balance"
      )
      .required("Amount paid is required"),
  });

  const handleSaveSubscription = async () => { };

  const handleStatusUpdate = async (record, value) => {
    // console.log(record, value)
    if (!value) return;
    try {
      showLoader();
      const res = await axios.put(
        `${process.env.REACT_APP_BACKEND_URL}/admin/updateStudentSubscription/${record.key}`,
        { ...props.subscription[record.index], status: value }, // use the selected status here
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("jwtoken")}`,
          },
          withCredentials: true,
        }
      );
      if (res.status === 200) {
        setIsModalVisible(false);
        showNotificationWithMessage("Date updated successfully", "success");
        await props.getStudentSubscription();
      }
    } catch (error) {
      console.log(error)
    }

  }

  const handleBatchUpdate = async (batch, value) => {
    // console.log("#####******", batch, value)
    if (!value) return;
    try {
      showLoader();
      const res = await axios.put(
        `${process.env.REACT_APP_BACKEND_URL}/admin/updateStudentSubscription/${batch.key}`,
        { ...props.subscription[batch.index], batch: value }, // use the selected status here
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("jwtoken")}`,
          },
          withCredentials: true,
        }
      );
      if (res.status === 200) {
        showNotificationWithMessage("Batch updated successfully", "success");
        await props.getStudentSubscription();
      }

    } catch (error) {
      console.log(error)
    }
    finally {
      hideLoader();
    }
  }

  const handleUpdate = async () => {
    // console.log(currentSubscription.key)
    // if (!selectedStatus) return; // exit if no status is selected

    try {
      showLoader();
      let data = {
        userId: currentSubscription.userId,
        courseId: currentSubscription.courseId,
        amountPaid: formik.values.amountPaid,
        description: formik.values.description
      };
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/admin/offline-payment-handler`,
        { ...data }, // use the selected status here
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("jwtoken")}`,
          },
          withCredentials: true,
        }
      );
      // console.log(res);
      if (res.status === 200) {
        setIsModalVisible(false);
        showNotificationWithMessage("Student subscription updated successfully", "success");
        await props.getStudentSubscription();
        formik.setFieldValue('amountPaid', "")
        formik.setFieldValue('description', "")
      }
    } catch (error) {
      console.error(error);
      showNotificationWithMessage("Failed to update student subscription", "error");
    } finally {
      hideLoader();
    }
  };
  const formik = useFormik({
    initialValues: {
      amountPaid: "",
      description: ''
    },
    validationSchema: validationSchema,
    onSubmit: handleUpdate,
  });
  const handleOk = () => {
    setIsModalVisible(false);
    // Update the subscription status here
    // You can access the currentSubscription and make any necessary API calls or state updates
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const handleDateChange = async (date, record, type) => {
    if (date) {
      const updatedDate = date.format('YYYY-MM-DD');

      if (type === "startDate") {
        try {
          showLoader();
          const res = await axios.put(
            `${process.env.REACT_APP_BACKEND_URL}/admin/updateStudentSubscription/${record.key}`,
            { ...props.subscription[record.index], startDate: updatedDate }, // use the selected status here
            {
              headers: {
                Authorization: `Bearer ${Cookies.get("jwtoken")}`,
              },
              withCredentials: true,
            }
          );
          if (res.status === 200) {
            setIsModalVisible(false);
            showNotificationWithMessage("Status updated successfully", "success");
            await props.getStudentSubscription();
          }
        } catch (error) {
          console.log(error)
        }
      } else if (type === "endDate") {
        try {
          showLoader();
          const res = await axios.put(
            `${process.env.REACT_APP_BACKEND_URL}/admin/updateStudentSubscription/${record.key}`,
            { ...props.subscription[record.index], endDate: updatedDate }, // use the selected status here
            {
              headers: {
                Authorization: `Bearer ${Cookies.get("jwtoken")}`,
              },
              withCredentials: true,
            }
          );
          if (res.status === 200) {
            setIsModalVisible(false);
            showNotificationWithMessage("Status updated successfully", "success");
            await props.getStudentSubscription();
          }
        } catch (error) {
          console.log(error)
        }
      }
    }


  };

  const columns = [
    {
      title: "Course Name",
      dataIndex: "courseName",
      key: "courseName",
      align: "center",
    },
    // {
    //   title: "Batch",
    //   dataIndex: "batch",
    //   key: "batch",
    //   align: "center",
    //   render: (_, batch) => {
    //     return (
    //       <Select
    //         defaultValue={batch.batch}
    //         onChange={(value) => {
    //           setSelectedBatch(value);
    //         }}
    //       >
    //         {console.log("%%%%%%%%",batch.courseBatches)}

    //         {batch.courseBatches.map((batch) => (
    //           <Select.Option key={batch.batchId} value={batch.batchName}>
    //             {batch.batchName}
    //           </Select.Option>
    //         ))}

    //       </Select>

    //     )
    //   }
    // },
    {
      title: "Batch",
      dataIndex: "batch",
      key: "batch",
      align: "center",
      // width:"5rem",
      render: (_, batch) => {
        // console.log("batchbatchbatchbatch", batch)
        return (
          <Select
            className={styles.batchWidth}
            value={batch.batch?.batchName}
            onChange={(value) => {
              setSelectedBatch(value);
              handleBatchUpdate(batch, value);
            }}
          >
            {/* {console.log("batch.courseBatches////", batch.courseBatches)} */}
            {batch.courseBatches.map((batch) => (
              <Select.Option key={batch.batchIDs} value={batch._id}>
                {batch.batchName}
              </Select.Option>
            ))}
          </Select>
        );
      },
    },
    {
      title: "Course Price",
      dataIndex: "price",
      key: "price",
      align: "center",
    },
    {
      title: "Start Date",
      dataIndex: "startDate",
      key: "startDate",
      align: "center",
      render: (_, record) => (
        <DatePicker onChange={e => handleDateChange(e, record, "startDate")} defaultValue={dayjs(record.startDate)} format={'YYYY/MM/DD'} />
      ),
    },
    {
      title: "End Date",
      dataIndex: "endDate",
      key: "endDate",
      align: "center",
      render: (_, record) => (
        <DatePicker onChange={e => handleDateChange(e, record, "endDate")} defaultValue={dayjs(record.endDate)} format={'YYYY/MM/DD'} />
      ),
    },
    {
      title: "Status",
      // dataIndex: 'status',
      key: "status",
      render: (_, record) => {
        // return <p className={record.status === 'active' ? styles.active:record.status === 'paused'?styles.paused:styles.inactive}>{record.status}</p>
        return (
          <Select
            defaultValue={record.status}
            onChange={(value) => {
              setSelectedStatus(value);
              handleStatusUpdate(record, value);
            }}
          >
            <Option value="active">Active</Option>
            <Option value="inactive">Inactive</Option>
          </Select>
        );
      },
      align: "center",
    },
    {
      title: "Balance",
      dataIndex: "balance",
      key: "balance",
      render: (_, record) => {
        // console.log(record.balance)
        if (record.balance <= 0) {
          return <div style={{ display: 'flex', width: 'fit-content', margin: 'auto' }} className={styles.active}>Fully paid</div>
        }
        else {
          return <div style={{ textAlign: 'center' }}>{record.balance}</div>
        }
      },
      align: "center",
    },
    // {
    //     title: 'Payment History',
    //     key: 'paymentHistory',
    //     render: (_, record) => (
    //         record.paymentHistory.map(payment => (
    //             <div key={payment._id}>
    //                 Date: {new Date(payment.date).toLocaleDateString()} - Amount: {payment.amount} - Status: {payment.status}
    //             </div>
    //         ))
    //     )
    // },
    {
      title: "Add New Payment",
      key: "action",
      render: (_, record) => {
        if (record.balance > 0) {
          return (
            <Button className="viewpayment" onClick={() => showEditModal(record)}>
              Add new payment
            </Button>
          );
        } else {
          return <></>;
        }
      },
      align: "center",
    },
  ];

  const dataSource = props.subscription.map((subscription, index) => ({
    key: subscription._id,
    courseName: subscription?.course?.Name ? subscription?.course?.Name : "Course Deleted",
    batch: subscription?.batch,
    courseBatches: subscription?.course.batchIDs,
    // batch: subscription?.course.batchIDs,
    price: subscription.totalPrice,
    startDate: new Date(subscription.startDate),
    endDate: new Date(subscription.endDate),
    status: subscription.status,
    paymentHistory: subscription.paymentHistory,
    balance: subscription.remainingAmount,
    userId: subscription.userId,
    courseId: subscription?.course?._id,
    index
  }));

  return (
    <div className={styles.container}>
      <Table dataSource={dataSource} style={{ width: '100%' }} columns={columns} />
      {currentSubscription && (
        <Modal
          // title={`Add new payment for ${currentSubscription.courseName} for user ${props.studentInfo.name}`}
          title={
            <div className="newpayment">
              Add New Payment
              {/* <p className="text-primary">{currentSubscription.courseName}</p> <p>user {props.studentInfo.name}</p> */}
            </div>
          }
          open={isModalVisible}
          onOk={(e) => formik.handleSubmit()}
          onCancel={handleCancel}
          className="labeltext cancelbtn"
        >

          <hr></hr>
          <div className={styles.formItem}>
            <Form.Item
              validateStatus={
                formik.touched.amountPaid && formik.errors.amountPaid
                  ? "error"
                  : ""
              }
              help={formik.touched.amountPaid ? formik.errors.amountPaid : ""}
            >
              {/* <p>Balance Amount {currentSubscription.balance}</p> */}
              <div className="row mt-2 mb-2">
                <div className="col-lg-6"><p className="labeltext">Student :&nbsp; <span className="newpaymenttext">{props.studentInfo.name}</span></p></div>
                <div className="col-lg-6"> <p className="labeltext">Course : <span className="newpaymenttext">{currentSubscription.courseName}</span></p></div>
                <div className="col-lg-6"><p className="labeltext">Balanced Amount : <span className="newpaymenttext">{currentSubscription.balance}</span></p></div>
              </div>
              {/* <div className="d-flex flex-row">
             
             
              <div className="text-center"> <p>Student <span>{currentSubscription.courseName}</span></p></div>
              <div className="text-center"> <p>Course<span>{props.studentInfo.name}</span></p></div>
              <div className="text-center"> <p>Course<span>{currentSubscription.balance}</span></p></div>
             
              </div> */}
              <Input
                placeholder="Enter amount paid by student"
                type="number"
                style={{ width: "100%" }}
                prefix={<DollarOutlined />}
                className={styles.input}
                value={formik.values.amountPaid}
                onChange={formik.handleChange("amountPaid")}
              />
            </Form.Item>
            <Form.Item
              validateStatus={
                formik.touched.description && formik.errors.description
                  ? "error"
                  : ""
              }
              help={formik.touched.description ? formik.errors.description : ""}
            >
              <Input
                placeholder="Add any description or note"
                // type="number"
                style={{ width: "100%" }}
                // prefix={<DollarOutlined />}
                className={styles.input}
                value={formik.values.description}
                onChange={formik.handleChange("description")}
              />
            </Form.Item>
          </div>
        </Modal>
      )}
    </div>
  );
}
