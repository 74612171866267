import React, { useEffect, useState, useRef, useMemo } from "react";
import { useParams, useNavigate } from "react-router-dom";
import JoditEditor from "jodit-react";
import axios from "axios";
import { NavLink } from "react-router-dom";
import Cookies from "js-cookie";
import { Switch, notification } from "antd";
import { ErrorMessage, Formik } from "formik";
import ImageCroper from "../../ImageCroper/ImageCroper";

import useNotification from '../../../useNotification'; // Import the custom hook
import { useGlobalLoader } from "../../../Loader/GlobalLoaderContext";

import { Button, Select } from "antd";
import { Space } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import AddCategory from "../addCategory/AddCategoryModal";
import AddBatches from "../addCategory/AddBatchesModal";



const UpdateCourse = () => {

  useEffect(() => {
    const checkToken = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/admin/protected`,
          {
            headers: {
              Authorization: `Bearer ${Cookies.get("jwtoken")}`,
            },

            withCredentials: true, //============new
          }
        );
        const data = await response.data;
        // console.log(data);
      } catch (error) {
        navigate("/");
        // alert("Unauthorized");
      }
    };

    checkToken();
  }, []);

  const { showLoader, hideLoader } = useGlobalLoader();
  const { showNotificationWithMessage } = useNotification();

  const [result, setResult] = useState();
  const [title, setTitle] = useState("");
  const [course, setCourse] = useState("");
  const [category, setCategory] = useState("");
  const [short_desc, setShortDesc] = useState("");
  const [duration, setDuration] = useState("");
  const [batches, setBatches] = useState("");
  const [price, setPrice] = useState("");
  const [discount_price, setDiscountPrice] = useState("");
  const [tag, setTag] = useState("");
  // const [date, setDate] = useState('');
  const [meta_title, setMetaTitle] = useState("");
  const [meta_keyword, setMetaKeyword] = useState("");
  const [meta_desc, setMetaDesc] = useState("");
  const [image, setImage] = useState("");
  const [imageURL, setImageURL] = useState("");
  const [posterURL, setPosterImageURL] = useState("");
  const [posterImage, setPosterImage] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [cropingImage, setCropingImage] = useState(false);
  const [aspectRatio, setAspectRatio] = useState(1.9);
  const [cropPosterImage, setPosterImageToCrop] = useState(false);
  const [sub_category, setSub_category] = useState("");
  const [suppport, setSupport] = useState("");
  const [display_name, setDisplayName] = useState("");
  const editor = useRef(null);
  const [content, setContent] = useState("");

  const navigate = useNavigate();
  const params = useParams();

  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedSubCategory, setSelectedSubCategory] = useState(null);
  const [dropdownVisible, setDropdownVisible] = useState(false);

  const [validationEnabled, setValidationEnabled] = useState(false);

  const [showBatchModal, setShowBatchModal] = useState(false);

  const [batchName, setBatchName] = useState([]);
  const [batchdropdownVisible, setBatchDropdownVisible] = useState(false);
  const [selectedBatchName, setSelectedBatchName] = useState(null);
  const [courseId, setCourseId] = useState(null);

  const [batchIDs, setBatchIDs] = useState([]);


  const scrollToError = (errors, handleSubmit) => {
    if (errors) {
      const errorField = Object.keys(errors)[0];
      const field = document.getElementsByName(errorField)[0];
      if (field) {
        field.scrollIntoView({ behavior: "smooth", block: "center" });
      }
      handleSubmit();
    } else {
      handleSubmit();
    }
  }

  const getCourseDetails = async () => {
    try {
      showLoader();
      // console.warn(params);
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/admin/GetEditCourse/${params.id}`,
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("jwtoken")}`,
          },
          withCredentials: true, // Send cookies with the request
        }
      );

      const result = response.data;
      // console.log(result)
      setResult(result)
      setTitle(result.title);
      setCourse(result.course);
      setCategory(result.category);
      setSelectedCategory(result.category);
      setShortDesc(result.short_desc);
      setImageURL(result.image);
      setPosterImageURL(result.posterImage);
      setDuration(result.duration);
      setBatches(result.batches);
      setPrice(result.price);
      setDiscountPrice(result.discount_price);
      setTag(result.tag);
      setMetaTitle(result.meta_title);
      setMetaKeyword(result.meta_keyword);
      setMetaDesc(result.meta_desc);
      setImage(result.image);
      setPosterImage(result.posterImage);
      setContent(result.course_content);

      setSupport(result.support);

      setDisplayName(result.display_name);
    } catch (error) {
      // Handle error
      // notification.warning({
      //   message: "something went wrong",
      // });
      showNotificationWithMessage('Something went wrong', 'error');

    }
    finally {
      hideLoader(); // Hide the global loader
    }
  };

  const imageHandler = (image, posterImage) => {
    setCropingImage(image);
    setPosterImageToCrop(posterImage);
    setShowModal(true);
  };

  const updateCourse = async () => {
    // console.log(image);
    const formData = new FormData();

    formData.append("title", title.toLowerCase());
    formData.append("course", course);
    formData.append("category", category);
    formData.append("short_desc", short_desc);
    formData.append("duration", duration);
    formData.append("batches", batches);
    formData.append("price", price);
    formData.append("discount_price", discount_price);
    formData.append("tag", tag);
    formData.append("meta_title", meta_title);
    formData.append("meta_keyword", meta_keyword);
    formData.append("meta_desc", meta_desc);
    formData.append("course_content", content);
    formData.append("image", image);
    formData.append("posterImage", posterImage);

    formData.append("support", suppport);
    formData.append("display_name", display_name);

    try {
      showLoader();
      // console.warn(name, price, category, company);
      const response = await axios.put(
        `${process.env.REACT_APP_BACKEND_URL}/admin/UpdateCourse/${params.id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${Cookies.get("jwtoken")}`,
          },
          withCredentials: true, // Send cookies with the request
        }
      );

      console.warn(response.data);

      showNotificationWithMessage('Course Updated successfully', 'success');
      setTimeout(() => {
        navigate('/CourseList');
      }, 1000);
      // notification.success({
      //   message: "Course Updated successfully",
      // });
      // navigate("/CourseList");
    } catch (error) {
      // Handle error
      // notification.warning({
      //   message: "something went wrong",
      // });
      showNotificationWithMessage('Something went wrong', 'error');

    }
    finally {
      hideLoader(); // Hide the global loader
    }
  };

  const editorConfig = useMemo(() => {
    return {
      readonly: false,
      placeholder: "Start typings...",
      responsive: true,
    };
  }, []);

  useEffect(() => {
    if (image?.size) {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(image);
      fileReader.onloadend = () => {
        setImageURL(fileReader.result);
      };
    }
  }, [image])
  useEffect(() => {
    if (posterImage?.size) {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(posterImage);
      fileReader.onloadend = () => {
        setPosterImageURL(fileReader.result);
      };
    }
  }, [posterImage])

  useEffect(() => {
    getCourseDetails();
  }, []);


  const subCategoriesOptions = useMemo(() => {
    const category = categories.find((cat) => cat.value === selectedCategory);
    // console.log("??????", category);
    return category
      ? category.subcategories.map((sub) => ({ label: sub, value: sub }))
      : [];
  }, [selectedCategory, categories]);


  const getCategories = async () => {
    try {
      showLoader();
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/admin/categories`,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${Cookies.get("jwtoken")}`,
          },
          withCredentials: true, // Send cookies with the request
        }
      );
      setCategories(
        response.data.data.map((cate) => {
          return {
            label: cate.category,
            value: cate._id,
            subcategories: cate.subcategories,
          };
        })
      );
      // console.log("respocn", response);
    } catch (error) {
      console.log(error);
    }
    finally {
      hideLoader();
    }
  };

  useEffect(() => {
    getCategories();
  }, []);

  const EditDeleteOptions = ({ option, category }) => (
    <Space className="space">
      <div className="optioncategory">
        {/* <span className="optioncategory">{option}</span> */}
      </div>

      <Button
        type="button"
        onClick={(e) => {
          e.stopPropagation();
          // console.log(category)
          handleEditCate(option.value);
          setDropdownVisible(false)
        }}
      >
        <i className="fa fa-pencil-square-o text-info"></i>
      </Button>

      <Button
        type="button"
        onClick={(e) => {
          e.stopPropagation();
          // console.log("optionoption", option)
          // console.log(option, category)
          handleDeleteCate(option.value);
        }}
      >
        <i className="fa fa-trash-o text-danger"></i>
      </Button>
    </Space>
  );

  const handleDeleteCate = async (value) => {
    try {
      setDropdownVisible(false)
      // console.log("value", value);
      const response = await axios.delete(
        `${process.env.REACT_APP_BACKEND_URL}/admin/deleteCategory/${value}`,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${Cookies.get("jwtoken")}`,
          },
          withCredentials: true, // Send cookies with the request
        }
      );
      if (response?.response?.status === 400) {
        showNotificationWithMessage(
          "You can't delete this category as it is already assigned to a course",
          "error"
        );
      }
      if (response?.status === 200) {
        showNotificationWithMessage(
          "Category deleted successfully",
          "success"
        );
      } else {
        showNotificationWithMessage(
          "Something went wrong",
          "error"
        );
      }

      setSelectedCategory("");
      setSelectedSubCategory("");

      getCategories();
    } catch (error) {
      if (error.response.status === 400) {
        showNotificationWithMessage(
          "You can't delete this category as it is already assigned to a course",
          "error"
        );
      }
      console.log(error);
    }
  };

  const [editCategoryData, setEditCategoryData] = useState(null);
  const handleEditCate = async (value) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/admin/getCategoriesEdit/${value}`,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${Cookies.get("jwtoken")}`,
          },
          withCredentials: true, // Send cookies with the request
        }
      );
      // console.log("respocn", response);
      // setSelectedCategory(response.data.data._id)
      // setCategory(response.data.data.category)
      // setSubCategory(response.data.data.subcategories)

      setEditCategoryData({
        _id: response.data.data._id,
        category: response.data.data.category,
        subcategories: response.data.data.subcategories,
      });

      setShowModal(true);
    } catch (error) {
      console.log(error);
    }
  };

  //getBatches
  const getBatches = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/admin/GetBatchesByCourse/${params.id}`,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${Cookies.get("jwtoken")}`,
          },
          withCredentials: true, // Send cookies with the request
        }
      );
      // console.log("getBatches", response.data.data);
      setBatchName(response.data.data);
      let ids = response.data.data.map((batch) => { console.log(batch); return batch._id })

      setBatchIDs(
        ids
      );
    } catch (error) {
      console.error("Error fetching batches:", error);
      setBatchName([]);
    }
  };
  useEffect(() => {
    getBatches();
  }, []);

  const EditDeleteBatches = ({ option, batch }) => (
    <Space className="space">
      <div className="optionbatcheName">
        {/* <span className="optioncategory">{option}</span> */}
      </div>

      <Button
        type="button"
        onClick={(e) => {
          e.stopPropagation();
          // console.log(category)
          handleEditBatchName(option._id);
          setBatchDropdownVisible(false)
        }}
      >
        <i className="fa fa-pencil-square-o text-info"></i>
      </Button>

      <Button
        type="button"
        onClick={(e) => {
          e.stopPropagation();
          // console.log(option, batch)
          // console.log("option.value", option._id)
          handleDeleteBatchName(option._id);
        }}
      >
        <i className="fa fa-trash-o text-danger"></i>
      </Button>
    </Space>
  );

  const handleDeleteBatchName = async (value) => {
    try {
      setBatchDropdownVisible(false)
      // console.log("value", value);
      showLoader();
      const response = await axios.delete(
        `${process.env.REACT_APP_BACKEND_URL}/admin/DeleteBatches/${value}`,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${Cookies.get("jwtoken")}`,
          },
          withCredentials: true, // Send cookies with the request
        }
      );
      if (response?.response?.status === 400) {
        showNotificationWithMessage(
          "You can't delete this Batch as it is assigned to a Student", "error"
        );
      }
      if (response?.status === 200) {
        showNotificationWithMessage("Batch Deleted Successfully", "success");
      } else {
        showNotificationWithMessage(
          "Something went wrong",
          "error"
        );
      }
      getBatches();
    } catch (error) {
      if (error.response.status === 400) {
        showNotificationWithMessage("You can't delete this Batch as it is assigned to a Student", "error")
      }
    } finally {
      hideLoader();
    }
  };
  const [batchNameData, setBatchNameData] = useState(null);
  const handleEditBatchName = async (value) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/admin/GetBatchesEdit/${value}`,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${Cookies.get("jwtoken")}`,
          },
          withCredentials: true,
        }
      );
      // console.log("handleEditBatchName", response);
      setBatchNameData({
        _id: response.data.data._id,
        batchName: response.data.data.batchName,
      })
      setShowBatchModal(true);

    } catch (error) {
      console.log("error", error)
    }
  };

  if (result) {
    return (
      <>
        <div className="content-wrapper">
          <div
            className="container-full"



          >
            <div className="content-header">
              <div className="d-flex align-items-center">
                <div className="mr-auto">
                  <h3 className="page-title">Update Course</h3>
                  <div className="d-inline-block align-items-center">
                    <nav>
                      <ol className="breadcrumb">
                        <li className="breadcrumb-item"><NavLink to="/dashboard">Dashboard</NavLink></li>
                        <li className="breadcrumb-item" aria-current="page"> <NavLink to="/courselist"> Course</NavLink></li>
                        <li className="breadcrumb-item active" aria-current="page">Update Course</li>
                      </ol>
                    </nav>
                  </div>
                </div>

              </div>
            </div>
            <section className="content mt-4">
              <div className="row">

                {/* <form onSubmit={handleSubmit}> */}

                <Formik
                  initialValues={{
                    // batchName: result?.batchName,
                    title: result?.Name,
                    support: result?.support,
                    category: result?.category,
                    sub_category: result?.sub_category,
                    short_desc: result?.short_desc,
                    duration: result?.duration,
                    batches: result?.batches,
                    price: result?.price,
                    discount_price: result?.discount_price,
                    tag: result?.tag,
                    meta_title: result?.meta_title,
                    meta_keyword: result?.meta_keyword,
                    meta_desc: result?.meta_desc,
                    course_content: result?.course_content,
                  }}
                  validate={(values) => {
                    const errors = {};
                    if (!values.title) {
                      errors.title = "Please enter a Title";
                    }
                    if (!values.category) {
                      errors.category = "Please enter a Category";
                    }
                    // if (!values.sub_category) {
                    //   errors.sub_category = "Please enter a Sub Category";
                    // }
                    if (!values.duration) {
                      errors.duration = "Please enter a Duration";
                    }
                    if (!values.batches) {
                      errors.batches = "Please enter a Length";
                    }
                    if (!values.support) {
                      errors.support = "Please enter a Support";
                    }
                    if (image == "" || image == null || image == undefined) {
                      errors.image = "Please Upload an Image";
                    }
                    if (
                      posterImage == "" ||
                      posterImage == null ||
                      posterImage == undefined
                    ) {
                      errors.posterImage = "Please Upload A Poster Image";
                    }

                    if (!validationEnabled) {

                      if (!values.short_desc) {
                        errors.short_desc = "Please enter a Short Description";
                      }
                      if (!values.price) {
                        errors.price = "Please enter a Price";
                      }
                      if (!values.discount_price) {
                        errors.discount_price = "Please enter a Discount Price";
                      }
                      if (!values.tag) {
                        errors.tag = "Please enter a Tag";
                      }
                      if (!values.meta_title) {
                        errors.meta_title = "Please enter a Meta Title";
                      }
                      if (!values.meta_keyword) {
                        errors.meta_keyword = "Please enter a Meta Keyword";
                      }
                      if (!values.meta_desc) {
                        errors.meta_desc = "Please enter a Meta Description";
                      }
                      if (!values.course_content || values.course_content == "<p><br></p>" || values.course_content == " " || values.course_content == null || values.course_content == undefined) {
                        errors.course_content = "Please enter a course Description";
                      }
                    }
                    return errors;
                  }}
                  onSubmit={async (values) => {
                    // console.log("heer/////////", values);

                    const formData = new FormData();
                    formData.append("title", values.title);
                    formData.append("support", values.support);
                    formData.append("category", values.category);
                    formData.append("sub_category", values.sub_category);
                    formData.append("short_desc", values.short_desc);
                    formData.append("duration", values.duration);
                    formData.append("batches", values.batches);
                    formData.append("price", values.price);
                    formData.append("discount_price", values.discount_price);
                    formData.append("tag", values.tag);
                    formData.append("meta_title", values.meta_title);
                    formData.append("meta_keyword", values.meta_keyword);
                    formData.append("meta_desc", values.meta_desc);
                    formData.append("course_content", content);

                    formData.append("image", image);
                    formData.append("posterImage", posterImage);

                    formData.append("batchIDs", batchIDs);

                    try {
                      showLoader();
                      const response = await axios.put(
                        `${process.env.REACT_APP_BACKEND_URL}/admin/UpdateCourse/${params.id}`,
                        formData,
                        {
                          headers: {
                            "Content-Type": "multipart/form-data",
                            Authorization: `Bearer ${Cookies.get("jwtoken")}`,
                          },
                          withCredentials: true, // Send cookies with the request
                        }
                      );
                      // console.log(response.data);
                      // console.log(short_desc);
                      // alert("Course successfully updated");
                      // navigate("/CourseList");
                      showNotificationWithMessage('Course updated successfully', 'success');
                      setTimeout(() => {
                        navigate('/courselist');
                      }, 1000);
                    } catch (error) {
                      console.error(error);
                    }
                    finally {
                      hideLoader(); // Hide the global loader
                    }
                  }}
                >
                  {({
                    values,
                    handleChange,
                    handleBlur,
                    setFieldValue,
                    handleSubmit,
                    errors,
                  }) => (
                    <div className="col-lg-12 col-12 mx-auto">
                      {/* <!-- box1 --> */}

                      <div className="box">
                        <div className="box-body boxpadding pt-3">
                          <div className="row pt-4">
                            <div className="col-md-12">
                              <div className="form-group upcomingadmincourse">
                                <label className="labeltext">
                                  Upcoming Course
                                </label>
                                &nbsp;&nbsp;
                                <Switch
                                  checked={validationEnabled}
                                  onChange={() => setValidationEnabled(!validationEnabled)}
                                />
                                <div>(if you want to add upcoming course then enable this switch)</div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label className="labeltext">Title <span className="aesterik">*</span></label>
                                <input
                                  type="text"
                                  name="title"
                                  className="form-control"
                                  placeholder="URL"
                                  value={values.title}
                                  onChange={handleChange}
                                />
                                <ErrorMessage
                                  className="text-danger"
                                  name="title"
                                  component="div"
                                />
                              </div>
                            </div>

                            {/* batches start  */}
                            <div className="col-md-4">
                              <div className="form-group">
                                <label className="labeltext">Batches <span className="aesterik">*</span></label>
                                <Select
                                  placeholder="All Batches for This Course"
                                  style={{ width: "100%" }}
                                  options={batchName.map((batch) => ({
                                    value: batch._id,
                                    label: batch.batchName,
                                  }))
                                  }
                                  // onChange={(value) => {
                                  //   setSelectedBatchName(value);

                                  //   setFieldValue("batchName", value)
                                  // }}
                                  // value={values.batchName}

                                  onDropdownVisibleChange={(visible) =>
                                    setBatchDropdownVisible(visible)
                                  }
                                  open={batchdropdownVisible}
                                  dropdownRender={(menu) => (
                                    <div>
                                      {batchName.map((batch) => (
                                        <div
                                          key={batch._id}
                                          style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            padding: "4px 8px",
                                            cursor: "pointer",
                                          }}
                                          onClick={() => {
                                            setFieldValue("batchName", batch._id);
                                            setSelectedBatchName(batch._id);

                                            setBatchDropdownVisible(false);
                                          }}
                                        >
                                          <span>{batch.batchName}</span>
                                          <EditDeleteBatches
                                            option={batch}
                                            batch={batch.batchName}
                                          />
                                        </div>
                                      ))}
                                    </div>
                                  )}
                                />
                              </div>

                            </div>

                            <div className="col-md-2">
                              <Button
                                type="primary"
                                onClick={(e) => {
                                  setShowBatchModal(true);
                                  setBatchNameData(null);
                                  setCourseId(params.id);
                                }}
                                className="marginbtn addcat"
                              >
                                Add new Batch{" "}
                                <i
                                  className="fa fa-plus pl-2"
                                  aria-hidden="true"
                                ></i>
                              </Button>
                            </div>
                            {/* batches  end*/}

                            <div className="col-md-4">
                              <div className="form-group">
                                <label className="labeltext">Category <span className="aesterik">*</span></label>
                                <Select
                                  style={{ width: "100%" }}
                                  options={categories.map((category) => ({
                                    value: category.value,
                                    label: category.label,
                                  }))}
                                  onChange={(value) => {
                                    setSelectedCategory(value);
                                    setSelectedSubCategory("");
                                    setFieldValue("sub_category", "");
                                    setFieldValue("category", value);
                                  }}
                                  // value={selectedCategory}
                                  value={values.category}
                                  className="cat_update"
                                  onDropdownVisibleChange={(visible) =>
                                    setDropdownVisible(visible)
                                  }
                                  open={dropdownVisible}
                                  dropdownRender={(menu) => (
                                    <div>
                                      {categories.map((category) => (
                                        <div
                                          key={category.value}
                                          style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            padding: "4px 8px",
                                            cursor: "pointer",
                                          }}
                                          onClick={() => {
                                            setSelectedCategory(category.value);
                                            setDropdownVisible(false);
                                            setSelectedSubCategory("");
                                            setFieldValue("sub_category", "");
                                            setFieldValue("category", category.value);
                                          }}
                                        >
                                          <span>{category.label}</span>
                                          <EditDeleteOptions
                                            option={category}
                                            category={category.label}
                                          />
                                        </div>
                                      ))}
                                    </div>
                                  )}
                                />
                                <ErrorMessage
                                  className="text-danger"
                                  name="category"
                                  component="div"
                                />

                              </div>
                            </div>



                            {/* <div className="col-md-2 mt-2">

                              <Button
                                type="primary"
                                // style={{ marginTop: "1rem" }}
                                onClick={(e) => setShowModal(true)}
                                className="marginbtn addcat"
                              >
                                Add new Category<i className="fa fa-plus pl-2" aria-hidden="true"></i>
                              </Button>
                            </div> */}

                            <div className="col-md-2">
                              <Button
                                type="primary"
                                // style={{ marginTop: "2.5rem" }}
                                onClick={(e) => { setShowModal(true); setEditCategoryData(null) }}
                                className="marginbtn addcat"
                              >
                                Add new Category{" "}
                                <i
                                  className="fa fa-plus pl-2"
                                  aria-hidden="true"
                                ></i>
                              </Button>

                            </div>

                            <div className="col-md-5 offset-md-1 mt-2 pb-2">
                              <div className="form-group">
                                <label id="Sub_category" className="labeltext">
                                  Sub Category
                                </label>
                                <Select
                                  style={{ width: "100%" }}
                                  options={subCategoriesOptions}
                                  // value={selectedSubCategory}
                                  value={values.sub_category}
                                  onChange={(value) => {
                                    // console.log(value);
                                    setSelectedSubCategory(value);
                                    setFieldValue("sub_category", value);
                                  }}
                                />
                                <ErrorMessage
                                  className="text-danger"
                                  name="sub_category"
                                  component="div"
                                />
                              </div>
                            </div>

                            {/* <div className="col-md-5 mt-2">
                              <div className="form-group">
                              
                                <label id="Sub_category" className="labeltext">Sub Category
                                </label>
                                <Select
                                  style={{ width: "100%" }}
                                  options={subCategoriesOptions}
                              
                                  onChange={(value) => {
                                    setSelectedSubCategory(value);
                                    setFieldValue("sub_category", value);
                                  }}

                                  value={values.sub_category}
                                />
                              </div>
                            </div> */}


                            <div className="col-md-12 mt-2">
                              <div className="form-group">
                                <label className="labeltext">Short Description
                                  {!validationEnabled &&
                                    <span className="aesterik">*</span>
                                  }
                                </label>
                                <textarea
                                  rows="2"
                                  name="short_desc"
                                  className="form-control"
                                  placeholder="Short Description"
                                  value={values.short_desc}
                                  onChange={handleChange}
                                ></textarea>

                                <ErrorMessage
                                  className="text-danger"
                                  name="short_desc"
                                  component="div"
                                />
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>
                      {/* <!-- box1 -->
  
  
       <!-- box2 --> */}

                      <div className="box">
                        <div className="box-body boxpadding">
                          <div className="row pt-3 pb-1">
                            <div className="col-md-4 mt-2">
                              <div className="form-group">
                                <label className="labeltext">Duration (In Months) <span className="aesterik">*</span></label>
                                <input
                                  type="number"
                                  name="duration"
                                  className="form-control"
                                  placeholder="Duration"
                                  value={values.duration}
                                  onChange={handleChange}
                                />
                                <ErrorMessage
                                  className="text-danger"
                                  name="duration"
                                  component="div"
                                />
                              </div>
                            </div>


                            <div className="col-md-4 mt-2">
                              <div className="form-group">
                                <label className="labeltext">Length (In Hours) <span className="aesterik">*</span></label>
                                <input
                                  type="number"
                                  name="batches"
                                  className="form-control"
                                  placeholder="Length of the course"
                                  value={values.batches}
                                  onChange={handleChange}
                                />
                                <ErrorMessage
                                  className="text-danger"
                                  name="batches"
                                  component="div"
                                />
                              </div>
                            </div>


                            <div className="col-md-4 mt-2">
                              <div className="form-group">
                                <label className="labeltext">Support (In Months) <span className="aesterik">*</span></label>
                                <input
                                  type="number"
                                  name="support"
                                  className="form-control"
                                  placeholder="Support"
                                  value={values.support}
                                  onChange={handleChange}
                                />
                                <ErrorMessage
                                  className="text-danger"
                                  name="support"
                                  component="div"
                                />
                              </div>
                            </div>


                            <div className="col-md-4 mt-2">
                              <div className="form-group">
                                <label className="labeltext"> Price
                                  {!validationEnabled &&
                                    <span className="aesterik">*</span>
                                  }
                                </label>
                                <input
                                  type="number"
                                  name="price"
                                  className="form-control"
                                  placeholder="Price"
                                  value={values.price}
                                  onChange={handleChange}
                                />
                                <ErrorMessage
                                  className="text-danger"
                                  name="price"
                                  component="div"
                                />
                              </div>
                            </div>


                            <div className="col-md-4 mt-2">
                              <div className="form-group">
                                <label className="labeltext">Discount Price
                                  {!validationEnabled &&
                                    <span className="aesterik">*</span>
                                  }
                                </label>
                                <input
                                  type="number"
                                  name="discount_price"
                                  className="form-control"
                                  placeholder="Discount Price"
                                  value={values.discount_price}
                                  onChange={handleChange}
                                />
                                <ErrorMessage
                                  className="text-danger"
                                  name="discount_price"
                                  component="div"
                                />
                              </div>
                            </div>


                            <div className="col-md-4 mt-2">
                              <div className="form-group">
                                <label className="labeltext">Tags
                                  {!validationEnabled &&
                                    <span className="aesterik">*</span>
                                  }
                                </label>
                                <input
                                  type="text"
                                  name="tag"
                                  className="form-control"
                                  placeholder="Tags"
                                  value={values.tag}
                                  onChange={handleChange}
                                />
                                <ErrorMessage
                                  className="text-danger"
                                  name="tag"
                                  component="div"
                                />
                              </div>
                            </div>


                            <div className="col-md-6 mt-2">
                              <div className="form-group">
                                <label className="labeltext">Thumb Image <span className="aesterik">*</span></label>
                                <div className="row">
                                  <div className="col-lg-6 mt-3">
                                    <input
                                      type="file"
                                      name="image"
                                      className="form-control"
                                      accept="image/*"
                                      onChange={(e) => {
                                        // setImage(e.target.files[0]);
                                        const selectedThumbImage = e.target.files[0];
                                        if (selectedThumbImage) {
                                          if (selectedThumbImage.size > 5 * 1024 * 1024) {
                                            showNotificationWithMessage('Image size should be less than 5mb', 'error');
                                            e.target.value = null;
                                            // return;
                                          } else {
                                            setImage(selectedThumbImage);
                                          }
                                        }
                                      }}
                                    />
                                  </div>
                                  <div className="col-lg-2">
                                    {imageURL && (<img style={{ maxHeight: '3rem', margin: '1rem 2rem 0 0' }} src={imageURL} />)}
                                    {/* {errors.image && (
                                      <span className="text-danger">
                                        {errors.image}
                                      </span>
                                    )} */}
                                    <ErrorMessage
                                      className="text-danger"
                                      name="image"
                                      component="div"
                                    />
                                  </div>
                                </div>


                              </div>
                            </div>


                            <div className="col-md-6 mt-2">
                              <div className="form-group">
                                <label className="labeltext">Poster Image <span className="aesterik">*</span></label>
                                <div className="row pt-2 pb-2">
                                  <div className="col-lg-6 mt-3">
                                    <input
                                      type="file"
                                      name="posterImage"
                                      className="form-control"
                                      accept="image/*"
                                      onChange={(e) => {
                                        // setPosterImage(e.target.files[0]);
                                        const selectedPosterImage = e.target.files[0];
                                        if (selectedPosterImage) {
                                          if (selectedPosterImage.size > 5 * 1024 * 1024) {
                                            showNotificationWithMessage('Image size should be less than 5mb', 'error');
                                            e.target.value = null;
                                            // return;
                                          } else {
                                            setPosterImage(selectedPosterImage);
                                          }
                                        }
                                      }
                                      }
                                    />
                                  </div>
                                  <div className="col-lg-2">
                                    {posterURL && (<img style={{ maxHeight: '3rem', margin: '1rem 2rem 0 0' }} src={posterURL} />)}
                                    {/* {errors.posterImage && (
                                      <span className="text-danger">
                                        {errors.posterImage}
                                      </span>
                                    )} */}
                                    <ErrorMessage
                                      className="text-danger"
                                      name="posterImage"
                                      component="div"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>

                          </div>

                        </div>
                      </div>




                      {/* box3 */}

                      <div className="box">
                        <div className="box-body boxpadding">
                          <div className="row pt-2">
                            <div className="col-md-12 mt-2">
                              <div className="form-group">
                                <label className="labeltext">Description
                                  {!validationEnabled &&
                                    <span className="aesterik">*</span>
                                  }
                                </label>
                                <JoditEditor
                                  name="course_content"
                                  ref={editor}
                                  value={values.course_content}
                                  config={editorConfig}
                                  onChange={(newContent) => {
                                    setContent(newContent)
                                    setFieldValue('course_content', newContent)
                                  }
                                  }
                                />
                                {/* {error && !duration && <span className='invalid-input'> Enter valid duration</span>} */}
                                <ErrorMessage
                                  className="text-danger"
                                  name="course_content"
                                  component="div"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* box3 */}

                      <div className="box">
                        <div className="box-body boxpadding">
                          <div className="row pt-2">
                            <div className="col-md-6 mt-2">
                              <div className="form-group">
                                <label className="labeltext">Meta Title
                                  {!validationEnabled &&
                                    <span className="aesterik">*</span>
                                  }
                                </label>
                                <input
                                  type="text"
                                  name="meta_title"
                                  className="form-control"
                                  placeholder="Meta Title"
                                  value={values.meta_title}
                                  onChange={handleChange}
                                />
                                <ErrorMessage
                                  className="text-danger"
                                  name="meta_title"
                                  component="div"
                                />
                              </div>
                            </div>

                            <div className="col-md-6 mt-2">
                              <div className="form-group">
                                <label className="labeltext">Meta Keywords
                                  {!validationEnabled &&
                                    <span className="aesterik">*</span>
                                  }
                                </label>
                                <input
                                  type="text"
                                  name="meta_keyword"
                                  className="form-control"
                                  placeholder="Keywords"
                                  value={values.meta_keyword}
                                  onChange={handleChange}
                                />
                                <ErrorMessage
                                  className="text-danger"
                                  name="meta_keyword"
                                  component="div"
                                />
                              </div>
                            </div>

                            <div className="col-md-12 mt-2">
                              <div className="form-group">
                                <label className="labeltext">Meta Description
                                  {!validationEnabled &&
                                    <span className="aesterik">*</span>
                                  }
                                </label>
                                <textarea
                                  rows="2"
                                  name="meta_desc"
                                  className="form-control"
                                  placeholder="Meta Description"
                                  value={values.meta_desc}
                                  onChange={handleChange}
                                ></textarea>
                                <ErrorMessage
                                  className="text-danger"
                                  name="meta_desc"
                                  component="div"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <!-- box4 --> */}

                      {/* <!-- action --> */}
                      <div className="box-footer mb-4  text-center">
                        <button
                          type="submit"
                          onClick={e => scrollToError(errors, handleSubmit)}
                          className="btn btn-rounded btn_dark btn-outline btncourse"
                          style={{ padding: '6px 22px' }}
                        >
                          {/* <i className="ti-save-alt"></i>  */}
                          Update
                        </button>
                      </div>
                      <AddCategory
                        editCategoryData={editCategoryData}
                        getCategories={getCategories}
                        showModal={showModal}
                        setShowModal={setShowModal}
                        setSelectedCategory={setFieldValue}
                        setCat={setSelectedCategory}
                      />

                      <AddBatches
                        batchNameData={batchNameData}
                        getBatches={getBatches}
                        showBatchModal={showBatchModal}
                        setShowBatchModal={setShowBatchModal}
                        setSelectedBatchName={setSelectedBatchName}
                        setBatch={setSelectedBatchName}
                        courseId={courseId}
                      />
                    </div>

                  )}
                </Formik>
                {/* </form> */}
              </div>
            </section>
            {/* <!-- form --> */}

            {/* 		
          <!-- /.content --> */}




          </div >

          {/* <ImageCroper
            aspectRatio={aspectRatio}
            imagetoCrop={cropingImage}
            showModal={showModal}
            setImage={cropPosterImage ? setPosterImage : setImage}
            setShowModal={setShowModal}
          /> */}
        </div >
        {/* <!-- /.content-wrapper --> */}
      </>
    );

  }
};

export default UpdateCourse;