import React, { useEffect, useRef, useState } from "react";
import { useDownloadExcel } from "react-export-table-to-excel";
import axios from "axios";
import Cookies from "js-cookie";
import { NavLink, useNavigate } from "react-router-dom";
import { Button, Input, Space, Table, notification } from "antd";
import Highlighter from "react-highlight-words";
import { useReactToPrint } from "react-to-print";

import useNotification from "../../../useNotification"; // Import the custom hook
import Swal from "sweetalert2";
import { SearchOutlined } from "@ant-design/icons";
import { useGlobalLoader } from "../../../Loader/GlobalLoaderContext";
import debounce from "../../../Debounce";
import jsPDF from "jspdf";
import "jspdf-autotable";
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';

const CourseList = () => {
  const [status, setStatus] = useState(""); // Initialize status state

  useEffect(() => {
    const checkToken = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/admin/protected`,
          {
            headers: {
              Authorization: `Bearer ${Cookies.get("jwtoken")}`,
            },

            withCredentials: true, //============new
          }
        );
        const data = await response.data;
        // console.log(data);
      } catch (error) {
        navigate("/");
        // alert("Unauthorized");
      }
    };

    checkToken();
  }, []);

  const { showLoader, hideLoader } = useGlobalLoader();
  const { showNotificationWithMessage } = useNotification();
  const [courses, setCourses] = useState([]);
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [searchValue, setSearchValue] = useState([]);

  function titleCase(str) {
    str = str.toLowerCase().split(" ");
    for (let i = 0; i < str.length; i++) {
      str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
    }
    return str.join(" ");
  }

  //---------------------------delete course-------------------------------

  const deleteCourse = async (id) => {
    try {
      // Show the confirmation popup
      const confirmed = await Swal.fire({
        title: "Are you sure?",
        text: "Are you sure you want to delete this course?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      });

      if (!confirmed.isConfirmed) {
        return; // If user cancels, do nothing
      }
      showLoader();
      const response = await axios.delete(
        `${process.env.REACT_APP_BACKEND_URL}/admin/DeleteCourses/${id}`,
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("jwtoken")}`,
          },
          withCredentials: true, // Send cookies with the request
        }
      );

      if (response.data) {
        getCourses();
        showNotificationWithMessage("Course is deleted", "success");
      }
    } catch (error) {
      // console.error("Error deleting course:", error);
      showNotificationWithMessage(error.response.data.message, "error");
    } finally {
      hideLoader(); // Hide the global loader
    }
  };

  const searchInput = useRef(null);
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  // const [ setMediaType] = useState();

  const [columns, setColumns] = useState([
    // "S No.",
    // "Name",
    // "Email",
    // "Date",
    // "Actions",
    { title: "Sr No.", dataIndex: "index", key: "index", className: "srno" },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      ...getColumnSearchProps("name"),
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      sorted: true,
      sorter: (a, b) => a.category.localeCompare(b.category),
    },
    {
      title: "Duration",
      dataIndex: "duration",
      key: "duration",
      className: "srno",
    },
    // { title: "Price", dataIndex: "price", key: "price" },
    {
      title: "Length",
      dataIndex: "batches",
      key: "batches",
      className: "srno",
    },
    // { title: "Image", dataIndex: "image", key: "image", render: (text) => <img src={text} width={80} alt="image" /> },
    { title: "Status", dataIndex: "status", key: "status", className: "srno" },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      //   render: () => <a>Delete</a>,
    },
  ]);
  const [data, setData] = useState([]);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  useEffect(() => {
    getCourses();
  }, []);

  const getCourses = async () => {
    try {
      showLoader();
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/admin/GetCourses`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("jwtoken")}`,
          },

          withCredentials: true, // Send cookies with the request
        }
      );

      // const result = response.data;
      // setCourses(result);
      let data = await response.data;
      setCourses(data);
      let newData = [];
      data.map((item, index) => {
        newData.push({
          key: item._id,
          index: index + 1,
          name: item.Name,
          category: item?.category?.category,
          duration: item.duration,
          // price: item.price ? item.price : "Null",
          batches: item.batches,
          image: item.image,
          status: (
            <>
              <button
                type="button"
                onClick={(e) => handleStatusChange(e, item._id)}
                className={
                  item.status && item.status === "active"
                    ? `btn btn-xs btn-toggle active`
                    : `btn btn-xs btn-toggle`
                }
                data-toggle="button"
                aria-pressed={
                  item.status && item.status == "inactive" ? false : true
                }
                autocomplete="off"
              >
                <div className="handle"></div>
              </button>
            </>
          ),
          action: (
            <>
              {" "}
              <NavLink
                to={"/updatecourse/" + item._id}
                className="btn btn-circle btn-info btn-xs mr-10"
                data-toggle="tooltip"
                data-original-title="Edit"
              >
                <i class="ti-marker-alt"></i>
              </NavLink>
              <NavLink
                to="javascript:void(0)"
                className="btn btn-circle btn-danger btn-xs"
                title=""
                data-toggle="tooltip"
                data-original-title="Delete"
                onClick={() => {
                  deleteCourse(item._id);
                }}
              >
                <i class="ti-trash"></i>
              </NavLink>
            </>
          ),
        });
      });
      setData(newData);

      if (!response.status === 200) {
        const error = new Error(response.error);
        throw error;
      }
    } catch (error) {
      console.error("Error fetching courses:", error);
      navigate("/");
    } finally {
      hideLoader(); // Hide the global loader
    }
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const handleStatusChange = async (e, id) => {
    // console.log("EEEEE", e.target.classList.contains("active"));
    if (e.target.classList.contains("active")) {
      showLoader();
      const response = await axios.put(
        `${process.env.REACT_APP_BACKEND_URL}/admin/UpdateCourseStatus/${id}`,
        { status: "inactive" },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("jwtoken")}`,
          },
          withCredentials: true, // Send cookies with the request
        }
      );

      if (response) {
        // console.log(response);
        hideLoader();
        getCourses();
      }
    } else {
      showLoader();
      const response = await axios.put(
        `${process.env.REACT_APP_BACKEND_URL}/admin/UpdateCourseStatus/${id}`,
        { status: "active" },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("jwtoken")}`,
          },
          withCredentials: true, // Send cookies with the request
        }
      );

      if (response) {
        // console.log(response);
        hideLoader();
        getCourses();
      }
    }
  };

  const tableRef = useRef(null);

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "Users table",
    sheet: "Users",
  });

  // const componentRef = useRef();
  // const handlePrint = useReactToPrint({
  //   content: () => componentRef.current,
  // });


  const generatePDF = () => {
    const doc = new jsPDF();
    doc.text("Course List", 10, 10);

    // Create an array of data for the table
    const tableData = courses.map((course,index) => [
      index + 1,
      course.Name,
      course.category.category,
      course.duration,
      course.batches,
      course.support,
      course.price,
      course.discount_price,

      // Add more fields as needed
    ]);

    // Define table columns
    const tableColumns = ["Sr.No","Name", "Category" , "Duration" , "Batches" , "Support"  , "Price"  , "Discount Price"]; // Add more column headers as needed

    // Set table position (x, y), header row, and data
    doc.autoTable({
      startY: 20,
      head: [tableColumns],
      body: tableData,
    });

    doc.save("all_courses.pdf");
  };





  // search function   ========================

  const searchcourse = debounce(async () => {
    try {
      const inputValue = document.querySelector('input[name="search"]').value;
      if (inputValue) {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/admin/SearchCoursesAdmin/${inputValue}`,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (response) {
          let data = response.data;
          setCourses(data);
          let newData = [];
          data.map((item, index) => {
            newData.push({
              key: item._id,
              index: index + 1,
              name: item.Name,
              category: item.category.category,
              duration: item.duration,
              // price: item.price ? item.price : "Null",
              batches: item.batches,
              image: item.image,
              status: (
                <>
                  <button
                    type="button"
                    onClick={(e) => handleStatusChange(e, item._id)}
                    className={
                      item.status && item.status === "active"
                        ? `btn btn-xs btn-toggle active`
                        : `btn btn-xs btn-toggle`
                    }
                    data-toggle="button"
                    aria-pressed={
                      item.status && item.status == "inactive" ? false : true
                    }
                    autocomplete="off"
                  >
                    <div className="handle"></div>
                  </button>
                </>
              ),
              action: (
                <>
                  {" "}
                  <NavLink
                    to={"/updatecourse/" + item._id}
                    className="btn btn-circle btn-info btn-xs mr-10"
                    data-toggle="tooltip"
                    data-original-title="Edit"
                  >
                    <i class="ti-marker-alt"></i>
                  </NavLink>
                  <NavLink
                    to="javascript:void(0)"
                    className="btn btn-circle btn-danger btn-xs"
                    title=""
                    data-toggle="tooltip"
                    data-original-title="Delete"
                    onClick={() => {
                      deleteCourse(item._id);
                    }}
                  >
                    <i class="ti-trash"></i>
                  </NavLink>
                </>
              ),
            });
          });
          setData(newData);
          // console.log(response);
        }
      } else {
        getCourses();
      }
    } catch (error) {
      showNotificationWithMessage("something went wrong", "error");
      console.error(error);
    }
  }, 2000);

  // sort by status code

  useEffect(() => {
    if (status) {
      fetchCoursesByStatus();
    }
  }, [status]);

  const fetchCoursesByStatus = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/admin/SortCoursesByStatus/${status}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response) {
        let data = response.data;
        setCourses(data);
        let newData = [];
        data.map((item, index) => {
          newData.push({
            key: item._id,
            index: index + 1,
            name: item.Name,
            category: item.category.category,
            duration: item.duration,
            // price: item.price ? item.price : "Null",
            batches: item.batches,
            image: item.image,
            status: (
              <>
                <button
                  type="button"
                  onClick={(e) => handleStatusChange(e, item._id)}
                  className={
                    item.status && item.status === "active"
                      ? `btn btn-xs btn-toggle active`
                      : `btn btn-xs btn-toggle`
                  }
                  data-toggle="button"
                  aria-pressed={
                    item.status && item.status == "inactive" ? false : true
                  }
                  autocomplete="off"
                >
                  <div className="handle"></div>
                </button>
              </>
            ),
            action: (
              <>
                {" "}
                <NavLink
                  to={"/updatecourse/" + item._id}
                  className="btn btn-circle btn-info btn-xs mr-10"
                  data-toggle="tooltip"
                  data-original-title="Edit"
                >
                  <i class="ti-marker-alt"></i>
                </NavLink>
                <NavLink
                  to="javascript:void(0)"
                  className="btn btn-circle btn-danger btn-xs"
                  title=""
                  data-toggle="tooltip"
                  data-original-title="Delete"
                  onClick={() => {
                    deleteCourse(item._id);
                  }}
                >
                  <i class="ti-trash"></i>
                </NavLink>
              </>
            ),
          });
        });
        setData(newData);
        // console.log(response);
      }
    } catch (error) {
      console.error("Error fetching Courses", error);
      showNotificationWithMessage("Error fetching Courses", "error");
    }
  };

  const handleSortByStatus = (e) => {
    setStatus(e.target.value); // Update the status state
  };
  // end sort by status code

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault(); // prevent default behavior
      searchcourse(searchValue);
    }
  };

  const generateExcel = () => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
  
    // Define the heading row
    const headingRow = [
      "Sr. No",
      "Name",
      "Category",
      "Duration",
      "Batches",
      "Support",
      "Price",
      "Discount Price",
      // Add more headings as needed
    ];
  
    // Extract data from courses and add the heading row as the first element
    const extractedData = [headingRow, ...courses.map((course, index) => [
      index + 1,
      course.Name,
      course.category.category,
      course.duration,
      course.batches,
      course.support,
      course.price,
      course.discount_price,
      // Add more fields as needed
    ])];
  
    const ws = XLSX.utils.json_to_sheet(extractedData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, "all_courses.xlsx");
  };

  return (
    <>
      <div className="content-wrapper">
        <div className="container-full">
          {/* <!-- Main content --> */}
          <section className="content">
            <div className="row">
              <div class="col-12" style={{ marginBottom: 20 }}>
                <div className="content-header pagetitle-pl">
                  <div className="d-flex align-items-center">
                  <div className="mr-auto">
              <h3 className="page-title">Course List</h3>
                <nav>
                <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                      <NavLink to="/dashboard">Dashboard</NavLink>
                    </li>
                    <li class="breadcrumb-item" aria-current="page">
                    <NavLink to="/courselist"> Course</NavLink>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">Course List</li>
                  </ol>
                </nav>
              </div>
              <div className="mr-end">
              <NavLink
                  className="waves-effect waves-light btn-block btn btn_dark addcourse"
                  to="/add-course"
                  style={{ padding: '6px 22px' }}
                >


                  <i className="fa fa-plus mr-10"></i>
                 
                  Add Course
                </NavLink>
              </div>
                  </div>
                </div>
              </div>

              <div className="col-12">
                <div className="box" style={{ marginTop: 20 }}>
                  <div className="box-body">
                    <div className="row py-2">
                      <div className="col-3">
                        <div
                          className="app-menu"
                          style={{ marginTop: 30, marginBottom: 30 }}
                        >
                          <div class="search-bx ml-4">
                            <form>
                              <div className="form-group">
                                <select
                                  value={status}
                                  onChange={handleSortByStatus}
                                  class="form-control searchselect"
                                >
                                  <option value="All">All</option>
                                  <option value="active">Active</option>
                                  <option value="inactive">Inactive</option>
                                </select>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>

                      <div className="col-8 text-right">
                        <div
                          class="app-menu"
                          style={{ marginTop: 30, marginBottom: 30 }}
                        >
                          <div class="search-bx ml-5">
                            <div class="input-group">
                              <input
                                type="search"
                                className="form-control searchcourse"
                                name="search"
                                placeholder="Search"
                                value={searchValue}
                                aria-label="Search"
                                aria-describedby="button-addon2"
                                onChange={(e) => {
                                  setSearchValue(e.target.value);
                                  searchcourse(e.target.value);
                                }}
                                onKeyPress={handleKeyPress}
                              />
                              <div class="input-group-append appendsearch">
                                <button
                                  className="btn btnsearch"
                                  type="submit"
                                  id="button-addon3"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    searchcourse(searchValue);
                                  }}
                                >
                                  <i class="ti-search"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-1">
                        <div className="dropdown">
                          <a
                            data-toggle="dropdown"
                            href="#"
                            className="px-10 coursefilter"
                          >
                            <i class="fa fa-ellipsis-v"></i>
                          </a>
                          <div className="dropdown-menu dropdown-menu-right">
                            <a
                              className="dropdown-item labeltext"
                              // onClick={onDownload}
                              onClick={generateExcel}
                              href="#"
                            >
                              <i className="ti-import"></i>Excel
                            </a>

                            <a
                              className="dropdown-item labeltext droppt"
                              onClick={generatePDF}
                              href="#"
                            >
                              <i className="ti-import"></i>PDF
                            </a>


                            {/* <a class="dropdown-item labeltext droppt" href="#"><i class="ti-printer"></i> Print</a> */}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="table-responsive">
                      {/* <div ref={componentRef}> */}
                      <div>
                        <Table
                          className="customTable"
                          columns={columns}
                          dataSource={data}
                          pagination={{ pageSize: 10 }}
                          // ref={tableRef}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* <!-- /.content --> */}
        </div>
      </div>
    </>
  );
};

export default CourseList;
