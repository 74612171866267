import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import axios from "axios";
// import { notification } from 'antd';
import useNotification from "../../../useNotification"; // Import the custom hook
import { useGlobalLoader } from "../../../Loader/GlobalLoaderContext";

const Header = () => {
  const { showLoader, hideLoader } = useGlobalLoader();
  const navigate = useNavigate();
  const { showNotificationWithMessage } = useNotification();

  const handleLogout = async () => {
    try {
      showLoader();

      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/admin/logoutAdmin`,
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("jwtoken")}`,
          },
          withCredentials: true, // Send cookies with the request
        }
      );
      // console.log(response.data);

      if (response.data) {
        hideLoader();

        showNotificationWithMessage("Logout Successfully", "success");
        setTimeout(() => {
          navigate("/");
        }, 1000);
      } else {
        showNotificationWithMessage("Something went wrong", "error");
      }
    } catch (error) {
      showNotificationWithMessage("Something went wrong", "error");
    }
  };

  return (
    <>
      <header className="main-header">
        <div className="d-flex align-items-center logo-box justify-content-start">
          <a href="" className="logo">
            <div className="logo-lg">
              <span className="light-logo">
                <img
                  src="../../assets/img/learnovationlogo.png"
                  alt="logo"
                  className="logoimg"
                />
              </span>
              <span className="dark-logo">
                <img
                  src="../../assets/img/learnovationlogo.png"
                  alt="logo"
                  className="logoimg"
                />
              </span>
            </div>
          </a>
        </div>

        <nav className="navbar navbar-static-top">
          <div className="app-menu">
            <ul className="header-megamenu nav">
              <li className="btn-group nav-item d-md-none">
                <a
                  href="#"
                  className="waves-effect waves-light nav-link push-btn"
                  data-toggle="push-menu"
                  role="button"
                >
                  <span className="icon-Align-left">
                    <span className="path1"></span>
                    <span className="path2"></span>
                    <span className="path3"></span>
                  </span>
                </a>
              </li>
            </ul>
          </div>

          <div className="navbar-custom-menu r-side">
            <ul className="nav navbar-nav">
              <li className="btn-group nav-item d-lg-inline-flex d-none">
                <NavLink
                  to="#"
                  data-provide="fullscreen"
                  className="waves-effect waves-light nav-link full-screen"
                  title="Full Screen"
                >
                  <i className="icon-Expand-arrows">
                    <span className="path1"></span>
                    <span className="path2"></span>
                  </i>
                </NavLink>
              </li>
              <li className="dropdown user user-menu">
                <a
                  href="#"
                  className="waves-effect waves-light dropdown-toggle"
                  data-toggle="dropdown"
                  title="User"
                >
                  <i className="icon-User">
                    <span className="path1"></span>
                    <span className="path2"></span>
                  </i>
                </a>

                <ul className="dropdown-menu animated flipInX dropdownmargin dropdownprofile">
                  <li className="user-body">
                    <h5 className="dropdownh5 admindropdown">
                      Learnovation Admin
                    </h5>
                    <div className="dropdown-divider"></div>
                    <NavLink
                      className="dropdown-item admindropdown"
                      to="/admin-profile"
                    >
                      <i className="fa fa-user" aria-hidden="true"></i> Profile
                    </NavLink>

                    <div className="dropdown-divider"></div>
                    <NavLink
                      onClick={handleLogout}
                      className="dropdown-item admindropdown"
                      to="#"
                    >
                      <i className="fa fa-sign-out" aria-hidden="true"></i>{" "}
                      Logout
                    </NavLink>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </nav>
      </header>
    </>
  );
};

export default Header;
