import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { NavLink, useNavigate } from "react-router-dom";
import Highlighter from "react-highlight-words";
import styles from './student.module.css'
import useNotification from "../../../useNotification"; // Import the custom hook
import Swal from "sweetalert2";
import { SearchOutlined } from "@ant-design/icons";

import { useGlobalLoader } from "../../../Loader/GlobalLoaderContext";
import { useDownloadExcel } from "react-export-table-to-excel";
import { useReactToPrint } from "react-to-print";
import debounce from "../../../Debounce";
import jsPDF from "jspdf";
import "jspdf-autotable";
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';

import {
  Button,
  Input,
  Space,
  Table,
  notification,
  Col,
  DatePicker,
  Drawer,
  Form,
  Row,
  Select,
  Divider,
} from "antd";
const { Option } = Select;
const Students = () => {
  const navigate = useNavigate();


  const DescriptionItem = ({ title, content }) => (
    <div className="site-description-item-profile-wrapper">
      <p className="site-description-item-profile-p-label">{title}:</p>
      {content}
    </div>
  );

  const { showLoader, hideLoader } = useGlobalLoader();
  const [hasActiveSubscription, setHasActiveSubscription] = useState(false);
  const { showNotificationWithMessage } = useNotification();
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const [subscriberId, setSubscriberId] = useState([]);
  const [viewPayment, setViewPayment] = useState(0);
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [childrenDrawer, setChildrenDrawer] = useState(false);
  const [students, setStudents] = useState([]);
  const [studentData, setStudentData] = useState({});
  const [searchValue, setSearchValue] = useState([]);
  const [columns, setColumns] = useState([
    { title: "Sr no", dataIndex: "index", key: "index", className: "srno" },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      ...getColumnSearchProps("name"),
    },
    // { title: "Email", dataIndex: "email", key: "email" },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (email) => (
        <NavLink className="acolor" to={`mailto:${email}`}>
          {email}
        </NavLink>
      ),
    },
    {
      title: "Phone",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      render: (phoneNumber) => (
        <NavLink className="acolor" to={`tel:${phoneNumber}`}>
          {phoneNumber}
        </NavLink>
      ),
    },
    {
      title: "Registration Date",
      dataIndex: "Date",
      key: "Date",
      sorter: (a, b) => a.Date.localeCompare(b.Date),
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      sorter: (a, b) => a.isSubscriber - b.isSubscriber,
      render: (text, record) => {
        if (record.isSubscriber) {
          return <div className={styles.subscriberStyles}>Subscriber</div>
        } else {
          return <div className={styles.unsbscriberStyles}>Free Student</div>
        }
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      //   render: () => <a>Delete</a>,
    },
  ]);

  const [data, setData] = useState([]);
  const [studentSubscription, setStudentSubscription] = useState([]);
  const navigator = useNavigate();


  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  //delete student
  const deleteStudent = async (id) => {
    try {
      // Show the confirmation popup
      const confirmed = await Swal.fire({
        title: "Are you sure?",
        text: "Are you sure you want to delete this?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      });

      if (!confirmed.isConfirmed) {
        return; // If user cancels, do nothing
      }

      showLoader();
      const response = await axios.delete(
        `${process.env.REACT_APP_BACKEND_URL}/admin/deletestudent/${id}`,
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("jwtoken")}`,
          },
          withCredentials: true, // Send cookies with the request
        }
      );

      if (response.data) {
        getStudents();
        showNotificationWithMessage("student is deleted", "success");
      }
    } catch (error) {
      console.log(error);
    } finally {
      hideLoader();
    }
  };

  const editStudent = async (id) => {
    navigator(`/admin/editstudent/${id}`);
  };

  const getStudentubSubscription = async (id) => {
    try {
      showLoader();
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/admin/getStudentSubscription/${id}`,
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("jwtoken")}`,
          },
          withCredentials: true, // Send cookies with the request
        }
      );

      if (response.data) {
        setStudentSubscription(response.data);
        response.data.forEach((element) => {
          if (element.status == "active") {
            setHasActiveSubscription(true);
          }
        });
        setDrawerOpen(true);
      }
    } catch (error) {
      console.log(error);
      if (error.response.status === 404) {
        setStudentSubscription([]);
        setDrawerOpen(true);
      }
    } finally {
      hideLoader();
    }
  };

  const getStudents = async () => {
    try {
      showLoader();
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/admin/GetStudents`,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${Cookies.get("jwtoken")}`,
          },
          withCredentials: true, // Send cookies with the request
        }
      );
      const result = response.data;
      // console.log("getSTu", result)
      let data = result.map((item, index) => {
        return {
          index: index + 1,
          Actions: (
            <div className="btn-group">
              <button
                type="button"
                className="btn-circle btn-info btn-xs mr-10"
                onClick={() => deleteStudent(item._id)}
              >
                <i className="fa fa-eye"></i>
              </button>
              <button
                type="button"
                className="btn btn-circle btn-sm btn-primary"
                onClick={() => editStudent(item._id)}
              >
                <i className="fa fa-edit"></i>
              </button>
              <button
                type="button"
                className="btn btn-circle btn-info btn-xs mr-10"
                onClick={() => deleteStudent(item._id)}
              >
                <i className="fa fa-trash"></i>
              </button>
            </div>
          ),
          name: item.name,
          email: item.email,
          phoneNumber: item.phoneNumber,
          Date: item.createdAt.split("T")[0],
          action: (
            <div className="btn-group">
              <button
                type="button"
                className="btn-circle btn-info btn-xs mr-10"
                onClick={() => {
                  setStudentData(item);
                  getStudentubSubscription(item._id);
                }}
              >
                <i className="fa fa-eye"></i>
              </button>
              <button
                type="button"
                className="btn-circle btn-success btn-xs mr-10"
                onClick={() => editStudent(item._id)}
              >
                <i className="ti-marker-alt"></i>
              </button>
              <button
                type="button"
                className="btn-circle btn-danger btn-xs mr-10"
                onClick={() => deleteStudent(item._id)}
              >
                <i className="fa fa-trash"></i>
              </button>
            </div>
          ),
          key: item._id,
          isSubscriber: item.subscriber
        };
      });

      // console.log("data", data);
      setData(data);
      setStudents(result);
    } catch (error) {
      console.error("Error fetching products:", error);
    } finally {
      hideLoader();
    }
  };

  const onClose = () => {
    setDrawerOpen(false);
  };

  const getAllSubscriberIds = async () => {
    try {
      showLoader();
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/admin/getAllSubscriberIds`,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${Cookies.get("jwtoken")}`,
          },
          withCredentials: true, // Send cookies with the request
        }
      );
      if (response.data) {
        // console.log("HERE?????", response.data.data, response.data.data.length)
        setSubscriberId(response.data.data)
      }
    }
    catch (e) {
      console.log(e)
    }
  }
  const showChildrenDrawer = () => {
    setChildrenDrawer(true);
  };
  const onChildrenDrawerClose = () => {
    setChildrenDrawer(false);
  };

  const tableRef = useRef(null);

  // const { onDownload } = useDownloadExcel({
  //   currentTableRef: tableRef.current,
  //   filename: "Users table",
  //   sheet: "Users",
  // });

  // const componentRef = useRef();
  // const handlePrint = useReactToPrint({
  //   content: () => componentRef.current,
  // });




  const generatePDF = () => {
    const doc = new jsPDF();
    doc.text("Student List", 10, 10);

    // Create an array of data for the table
    const tableData = students.map((student, index) => [
      index + 1,
      student.name,
      student.email,
      student.phoneNumber,
      student.city,
      student.age,
      // Add more fields as needed
    ]);

    // Define table columns
    const tableColumns = ["Sr.No", "Name", "Email", "Phone", "City", "Age"]; // Add more column headers as needed

    // Set table position (x, y), header row, and data
    doc.autoTable({
      startY: 20,
      head: [tableColumns],
      body: tableData,
    });

    doc.save("all_students.pdf");
  };


  // search function   ========================


  const searchstudent = debounce(async (event) => {
    try {
      showLoader()
      const inputValue = document.querySelector('input[name="search"]').value;
      if (inputValue) {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/admin/SearchStudents/${inputValue}`,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (response) {

        }
        const result = response.data;
        let data = result.map((item, index) => {
          // console.log("item", item._id);
          return {
            index: index + 1,
            name: item.name,
            email: item.email,
            phoneNumber: item.phoneNumber,
            // Date: item.createdAt,
            Date: item.createdAt.split("T")[0],
            action: (
              <div className="btn-group">
                <button
                  type="button"
                  className="btn-circle btn-info btn-xs mr-10"
                  onClick={() => {
                    setStudentData(item);
                    getStudentubSubscription(item._id);
                  }}
                >
                  <i className="fa fa-eye"></i>
                </button>
                <button
                  type="button"
                  className="btn-circle btn-success btn-xs mr-10"
                  onClick={() => editStudent(item._id)}
                >
                  <i className="ti-marker-alt"></i>
                </button>
                <button
                  type="button"
                  className="btn-circle btn-danger btn-xs mr-10"
                  onClick={() => deleteStudent(item._id)}
                >
                  <i className="fa fa-trash"></i>
                </button>
              </div>
            ),
            key: item._id,
            isSubscriber: item.subscriber
          };
        });
        // console.log("data", data);
        setData(data);
        hideLoader()
        setStudents(result);
      } else {
        getStudents();
      }
    } catch (error) {
      showNotificationWithMessage("something went wrong", "error");
      console.error(error);
      hideLoader()
    }
  }, 2000);

  useEffect(() => {
    getStudents();
  }, []);
  useEffect(() => {
    const checkToken = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/admin/protected`,
          {
            headers: {
              Authorization: `Bearer ${Cookies.get("jwtoken")}`,
            },

            withCredentials: true, //============new
          }
        );
        const data = await response.data;
        // console.log(data);
      } catch (error) {
        navigate("/");
        // alert("Unauthorized");
      }
    };

    checkToken();
  }, []);

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault(); // prevent default behavior
      searchstudent(searchValue);
    }
  };

  const generateExcel = () => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    const extractedData = data.map((item) => ({
      Name: item.name,
      Email: item.email,
      Phone: item.phoneNumber,
    }));

    const ws = XLSX.utils.json_to_sheet(extractedData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const excelData = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(excelData, "StudentList" + fileExtension);
  }




  return (
    <>
      <div className="content-wrapper">
        <div className="container-full">
          <div className="content-header">
            <div className="d-flex align-items-center">
              <div className="mr-auto">
                <h3 className="page-title">Student List</h3>
                <div className="d-inline-block align-items-center">
                  <nav>
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <NavLink to="/dashboard">Dashboard</NavLink>
                      </li>
                      <li className="breadcrumb-item" aria-current="page">
                        Student
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        Student List
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
          <section className="content mt-4">
            <div className="row">
              <div className="col-12">
                <div className="box" style={{ marginTop: 20 }}>
                  <div className="box-body">
                    <div className="row py-2">
                      <div className="col-5 pt-3  pl-4 ml-4">
                        <button
                          className="excel px-4"
                          // onClick={onDownload}
                          onClick={generateExcel}

                          style={{ marginTop: 20 }}
                        >
                          <i className="fa fa-sign-in" aria-hidden="true"></i>
                          <span className="ml-2"> Excel</span>
                        </button>

                        <button
                          className="excel px-4 pdf ml-3"
                          onClick={generatePDF}
                          style={{ marginTop: 20 }}
                        >
                          <i className="fa fa-sign-in" aria-hidden="true"></i>
                          <span className="ml-2">PDF</span>
                        </button>
                      </div>

                      <div className="col-6 text-right">
                        <div
                          class="app-menu"
                          style={{ marginTop: 30, marginBottom: 30 }}
                        >
                          <div class="search-bx ml-5">
                            <div class="input-group">
                              <input
                                type="search"
                                className="form-control searchcourse"
                                name="search"
                                placeholder="Search"
                                aria-label="Search"
                                aria-describedby="button-addon2"
                                onChange={(e) => {
                                  setSearchValue(e.target.value);
                                  searchstudent(e.target.value);
                                }}
                                onKeyPress={handleKeyPress}
                              />
                              <div class="input-group-append appendsearch">
                                <button
                                  class="btn"
                                  type="submit"
                                  id="button-addon3"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    searchstudent(searchValue);
                                  }}

                                >
                                  <i class="ti-search"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="table-responsive">
                      {/* <div ref={componentRef}> */}
                      <div>
                        <Table
                          className="customTable"
                          columns={columns}
                          dataSource={data}
                          pagination={{ pageSize: 10 }}
                        // ref={tableRef}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <Drawer
          width={800}
          placement="right"
          closable={true}
          onClose={onClose}
          open={drawerOpen}
        >
          <div className="text-center">
            <img
              src="../../admin-assets/images/account.png"
              alt=""
              class="align-self-end"
            />
          </div>
          <p
            className="site-description-item-profile-p pt-2 profileheading"
          // style={{ marginBottom: 24 }}
          >
            User Profile
          </p>
          {/* <p className="site-description-item-profile-p studentname">
            {studentData.name}
          </p> */}

          <p className="site-description-item-profile-p profilesubhead mb-4">
            Personal Information
          </p>
          <Divider />
          <Row>
            <Col span={12}>
              <DescriptionItem title="Account" content={studentData.email} />
            </Col>
            <Col span={12}>
              <DescriptionItem
                title="Student Name"
                content={studentData.name}
              />
            </Col>
            <Col span={12}>
              <DescriptionItem
                title="Phone no"
                content={studentData.phoneNumber}
              />
            </Col>
            <Col span={12}>
              <DescriptionItem
                title="Registered on"
                content={studentData?.createdAt?.split("T")[0]}
              />
            </Col>
          </Row>

          <div class="box mt-3">
            <div class="box-body">
              <div className="box no-shadow mb-0 bg-transparent">
                <div className="box-header no-border px-0">
                  <h4 className="box-title pl-3">Subscription Details</h4>
                </div>
              </div>
            </div>
            <div class="table-responsive">


              {studentSubscription && studentSubscription.length > 0 ? (
                <table
                  id="example"
                  className="table table-hover display nowrap margin-top-10 w-p100"
                >
                  <thead className="tablehead">
                    <tr>
                      <th>Course Name</th>
                      <th>Batch</th>
                      <th>Status</th>
                      <th>Start date</th>

                      <th>End Date</th>
                      <th>Balance</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  {studentSubscription.map((subscription, index) => (
                    <tbody>
                      <tr key={index}>
                        <td>{subscription.course?.Name}</td>
                        <td>{subscription.batch?.batchName}</td>
                        <td>{subscription.status}</td>
                        <td>
                          {new Date(
                            subscription.startDate
                          ).toLocaleDateString()}
                        </td>

                        <td>
                          {new Date(subscription.endDate).toLocaleDateString()}
                        </td>
                        <td>{subscription.remainingAmount}</td>

                        <td>
                          <Button
                            type="primary"
                            className="viewpayment"
                            onClick={e => { showChildrenDrawer(); setViewPayment(index) }}
                          >
                            View Payment
                          </Button>
                        </td>
                      </tr>
                    </tbody>
                  ))}
                </table>
              ) : (
                <h6 className="pl-4 pb-4 nodatafound text-center">No Subscriptions Found</h6>
              )}
            </div>
          </div>

          <Drawer
            title=""
            width={500}
            closable={false}
            onClose={onChildrenDrawerClose}
            open={childrenDrawer}
          >
            {studentSubscription && studentSubscription.length > 0 ? (
              <div>
                <p className="site-description-item-profile-p profilesubhead">
                  Payment History
                </p>
                <Divider />
                {studentSubscription[viewPayment].paymentHistory.map((payment, paymentIndex) => (
                  <div key={paymentIndex}>
                    <Row>
                      <Col span={12}>
                        <DescriptionItem
                          title="Date"
                          content={new Date(
                            payment.date
                          ).toLocaleDateString()}
                        />
                      </Col>
                      <Col span={12}>
                        <DescriptionItem
                          title="Amount"
                          content={payment.amount}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col span={12}>
                        <DescriptionItem
                          title="Transaction ID"
                          content={payment.transactionId}
                        />
                      </Col>

                      <Col span={12}>
                        <DescriptionItem
                          title="Payment Mode"
                          content={payment.paymentMethod}
                        />
                      </Col>
                      <Col span={8}>
                        <DescriptionItem
                          title="Status"
                          content={payment.status}
                        />
                      </Col>
                    </Row>
                    <Divider />
                  </div>
                ))}
              </div>
            ) : (
              <h6 className="pl-4 pt-4 nodatafound">No Subscriptions Found</h6>
            )}
          </Drawer>
        </Drawer>
      </div>
    </>
  );
};

export default Students;
