import { notification } from "antd";
import axios from "axios";
import { NavLink } from "react-router-dom";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ImageCroper from "../../ImageCroper/ImageCroper";
import useNotification from '../../../useNotification'; // Import the custom hook
import { useGlobalLoader } from "../../../Loader/GlobalLoaderContext";

const UpdateBanner = () => {

  useEffect(() => {
    const checkToken = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/admin/protected`,
          {
            headers: {
              Authorization: `Bearer ${Cookies.get("jwtoken")}`,
            },

            withCredentials: true, //============new
          }
        );
        const data = await response.data;
        // console.log(data);
      } catch (error) {
        navigate("/");
        // alert("Unauthorized");
      }
    };

    checkToken();
  }, []);

  const { showLoader, hideLoader } = useGlobalLoader();
  const { showNotificationWithMessage } = useNotification();

  //get banner by id
  const [bannerPage, setBannerPage] = useState();
  const [bannerImage, setBannerImage] = useState();
  const [cropingImage, setCropingImage] = useState(false);
  const [aspectRatio, setAspectRatio] = useState(4.8);
  const [cropPosterImage, setPosterImageToCrop] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [imageURL, setImageURL] = useState("");

  const params = useParams();
  const navigate = useNavigate();

  const getBannerImage = async () => {
    try {
      showLoader();
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/admin/GetEditBanner/${params.id}`,
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("jwtoken")}`,
          },
          withCredentials: true, // Send cookies with the request
        }
      );

      const result = response.data;
      setBannerPage(result.bannerPage);
      setBannerImage(result.bannerURL);
    } catch (error) {
      // notification.warning({
      //   message: "something went wrong",
      // });
      showNotificationWithMessage('Something went wrong', 'error');

    }
    finally {
      hideLoader();
    }
  };

  useEffect(() => {
    getBannerImage();
  }, []);

  //update banner
  const updateBanner = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("bannerPage", bannerPage);
    formData.append("bannerImage", bannerImage);

    try {
      showLoader();
      const response = await axios.put(
        `${process.env.REACT_APP_BACKEND_URL}/admin/UpdateBanner/${params.id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${Cookies.get("jwtoken")}`,
          },
          withCredentials: true, // Send cookies with the request
        }
      );
      // console.log(response.data);
      // notification.success({
      //   message: "Banner Image Updated Successfully",
      // });
      // navigate("/ViewBanner");

      showNotificationWithMessage('Banner Updated Successfully', 'success');
      setTimeout(() => {
        navigate('/banner');
      }, 1000);
    } catch (error) {
      // notification.warning({
      //   message: "something went wrong",
      // });
      showNotificationWithMessage('Something went wrong', 'error');

    }
    finally {
      hideLoader();
    }
  };

  useEffect(() => {
    if (bannerImage?.size) {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(bannerImage);
      fileReader.onloadend = () => {
        setImageURL(fileReader.result);
      };
    }
  }, [bannerImage])


  return (
    <>
      <div className="content-wrapper">
        <div className="container-full">
          <div className="content-header">
            <div className="d-flex align-items-center">
              <div className="mr-auto">
                <h3 className="page-title">Update Banner</h3>
                <div className="d-inline-block align-items-center">
                  <nav>
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item"><NavLink to="/dashboard">Dashboard</NavLink></li>
                      <li className="breadcrumb-item" aria-current="page"><NavLink to="/banner">Banner</NavLink></li>
                      <li className="breadcrumb-item active" aria-current="page">Update Banner</li>
                    </ol>
                  </nav>
                </div>
              </div>

            </div>
          </div>
          <section className="content mt-5">
            <div className="row pt-3">

              {/* <form>/ */}
              <div className="col-lg-12 col-12 mx-auto">
                {/* <!-- box1 --> */}

                <div className="box">

                  <div className="box-body boxpadding">
                    <div className="row pt-3 pb-3">
                      <div className="col-md-12">
                        <div className="form-group">
                          <label className="labeltext">Select Page <span className="aesterik">*</span></label>
                          <select
                            className="form-control"
                            value={bannerPage}
                            onChange={(e) => setBannerPage(e.target.value)}
                          >
                            {/* <option>Home</option> */}
                            <option>StudentDashboard</option>
                            <option>About</option>
                            <option>Course</option>
                            <option>Blog</option>
                            <option>Contact</option>
                            <option>MyMedia</option>
                            <option>MyCourses</option>
                            <option>Blogdetail</option>
                            <option>Servicedetail</option>
                            <option>CourseDetail</option>
                            <option>Gallery</option>
                            <option>PrivacyPolicy</option>

                          </select>
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div className="form-group">
                          <label className="labeltext">
                            Banner Image
                            <span className="text-danger ml-1">
                              (Size - 1920 x 300)*
                            </span>
                          </label>
                          <div className="row">
                            <div className="col-lg-6 mt-3">
                              <input
                                type="file"
                                className="form-control"
                                // value={bannerImage}
                                accept="image/*"
                                onChange={(e) => {
                                  // setBannerImage(e.target.files[0]);
                                  const selectedBannerImage = e.target.files[0];
                                  if (selectedBannerImage) {
                                    if (selectedBannerImage.size > 5 * 1024 * 1024) {
                                      showNotificationWithMessage('Image size should be less than 5MB', 'error');
                                      e.target.value = null;
                                    } else {
                                      setBannerImage(selectedBannerImage);
                                    }
                                  }
                                }}
                              />
                            </div>
                            <div className="col-lg-6 mt-3">
                              <img src={imageURL ? imageURL : bannerImage} alt="" width={160} />
                            </div>
                          </div>


                        </div>

                      </div>
                    </div>
                  </div>
                </div>
                {/* <!-- box1 -->
                    
                      {/* <!-- action --> */}
                <div className="box-footer mb-4 text-center">
                  <button
                    type="submit"
                    className="btn btn-rounded btn_dark btn-outline btncourse"
                    onClick={updateBanner}
                    style={{ padding: '6px 22px' }}
                  >
                    {/* <i className="ti-save-alt"></i>  */}
                    Update
                  </button>
                </div>

                {/* <!-- action --> */}
              </div>
              {/* </form> */}
            </div>
          </section>
          {/* <ImageCroper
            aspectRatio={aspectRatio}
            imagetoCrop={cropingImage}
            showModal={showModal}
            setImage={setBannerImage}
            setShowModal={setShowModal}
          /> */}
          {/* <!-- form --> */}

          {/* 		
            <!-- /.content --> */}
        </div>
      </div>
      {/* <!-- /.content-wrapper --> */}
    </>
  );
};

export default UpdateBanner;
