import { Button, Checkbox } from "antd";
import axios from "axios";
import Cookies from "js-cookie";
import { useState } from "react";
import { useRef } from "react";
import { useMemo } from "react";
import { useEffect } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import useNotification from "../../useNotification"; // Import the custom hook
// import { useGlobalLoader } from "../../../Loader/GlobalLoaderContext";
import { useGlobalLoader } from "../../Loader/GlobalLoaderContext";
import SubscriptionDetail from "../../components/SubscriptionDetail";
import EditPaymentDrawer from "../../components/paymentDrawer";

const EditStudent = () => {
  const [studentSubscription, setStudentSubscription] = useState([]);

  const getStudentubSubscription = async () => {
    try {
      showLoader();
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/admin/getStudentSubscription/${params.id}`,
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("jwtoken")}`,
          },
          withCredentials: true, // Send cookies with the request
        }
      );

      if (response.data) {
        setStudentSubscription(response.data);
        // setDrawerOpen(true);
      }
    } catch (error) {
      // console.log(error);
      if (error.response.status === 404) {
        setStudentSubscription([]);
        // setDrawerOpen(true);
      }
    } finally {
      hideLoader();
    }
  };

  const { showLoader, hideLoader } = useGlobalLoader();
  const { showNotificationWithMessage } = useNotification();

  const params = useParams();
  const navigate = useNavigate();
  const [courses, setCourses] = useState([]);
  const [studentInfo, setStudentInfo] = useState({});
  const [error, setError] = useState();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [errors, setErrors] = useState({});
  const [notSubCourses, setNotSubCourses] = useState([]);

  const getAllCourses = async () => {
    const courses = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/admin/GetCourses`,
      {
        headers: {
          Authorization: `Bearer ${Cookies.get("jwtoken")}`,
        },
        withCredentials: true, // Send cookies with the request
      }
    );
    setCourses(courses.data);
  };
  const getNotSubCourses = async (id) => {
    try {
      showLoader();
      const courses = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/admin/getcourseListOfflinePayment/${id}`,
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("jwtoken")}`,
          },
          withCredentials: true, // Send cookies with the request
        }
      );
      // console.log("HERE???>>>course",courses)
      setNotSubCourses(courses.data);
      hideLoader();
    } catch (error) {
      console.log(error);
    }
  };

  const getStudentInfo = async () => {
    showLoader();
    const student = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/admin/GetStudents/${params.id}`,
      {
        headers: {
          Authorization: `Bearer ${Cookies.get("jwtoken")}`,
        },
        withCredentials: true, // Send cookies with the request
      }
    );
    setStudentInfo(student.data.studentInfo);
    hideLoader();
  };

  const handleUpdate = async (e) => {
    // e.preventDefault();

    const validationErrors = {};
    if (!studentInfo.name) {
      validationErrors.name = "Enter Student Name";
    }
    if (!studentInfo.email) {
      validationErrors.email = "Enter Student Email";
    }
    if (!studentInfo.password) {
      validationErrors.password = "Enter Student Password";
    }
    if (!studentInfo.phoneNumber) {
      validationErrors.phoneNumber = "Enter Student Phone Number";
    }
    if (!studentInfo.age) {
      validationErrors.age = "Enter Student Age";
    }
    if (!studentInfo.city) {
      validationErrors.city = "Enter Student City";
    }

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    try {
      showLoader();
      const res = await axios.put(
        `${process.env.REACT_APP_BACKEND_URL}/admin/UpdateStudent/${params.id}`,
        studentInfo,
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("jwtoken")}`,
          },
          withCredentials: true, // Send cookies with the request
        }
      );
      if (res.status == 200) {
        showNotificationWithMessage("Student Updated Successfully ", "success");
        setTimeout(() => {
          navigate("/students");
        }, 1000);
        // navigate("/Students");
      }
    } catch (error) {
      console.log(error);
    } finally {
      hideLoader();
    }
  };

  const handleChange = (name, value) => {
    setStudentInfo({ ...studentInfo, [name]: value });

    setErrors({ ...errors, [name]: "" });
  };

  const handleCourseAdd = (id, istrue) => {
    if (istrue) {
      setStudentInfo({ ...studentInfo, courses: [...studentInfo.courses, id] });
    }
  };

  const handleCourseRemove = (id, istrue) => {
    if (!istrue) {
      const newCourses = studentInfo.courses.filter((c) => c != id);
      setStudentInfo({ ...studentInfo, courses: newCourses });
    }
  };
  useEffect(() => {
    const checkToken = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/admin/protected`,
          {
            headers: {
              Authorization: `Bearer ${Cookies.get("jwtoken")}`,
            },

            withCredentials: true, //============new
          }
        );
        const data = await response.data;
        // console.log(data);
      } catch (error) {
        navigate("/");
        // alert("Unauthorized");
      }
    };

    checkToken();
  }, []);

  useEffect(() => {
    if (params.id) {
      getStudentInfo();
      getAllCourses();
      getStudentubSubscription();
    }
  }, [params]);

  useEffect(() => {
    // console.log(studentInfo);
  }, [studentInfo]);

  const scrollToError = (errors, handleUpdate) => {
    if (errors) {
      const errorField = Object.keys(errors)[0];
      const field = document.getElementsByName(errorField)[0];
      if (field) {
        field.scrollIntoView({ behavior: "smooth", block: "center" });
      }
      handleUpdate();
    } else {
      handleUpdate();
    }
  };

  return (
    <>
      <div className="content-wrapper">
        <div className="container-full">
          <div className="content-header">
            <div className="d-flex align-items-center">
              <div className="mr-auto">
                <h3 className="page-title">Update Student</h3>
                <div className="d-inline-block align-items-center">
                  <nav>
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <NavLink to="/dashboard">Dashboard</NavLink>
                      </li>
                      <li className="breadcrumb-item" aria-current="page">
                        <NavLink to="/students">Student</NavLink>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        Update Student
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
          <section className="content mt-4">
            <div className="row">
              <div className="col-lg-12 col-12 mx-auto">
                {/* <form> */}
                {/* <!-- box1 --> */}

                <div className="box">
                  <div className="box-body boxpadding">
                    <div className="row">
                      <div className="col-md-4 pt-3">
                        <div className="form-group">
                          <label className="labeltext">
                            Student Name <span className="aesterik">*</span>
                          </label>
                          <input
                            type="text"
                            name="name"
                            className="form-control"
                            placeholder="Student Name"
                            value={studentInfo.name}
                            onChange={(e) => {
                              handleChange(e.target.name, e.target.value);
                              //   setTitle(e.target.value);
                            }}
                          />
                          {errors.name && (
                            <span className="invalid-input">{errors.name}</span>
                          )}
                        </div>
                      </div>

                      <div className="col-md-4 mt-2 py-2">
                        <div className="form-group">
                          <label className="labeltext">
                            Email <span className="aesterik">*</span>
                          </label>
                          <input
                            type="text"
                            name="email"
                            className="form-control"
                            placeholder="Student Email"
                            value={studentInfo.email}
                            onChange={(e) => {
                              handleChange(e.target.name, e.target.value);
                              //   setTitle(e.target.value);
                            }}
                          />
                          {errors.email && (
                            <span className="invalid-input">
                              {errors.email}
                            </span>
                          )}
                        </div>
                      </div>

                      <div className="col-md-4 mt-2 py-2">
                        <div className="form-group">
                          <label className="labeltext">
                            Password <span className="aesterik">*</span>
                          </label>
                          <input
                            type="password"
                            name="password"
                            className="form-control"
                            placeholder="Password"
                            value={studentInfo.password}
                            onChange={(e) => {
                              handleChange(e.target.name, e.target.value);
                              //   setTitle(e.target.value);
                            }}
                          />
                          {errors.password && (
                            <span className="invalid-input">
                              {errors.password}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-md-4 mt-2">
                        <div className="form-group">
                          <label className="labeltext">
                            Phone No <span className="aesterik">*</span>
                          </label>
                          <input
                            type="number"
                            name="phoneNumber"
                            className="form-control"
                            placeholder="Phone no"
                            value={studentInfo.phoneNumber}
                            onChange={(e) => {
                              handleChange(e.target.name, e.target.value);
                              //   setTitle(e.target.value);
                            }}
                          />
                          {errors.phoneNumber && (
                            <span className="invalid-input">
                              {errors.phoneNumber}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-4 mt-2">
                        <div className="form-group">
                          <label className="labeltext">
                            Age <span className="aesterik">*</span>
                          </label>
                          <input
                            type="number"
                            name="age"
                            className="form-control"
                            placeholder="Age"
                            value={studentInfo.age}
                            onChange={(e) => {
                              handleChange(e.target.name, e.target.value);
                              //   setTitle(e.target.value);
                            }}
                          />
                          {errors.age && (
                            <span className="invalid-input">{errors.age}</span>
                          )}
                        </div>
                      </div>

                      <div className="col-md-4 mt-2">
                        <div className="form-group">
                          <label className="labeltext">
                            City <span className="aesterik">*</span>
                          </label>
                          <input
                            type="text"
                            name="city"
                            className="form-control"
                            placeholder="City"
                            value={studentInfo.city}
                            onChange={(e) => {
                              handleChange(e.target.name, e.target.value);
                              //   setTitle(e.target.value);
                            }}
                          />
                          {errors.city && (
                            <span className="invalid-input">{errors.city}</span>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="box-footer mt-5">
                      <button
                        className="btn btn-rounded btn_dark btn-outline btncourse"
                        style={{ padding: '6px 22px' }}
                        onClick={handleUpdate}
                      >
                        {/* <i className="ti-save-alt"></i> */}
                         Update
                      </button>
                    </div>
                  </div>
                </div>
                {/* <!-- box1 -->
  
  
               <!-- box2 --> */}
              </div>

              <div className="col-lg-12">
                <div className="box">
                  <div className="box-body boxpadding">
                    <div className="row">
                      <div className="col-md-12 mt-2">
                        <div className="content-header">
                          <div className="d-flex align-items-center">
                            <div className="mr-auto">
                              <h3 className="page-title">Subscription</h3>
                            </div>
                            <div className="mr-end">
                              <button
                                className="btn btn-rounded btn_dark btn-outline btncourse"
                                // style={{ padding: 10 }}
                                onClick={() => setIsDrawerOpen(true)}
                              >
                                <i className="fa fa-plus mr-10"></i> 
                                Add Subscription
                              </button>
                            </div>
                          </div>
                        </div>
                        <div
                        // style={{
                        //   display: "flex",
                        //   flexDirection: "column",
                        //   // gap: "0.7rem",
                        // }}
                        >
                          {/* {courses.map((course) => {
                              if (studentInfo?.courses?.includes(course._id)) {
                                return (
                                  <>
                                    <Checkbox
                                      checked
                                      onChange={(e) =>
                                        handleCourseRemove(
                                          course._id,
                                          e.target.checked
                                        )
                                      }
                                    >
                                      {" "}
                                      {course.Name}
                                    </Checkbox>
                                  </>
                                );
                              } else {
                                return (
                                  <>
                                    <Checkbox
                                      onChange={(e) =>
                                        handleCourseAdd(
                                          course._id,
                                          e.target.checked
                                        )
                                      }
                                    >
                                      {" "}
                                      {course.Name}
                                    </Checkbox>
                                  </>
                                );
                              }
                            })} */}

                          <div className="mt-4">
                            <SubscriptionDetail
                              subscription={studentSubscription}
                              studentInfo={studentInfo}
                              getStudentSubscription={getStudentubSubscription}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <!-- box4 --> */}

              {/* <!-- action --> */}
              {/* <div className="box-footer mb-4">
                  <button
                    className="btn btn-rounded btn_dark btn-outline btncourse"
                    style={{ padding: 10 }}
                    // onClick={handleUpdate}
                    onClick={e => scrollToError(errors, handleUpdate)}
                  >
                    <i className="ti-save-alt"></i> Update
                  </button>
                </div> */}

              {/* <!-- action --> */}

              {/* </form> */}
            </div>

            {/* </div> */}
          </section>
          <EditPaymentDrawer
            getNotSubCourses={getNotSubCourses}
            setIsDrawerOpen={setIsDrawerOpen}
            studentInfo={studentInfo}
            isDrawerOpen={isDrawerOpen}
            courses={notSubCourses}
            getStudentubSubscription={getStudentubSubscription}
          />
          {/* <!-- form --> */}

          {/* 		
          <!-- /.content --> */}
        </div>
      </div>
      {/* <!-- /.content-wrapper --> */}
    </>
  );
};

export default EditStudent;
