// import React from 'react'

// import Media from './Media'

// const MediaDocs = () => {
//     return (
//         <>
//             <Media mediaType={"document"} />

//         </>
//     )
// }

// export default MediaDocs

import { notification } from "antd";
import axios from "axios";
import Cookies from "js-cookie";
import React, { useEffect, useRef, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import AddMedia from "./AddMediaImage";
import useNotification from "../../../useNotification"; // Import the custom hook
import Swal from "sweetalert2";
import { useGlobalLoader } from "../../../Loader/GlobalLoaderContext";
import { Button, Input, Space, Table } from "antd";
import Highlighter from "react-highlight-words";
import { SearchOutlined } from "@ant-design/icons";



const MediaDocs = () => {
  const navigate = useNavigate();
  useEffect(() => {
    const checkToken = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/admin/protected`,
          {
            headers: {
              Authorization: `Bearer ${Cookies.get("jwtoken")}`,
            },

            withCredentials: true, //============new
          }
        );
        const data = await response.data;
        // console.log(data);
      } catch (error) {
        navigate("/");
        // alert("Unauthorized");
      }
    };

    checkToken();
  }, []);

  const { showLoader, hideLoader } = useGlobalLoader();
  const [media, setMedia] = useState([]);
  const [mediaType, setMediaType] = useState("document");

  const { showNotificationWithMessage } = useNotification();
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  // const [ setMediaType] = useState();

  const [columns, setColumns] = useState([
    { title: "Sr No.", dataIndex: "index", key: "index", className: "srno" },
    {
      title: "Media title",
      dataIndex: "title",
      key: "title",
      ...getColumnSearchProps("title"),
    },
    { title: "Category", dataIndex: "category", key: "category", ...getColumnSearchProps("category"), },

    { title: "Media Type", dataIndex: "mediaType", key: "mediaType" },

    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      //   render: () => <a>Delete</a>,
    },
  ]);
  const [data, setData] = useState([]);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  useEffect(() => {
    getMedia();
  }, [mediaType]);

  // console.log(mediaType);

  const getMedia = async () => {
    try {
      showLoader();
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/admin/GetMediaByType/${mediaType}`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("jwtoken")}`,
          },

          withCredentials: true, // Send cookies with the request
        }
      );
      const data = await response.data;
      let setMedia = data.map((item, index) => {
        // console.log("item", item);
        return {
          index: index + 1,
          title: item.mediaTitle,
          category: item.mediaSelectCourse ? item.mediaSelectCourse : "All",
          mediaType: "Document",
          action: (
            <div className="btn-group">
              <NavLink
                to={"/update-media/" + item._id}
                className="btn-circle btn-info btn-xs mr-10 pl-2"
                data-toggle="tooltip"
                data-original-title="Edit"
              >
                <i class="ti-marker-alt"></i>
              </NavLink>
              <button
                type="button"
                className="btn-circle btn-danger btn-xs"
                onClick={() => {
                  deleteMedia(item._id);
                }}
              >
                <i className="fa fa-trash"></i>
              </button>
            </div>
          ),
        };
      });
      setData(setMedia);
      if (!response.status === 200) {
        const error = new Error(response.error);
        throw error;
      }
    } catch (error) {
      console.error("Error fetching media:", error);
    } finally {
      hideLoader();
    }
  };

  //delete media
  const deleteMedia = async (id) => {
    try {
      // Show the confirmation popup
      const confirmed = await Swal.fire({
        title: "Are you sure?",
        text: "Are you sure you want to delete this Media?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      });

      if (!confirmed.isConfirmed) {
        return; // If user cancels, do nothing
      }

      showLoader();
      const response = await axios.delete(
        `${process.env.REACT_APP_BACKEND_URL}/admin/DeleteMedia/${id}`,
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("jwtoken")}`,
          },
          withCredentials: true, // Send cookies with the request
        }
      );

      if (response.data) {
        getMedia();
        showNotificationWithMessage("Media is deleted", "success");
      }
    } catch (error) {
      showNotificationWithMessage("Something went wrong", "error");
    } finally {
      hideLoader();
    }
  };

  return (
    <>
      <div className="content-wrapper">
        <div className="container-full">
          {/* <!-- Content Header (Page header) --> */}
          {/* <div className="content-header">
            <div className="d-flex align-items-center">
              <div className="mr-auto">
                <h3 className="page-title">Document List</h3>
                <nav>
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                      <NavLink to="/dashboard">Dashboard</NavLink>
                    </li>
                    <li class="breadcrumb-item" aria-current="page">
                      <NavLink to="/documents">Media</NavLink>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                      Document List
                    </li>
                  </ol>
                </nav>
              </div>
              <div className="mr-end">
                <NavLink
                  className="waves-effect waves-light btn-block btn btn_dark addcourse"
                  to="/add-document"
                >
                  <i className="fa fa-plus mr-15"></i>Add Document
                </NavLink>
              </div>
            </div>
          </div> */}

          {/* <!-- Main content --> */}
          <section className="content">
            <div className="row">
              <div class="col-12" style={{ marginBottom: 20 }}>
                <div className="content-header pagetitle-pl">
                  <div className="d-flex align-items-center">
                    <div className="mr-auto">
                      <h3 className="page-title">Document List</h3>
                      <nav>
                        <ol class="breadcrumb">
                          <li class="breadcrumb-item">
                            <NavLink to="/dashboard">Dashboard</NavLink>
                          </li>
                          <li class="breadcrumb-item" aria-current="page">
                            <NavLink to="/documents">Media</NavLink>
                          </li>
                          <li class="breadcrumb-item active" aria-current="page">
                            Document List
                          </li>
                        </ol>
                      </nav>
                    </div>
                    <div className="mr-end">
                      <NavLink
                        className="waves-effect waves-light btn-block btn btn_dark addcourse"
                        to="/add-document"
                      >
                        <i className="fa fa-plus mr-10"></i>
                        Add Document
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-10 mx-auto">
                <div className="box" style={{ marginTop: 50 }}>
                  <div className="box-body">
                    <div className="table-responsive">
                      <Table
                        className="customTable"
                        columns={columns}
                        dataSource={data}
                        pagination={{ pageSize: 5 }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* <!-- /.content --> */}
        </div>
      </div>
    </>
  );
};

export default MediaDocs;
