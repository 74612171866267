import { apiGet, apiPost, apiPut } from "./Api";
import Localstorage from "./storage/Localstorage";

const baseURL = `${process.env.REACT_APP_BACKEND_URL}`

export const studentLoginURL  = baseURL+"/studentlogin";

export const getStudentInfo=(id) =>  baseURL+`/getstudentinfo/${id}`;
export const editStudentProfile = baseURL+`/editstudentprofile/${localStorage.getItem('userId')}`;


export const routes = {
    BASE_PATH: `${process.env.REACT_APP_BACKEND_URL}`,
    APIS:{
        studentLogin:async(data)=>{
            return await apiPost("/studentlogin",routes.BASE_PATH,data)
        },
        getStudentInfo:async(id)=>{
            return await apiGet(`/getstudentinfo/${id}`,routes.BASE_PATH)
        },
        editStudentProfile:async(data)=>{
            return await apiPost(`/editstudentprofile/${Localstorage.USER_ID.get()}`,routes.BASE_PATH,data)
        },
        getStudentMedia:async()=>{
            return await apiGet(`/web/GetMediaWebsite`,routes.BASE_PATH)
        },
        searchCourse:async(data)=>{
            return await apiGet(`/web/SearchCoursesWebsite/search/${data}`,routes.BASE_PATH)
        },
        getCoursesByCategory:async(category)=>{
            return apiGet(`/web/GetCoursesWebsite/${category}`,routes.BASE_PATH)
        },
        getStudentCourses:async()=>{
            return apiGet(`/getStudentCourses/${Localstorage.USER_ID.get()}`,routes.BASE_PATH)
        },
        getMediaByCourseId:async(id)=>{
            return apiGet(`/getMediaByCourseId/${id}`,routes.BASE_PATH)
        }, 
        editStudentProfile:async(data)=>{
            return await apiPut(`/editstudentprofile/${Localstorage.USER_ID.get()}`,routes.BASE_PATH,data)
        },
       
    }


}

