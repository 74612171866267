import React, { useEffect } from 'react'
import Header from './Include/Header';
import Sidebar from './Include/Sidebar';
import { Outlet, Navigate } from 'react-router-dom'
import { useLocation } from 'react-router-dom'; // Assuming you're using React Router
import Footer from './Include/Footer';

const AdminMain = () => {

  const location = useLocation();
  useEffect(() => {
    // console.log(location.pathname);
    const section = document.getElementById('mySection');
    if (section) {
      section.scrollIntoView({ behavior: 'auto', block: 'start' });
    }
  }, [window.location.pathname]);

  return (
    <>
      <body className="light-skin sidebar-mini  fixed adminanimation">

          <div className="wrapper" style={{ overflow: 'auto' }}>
        <section id="mySection">
       
            <Header />
            <Sidebar />
            <Outlet />
            </section >
            <Footer></Footer>
          </div>

      </body>




    </>
  )
}

export default AdminMain