import { Button, Input, Modal, Icon } from "antd";
import { DeleteOutlined } from "@ant-design/icons"; // Import the delete icon
import { useEffect, useState } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { useGlobalLoader } from "../../../Loader/GlobalLoaderContext";
import useNotification from "../../../useNotification";


export default function AddCategory({
  showModal,
  setShowModal,
  imagetoCrop,
  getCategories,
  editCategoryData,
  setSelectedCategory,
  setCat
}) {
  const [category, setCategory] = useState("");
  const [subcategories, setSubcategories] = useState([]);
  const { showNotificationWithMessage } = useNotification();

  const { showLoader, hideLoader } = useGlobalLoader();


  useEffect(() => {
    if (editCategoryData) {
      setCategory(editCategoryData.category);
      setSubcategories(editCategoryData.subcategories || []);
    } else {
      setCategory("");
      setSubcategories([]);
    }
  }, [editCategoryData]);

  const addSubcategory = () => {
    setSubcategories([...subcategories, ""]);
  };

  const removeSubcategory = (index) => {
    setSubcategories(subcategories.filter((_, idx) => idx !== index));
  };

  
  const handleOkClick = async () => {
    // Structure the data
    // let newSubCategories = setCategory.map((sub)=> sub)
    const categoryData = {
      category,
      subcategories: subcategories,
    };
    // console.log(categoryData)
    // return
    try {
      showLoader();
      if (editCategoryData) {
        const response = await axios.put(
          `${process.env.REACT_APP_BACKEND_URL}/admin/editCategory/${editCategoryData._id}`,
          categoryData,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${Cookies.get("jwtoken")}`,
            },
            withCredentials: true,
          }
        );
        await getCategories();
        setShowModal(false);
        setSelectedCategory("sub_category", "");
        setSelectedCategory("category", response.data.cat._id);
        setCat(response.data.cat._id);

      } else {
        const response = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/admin/addCategory`,
          categoryData,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${Cookies.get("jwtoken")}`,
            },
            withCredentials: true, // Send cookies with the request
          }
        );
        getCategories();
        setShowModal(false)
        // console.log("Category added:", response.data);
      }
    } catch (error) {
      // console.log("error", error)
      // console.log("error.response", error.response)
      if (error.response.status === 400) {
        showNotificationWithMessage(error.response.data.message, "error");
      }
      // showNotificationWithMessage("Error adding category", "error");
      console.error("Error adding category:", error);
    }
    finally {
      hideLoader();
    }
  }


  useEffect(() => { }, [imagetoCrop]);

  return (
    <Modal
      title={editCategoryData ? "Edit category" : "Add category"}
      visible={showModal}
      onOk={handleOkClick}
      onCancel={() => setShowModal(false)}
    >
      <div>
        <Input
          placeholder="Category Name"
          value={category}
          onChange={(e) => setCategory(e.target.value)}
          maxLength={30}
        />
        {subcategories.map((subcategory, index) => (
          <div
            key={index}
            style={{ marginTop: 10, display: "flex", alignItems: "center" }}
          >
            <Input
              style={{ marginRight: 10 }}
              placeholder={`Subcategory ${index + 1}`}
              maxLength={30}
              value={subcategory}
              onChange={(e) => {
                const newSubcategories = [...subcategories];
                newSubcategories[index] = e.target.value;
                setSubcategories(newSubcategories);
              }}
            />
            <DeleteOutlined onClick={() => removeSubcategory(index)} />
          </div>
        ))}

        <Button
          onClick={addSubcategory}
          type="primary"
          style={{ marginTop: 25 }}
          className="addsubbtn"
        >
          Add Subcategory
        </Button>
      </div>
    </Modal>
  );
}
