import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { notification } from "antd";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import useNotification from '../../../useNotification'; // Import the custom hook
import { useGlobalLoader } from "../../../Loader/GlobalLoaderContext";
import { useEffect } from "react";

const AddTestimonial = () => {

    useEffect(() => {
        const checkToken = async () => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_BACKEND_URL}/admin/protected`,
                    {
                        headers: {
                            Authorization: `Bearer ${Cookies.get("jwtoken")}`,
                        },

                        withCredentials: true, //============new
                    }
                );
                const data = await response.data;
                // console.log(data);
            } catch (error) {
                navigate("/");
                // alert("Unauthorized");
            }
        };

        checkToken();
    }, []);

    const { showLoader, hideLoader } = useGlobalLoader();
    const { showNotificationWithMessage } = useNotification();

    const [testiTitle, setTestiTitle] = useState("");
    const [testiInfo, setTestiInfo] = useState("");
    const [testiName, setTestiName] = useState("");
    const [testiProfession, setTestiProfession] = useState("");
    const [testiImage, setTestiImage] = useState("");

    const [errors, setErrors] = useState({});

    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        // e.preventDefault();

        try {
            showLoader();
            // if (!testiTitle || !testiInfo || !testiName || !testiProfession || !testiImage) {
            //     showNotificationWithMessage('Please fill all field', 'error');
            //     return;
            // }
            const formErrors = {}; // Object to store field-specific errors

            // Validate each field
            if (!testiTitle) {
                formErrors.testiTitle = "Testimonial Title is required";
            }

            if (!testiInfo) {
                formErrors.testiInfo = "Testimonial Review is required";
            }

            if (!testiName) {
                formErrors.testiName = "Testimonial Author Name is required";
            }

            if (!testiProfession) {
                formErrors.testiProfession = "Testimonial Author Profession is required";
            }

            if (!testiImage) {
                formErrors.testiImage = "Testimonial Image is required";
            }

            setErrors(formErrors); // Update validation errors

            if (Object.keys(formErrors).length > 0) {
                // If there are validation errors, don't proceed with the submission
                hideLoader();
                return;
            }



            const formData = new FormData();
            formData.append("testiTitle", testiTitle);
            formData.append("testiInfo", testiInfo);
            formData.append("testiName", testiName);
            formData.append("testiProfession", testiProfession);
            formData.append("testiImage", testiImage);

            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/admin/AddTestimonial`, formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        Authorization: `Bearer ${Cookies.get("jwtoken")}`,
                    },
                    withCredentials: true,
                }
            );
            if (response.status === 200) {
                // notification.success({
                //     message: "Testimonial Added Successfully",
                // });
                // navigate("/ViewTestimonial");

                showNotificationWithMessage('Testimonial Added Successfully', 'success');
                setTimeout(() => {
                    navigate('/testimonial');
                }, 1000);
            }

        } catch (error) {
            console.error("Error adding Testimonial:", error);
            // notification.error({
            //     message: "Error adding Testimonial ",
            // });
            showNotificationWithMessage('Error adding Testimonial', 'error');

        }
        finally {
            hideLoader();
        }

    };

    const scrollToError = (errors, handleSubmit) => {
        if (errors) {
            const errorField = Object.keys(errors)[0];
            const field = document.getElementsByName(errorField)[0];
            if (field) {
                field.scrollIntoView({ behavior: "smooth", block: "center" });
            }
            handleSubmit();
        } else {
            handleSubmit();
        }
    }

    return (
        <>
            <div className="content-wrapper">
                <div
                    className="container-full"

                >
                    <div className="content-header">
                        <div className="d-flex align-items-center">
                            <div className="mr-auto">
                                <h3 className="page-title">Add Testimonial</h3>
                                <div className="d-inline-block align-items-center">
                                    <nav>
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item"><NavLink to="/dashboard">Dashboard</NavLink></li>
                                            <li className="breadcrumb-item" aria-current="page"><NavLink to="/Testimonial">Testimonial</NavLink></li>
                                            <li className="breadcrumb-item active" aria-current="page">Add Testimonial</li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>

                        </div>
                    </div>
                    <section className="content mt-5">
                        <div className="row">

                            {/* <form
                                onSubmit={handleSubmit}
                            > */}
                            <div className="col-lg-12 col-12 mx-auto">
                                {/* <!-- box1 --> */}

                                <div className="box">

                                    <div className="box-body boxpadding pt-4">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label className="labeltext">Title  <span className="aesterik">*</span></label>
                                                    <input
                                                        type="text"
                                                        name="testiTitle"
                                                        // className="form-control"
                                                        className={`form-control ${errors.testiTitle ? "is-invalid" : ""}`}
                                                        placeholder="Testimonial Title"
                                                        value={testiTitle}
                                                        onChange={(e) => {
                                                            setTestiTitle(e.target.value);
                                                            setErrors({ ...errors, testiTitle: "" });
                                                        }}
                                                    />
                                                    {errors.testiTitle && (
                                                        <span className="invalid-input">{errors.testiTitle}</span>
                                                    )}
                                                </div>
                                            </div>


                                            <div className="col-md-4 mt-2">
                                                <div className="form-group">
                                                    <label className="labeltext">Author Name  <span className="aesterik">*</span></label>
                                                    <input
                                                        type="text"
                                                        name="testiName"
                                                        // className="form-control"
                                                        className={`form-control ${errors.testiName ? "is-invalid" : ""}`}
                                                        placeholder="Testimonial Author"
                                                        value={testiName}
                                                        onChange={(e) => {
                                                            setTestiName(e.target.value);
                                                            setErrors({ ...errors, testiName: "" });
                                                        }}
                                                    />
                                                    {errors.testiName && (
                                                        <span className="invalid-input">{errors.testiName}</span>
                                                    )}
                                                </div>
                                            </div>

                                            <div className="col-md-4 mt-2">
                                                <div className="form-group">
                                                    <label className="labeltext">Author Profession  <span className="aesterik">*</span></label>
                                                    <input
                                                        type="text"
                                                        name="testiProfession"
                                                        // className="form-control"
                                                        className={`form-control ${errors.testiProfession ? "is-invalid" : ""}`}
                                                        placeholder="Testimonial Author Profession"
                                                        value={testiProfession}
                                                        onChange={(e) => {
                                                            setTestiProfession(e.target.value);
                                                            setErrors({ ...errors, testiProfession: "" });
                                                        }}
                                                    />
                                                    {errors.testiProfession && (
                                                        <span className="invalid-input">{errors.testiProfession}</span>
                                                    )}
                                                </div>
                                            </div>

                                            <div className="col-md-4 mt-2">
                                                <div className="form-group">
                                                    <label className="labeltext"> Image  <span className="aesterik">*</span></label>
                                                    <input
                                                        type="file"
                                                        // name="testiImage"
                                                        // className="form-control"
                                                        className={`form-control ${errors.testiImage ? "is-invalid" : ""}`}
                                                        onChange={(e) => {
                                                            // setTestiImage(e.target.files[0]);
                                                            // setErrors({ ...errors, testiImage: "" });
                                                            const selectedtestiImage = e.target.files[0];
                                                            if (selectedtestiImage) {
                                                                if (selectedtestiImage.size > 5 * 1024 * 1024) {
                                                                    showNotificationWithMessage('Image size should be less than 5MB', 'error');
                                                                    e.target.value = null;
                                                                } else {
                                                                    setTestiImage(selectedtestiImage);
                                                                    setErrors({ ...errors, testiImage: "" });
                                                                }
                                                            }
                                                        }}
                                                    />
                                                    {errors.testiImage && (
                                                        <span className="invalid-input">{errors.testiImage}</span>
                                                    )}
                                                </div>
                                            </div>

                                            <div className="col-md-12 mt-2">
                                                <div className="form-group">
                                                    <label className="labeltext">Review  <span className="aesterik">*</span></label>
                                                    <textarea
                                                        rows="2"
                                                        name="testiInfo"
                                                        // className="form-control"
                                                        className={`form-control ${errors.testiInfo ? "is-invalid" : ""}`}
                                                        placeholder="Review"
                                                        value={testiInfo}
                                                        onChange={(e) => {
                                                            setTestiInfo(e.target.value);
                                                            setErrors({ ...errors, testiInfo: "" });
                                                        }}
                                                    ></textarea>
                                                    {errors.testiInfo && (
                                                        <span className="invalid-input">{errors.testiInfo}</span>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- box1 --> */}


                                {/* // <!-- box2 -->  */}


                                {/* <!-- box4 --> */}





                                {/* <!-- action --> */}
                                <div className="box-footer mb-4 text-center">
                                    <button
                                        type="submit"
                                        className="btn btn-rounded btn_dark btn-outline btncourse"
                                        style={{ padding: '6px 22px' }}
                                        // onClick={handleSubmit}
                                        onClick={e => scrollToError(errors, handleSubmit)}
                                    >
                                        {/* <i className="ti-save-alt"></i>  */}
                                        Save
                                    </button>
                                </div>

                                {/* <!-- action --> */}
                            </div>
                            {/* </form> */}
                        </div>
                    </section>
                </div>

                {/* <!-- form --> */}

                {/* 		
      <!-- /.content --> */}
            </div >


        </>

    )
};
export default AddTestimonial;
