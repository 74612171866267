import React from 'react';
import { useNavigate } from 'react-router-dom';
import { AppstoreOutlined, CalendarOutlined, LinkOutlined, MailOutlined, SettingOutlined } from '@ant-design/icons';
import { Divider, Menu } from 'antd';

import styles from './UIManagement.module.css'

const { SubMenu } = Menu;

function getItem(label, key, icon, onClick, children) {
    return {
        key,
        icon,
        onClick,
        children,
        label,
    };
}

const items = [
    getItem(<div className='sidebartext'><i className="icon-Write icolor iconpl"><span class="path1"></span><span class="path2"></span></i><span className='fontcolor'>UI Management</span></div>, 'header', null, null, [
        getItem('Banner', 'ViewBanner', <i className="icon-Commit"><span className="path1"></span><span className="path2"></span></i>, '/banner'),
        getItem('Blog', 'ViewBlogList', <i className="icon-Commit"><span className="path1"></span><span className="path2"></span></i>, '/blog'),
        getItem('Testimonial', 'ViewTestimonial', <i className="icon-Commit"><span className="path1"></span><span className="path2"></span></i>, '/testimonial'),
        getItem('Services', 'ViewServicesList', <i className="icon-Commit"><span className="path1"></span><span className="path2"></span></i>, '/service'),
        getItem('Service Image', 'ViewServiceImg', <i className="icon-Commit"><span className="path1"></span><span className="path2"></span></i>, '/service-content'),
        getItem('Gallery', 'ViewGallery', <i className="icon-Commit"><span className="path1"></span><span className="path2"></span></i>, '/gallery'),
        getItem('Privacy & Policy', 'ViewPrivacyPolicy', <i className="icon-Commit"><span className="path1"></span><span className="path2"></span></i>, '/privacy-policy'),
        getItem('PopUp Modal', 'PopUpModal', <i className="icon-Commit"><span className="path1"></span><span className="path2"></span></i>, '/popUp-modal'),
    ]),
];

const UIManagement = () => {
    const navigate = useNavigate();

    return (
        <>
            <Menu
                style={{ width: "100%", padding: 0 }}
                defaultSelectedKeys={['header']}
                defaultOpenKeys={['uiManagement']}
                mode="inline"
                // theme="light"
                className={styles.header}
            >
                {items.map((item) => {
                    if (item.children) {
                        return (
                            <SubMenu key={item.key} icon={item.icon} style={{ padding: 0 }} title={item.label} className={styles.submenu}>
                                {item.children.map((child) => (
                                    <Menu.Item key={child.key} icon={child.icon} onClick={() => navigate(child.onClick)}>
                                        {child.label}
                                    </Menu.Item>
                                ))}
                            </SubMenu>
                        );
                    } else {
                        return (
                            <Menu.Item key={item.key} icon={item.icon} onClick={() => navigate(item.onClick)}>
                                {item.label}
                            </Menu.Item>
                        );
                    }
                })}
            </Menu>
        </>
    );
};

export default UIManagement;
