import styles from "./styles.module.css";
import {
  Drawer,
  Input,
  DatePicker,
  Select,
  Tooltip,
  Button,
  Checkbox,
} from "antd";
import {
  SaveOutlined,
  DollarOutlined,
  WalletOutlined,
  CalendarOutlined,
  CalculatorOutlined,
  BookOutlined,
} from "@ant-design/icons";
import { useState } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import useNotification from "../../useNotification";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Form } from "antd";
import { useEffect } from "react";
export default function EditPaymentDrawer({
  setIsDrawerOpen,
  isDrawerOpen,
  courses,
  studentInfo,
  getStudentubSubscription,
  getNotSubCourses
}) {
  const [batchSelected, setBatchSelected] = useState(null);
  const [courseSelected, setCourseSelected] = useState(null);
  const [dateRange, setDateRange] = useState([]);
  const [coursePrice, setCoursePrice] = useState("");
  const [amountPaid, setAmountPaid] = useState("");
  const [balance, setBalance] = useState(0);
  const [nextInstallment, setNextInstallment] = useState(null);
  const [remindByEmail, setRemindByEmail] = useState(false);
  const [nextInstallmentDate, setNextInstallmentDate] = useState(null);

  const [batches, setBatches] = useState([]);


  const { showNotificationWithMessage } = useNotification();

  const calculateBalance = (amount, coursePrice) => {
    const courseCost = parseFloat(coursePrice) || 0;
    const amountGiven = parseFloat(amount) || 0;
    const calculatedBalance = courseCost - amountGiven;
    setBalance(calculatedBalance);
  };
  const validationSchema = Yup.object({
    courseSelected: Yup.string().required("Course is required"),
    dateRange: Yup.array()
      // .of(Yup.date().min(new Date()))
      .required("Date range is required")
      .test(
        "length",
        "Both start and end dates are required",
        (val) => val && val.length === 2
      ),
    coursePrice: Yup.number().min(1).required("Course price is required"),
    amountPaid: Yup.number().min(0).required("Amount paid is required"),
    batchSelected: Yup.string().required("Batch is required"),
  });


  const handleSaveSubscription = async () => {
    const subscriptionData = {
      userId: studentInfo._id,
      courseId: formik.values.courseSelected,
      price: formik.values.coursePrice,
      amountPaid: formik.values.amountPaid,
      startDate: formik.values.dateRange[0],
      endDate: formik.values.dateRange[1],
      batch: formik.values.batchSelected,
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/admin/offline-payment-handler`,
        subscriptionData,
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("jwtoken")}`,
          },
          withCredentials: true,
        }
      );
      if (response.status === 200) {
        showNotificationWithMessage(
          "Offline Payment Successfully Added",
          "success"
        );
        setIsDrawerOpen(false); // Close the drawer
        getStudentubSubscription(); // Refresh the subscriptions
        formik.resetForm(); 
      }
    } catch (error) {
      showNotificationWithMessage(
        "Failed to add offline payment. Please try again.",
        "error"
      );
    }
  };

  const formik = useFormik({
    initialValues: {
      courseSelected: "",
      dateRange: [],
      coursePrice: "",
      amountPaid: "",
      nextInstallment: null,
      remindByEmail: false,
      batchSelected: "",
    },
    validationSchema: validationSchema,
    onSubmit: handleSaveSubscription,
  });
  {
    console.log(formik.values, formik.errors);
  }

  useEffect(() => {
    // console.log("HERE>>>????MKGKADYUJFcsvbn", studentInfo)
    getNotSubCourses(studentInfo._id)
  }, [])

  const handleCourseChange = (courseId) => {
    setCourseSelected(courseId);

    // Use the courses prop to find the selected course
    const selectedCourse = courses.find((course) => course._id === courseId);

    if (selectedCourse) {
      // Extract batches from the selected course and set the batches state
      setBatches(selectedCourse.batchIDs);
    }
  };



  return (
    <Drawer
      title="Add New Subscription"
      width={500}
      onClose={() => setIsDrawerOpen(false)}
      visible={isDrawerOpen}
      bodyStyle={{ paddingBottom: 80 }}
      className={styles.drawer}

    >
      <form onSubmit={formik.handleSubmit}>
        <div className={styles.formItem}>
          <Form.Item
            validateStatus={
              formik.touched.courseSelected && formik.errors.courseSelected
                ? "error"
                : ""
            }
            help={
              formik.touched.courseSelected ? formik.errors.courseSelected : ""
            }
          >
            <label className="labeltext">Course <span className="aesterik">*</span></label>
            <Select
              placeholder="Select a course"
              style={{ width: "100%" }}
              value={formik.values.courseSelected}
              // onChange={(e) => formik.setFieldValue("courseSelected", e)}
              onChange={(e) => {
                formik.setFieldValue("courseSelected", e);
                handleCourseChange(e); // Call handleCourseChange when the course changes
              }}
              // suffixIcon={<BookOutlined />}
              className={styles.selectInput}
            >
              {courses.map((course) => (
                <Select.Option key={course._id} value={course._id}>
                  {course.Name}
                </Select.Option>
              ))}
            </Select>

          </Form.Item>
        </div>

        {/* batch */}

        <div className={styles.formItem}>
          {/* <Form.Item
            validateStatus={
              formik.touched.courseSelected && formik.errors.courseSelected
                ? "error"
                : ""
            }
            help={
              formik.touched.courseSelected ? formik.errors.courseSelected : ""
            }
          >
            <label className="labeltext">Batch<span className="aesterik">*</span></label>
            <Select
              placeholder="Select a Batch"
              style={{ width: "100%" }}
              // value={formik.values.courseSelected}
              // onChange={(e) => formik.setFieldValue("courseSelected", e)}
              // suffixIcon={<BookOutlined />}
              className={styles.selectInput}
            >
              <Select.Option >
                Batch One
              </Select.Option>
              <Select.Option >
                Batch Two
              </Select.Option>
              <Select.Option >
                Batch Three
              </Select.Option>
              <Select.Option >
                Batch Four
              </Select.Option>
              <Select.Option >
                Batch Five
              </Select.Option>

            </Select>

          </Form.Item> */}

          <Form.Item
            validateStatus={
              formik.touched.batchSelected && formik.errors.batchSelected
                ? "error"
                : ""
            }
            help={
              formik.touched.batchSelected ? formik.errors.batchSelected : ""
            }

          >
            <label className="labeltext">Batch <span className="aesterik">*</span>
              {/* <span className="aesterik">*</span> */}
            </label>
            <Select
              placeholder="Select a Batch"
              style={{ width: "100%" }}
              value={formik.values.batchSelected}
              onChange={(e) => formik.setFieldValue("batchSelected", e)}
              className={styles.selectInput}
            >
              {courseSelected
                ? courses
                  .find((course) => course._id === courseSelected)
                  .batchIDs.map((batch) => (
                    <Select.Option key={batch._id} value={batch._id}>
                      {batch.batchName}
                    </Select.Option>
                  ))
                : null}
            </Select>
          </Form.Item>
        </div>

        {/* batch */}

        <div className={styles.formItem}>
          <Form.Item
            validateStatus={
              formik.touched.dateRange && formik.errors.dateRange ? "error" : ""
            }
            help={formik.touched.dateRange ? formik.errors.dateRange : ""}
          >
            <label className="labeltext">Date<span className="aesterik">*</span></label>
            <DatePicker.RangePicker
              style={{ width: "100%" }}
              value={formik.values.dateRange}
              onChange={(dates, datestrings) => {
                // console.log(datestrings);
                setDateRange(datestrings);
                formik.setFieldValue("dateRange", dates); // Setting the date in formik state
              }}
              suffixIcon={<CalendarOutlined />}
              className={styles.datePicker}
            />
          </Form.Item>
        </div>

        <div className={styles.formItem}>
          <Form.Item
            validateStatus={
              formik.touched.coursePrice && formik.errors.coursePrice
                ? "error"
                : ""
            }
            help={formik.touched.coursePrice ? formik.errors.coursePrice : ""}
          >
            <label className="labeltext">Price<span className="aesterik">*</span></label>
            <Input
              placeholder="Price of Course"
              type="number"
              style={{ width: "100%" }}
              prefix={<DollarOutlined />}
              className={styles.input}
              value={formik.values.coursePrice}
              onChange={(e) =>
                formik.setFieldValue("coursePrice", e.target.value)
              }
            />
          </Form.Item>
        </div>

        <div className={styles.formItem}>
          <Form.Item
            validateStatus={
              formik.touched.amountPaid && formik.errors.amountPaid
                ? "error"
                : ""
            }
            help={formik.touched.amountPaid ? formik.errors.amountPaid : ""}
          >
            <label className="labeltext">Paid Amount <span className="aesterik">*</span></label>
            <Input
              placeholder="Amount Paid"
              type="number"
              style={{ width: "100%" }}
              prefix={<WalletOutlined />}
              className={styles.input}
              value={formik.values.amountPaid}
              onChange={(e) => {
                formik.setFieldValue("amountPaid", e.target.value);
                calculateBalance(e.target.value, formik.values.coursePrice);
              }}
            />

          </Form.Item>
        </div>

        <div className={styles.formItem}>

          <Tooltip
            title="Balance is auto-calculated"
            className={styles.tooltip}
          >
            <label className="labeltext">Balanced Amount <span className="aesterik">*</span></label>
            <Input
              placeholder="Balance"
              disabled
              prefix={<CalculatorOutlined />}
              className={styles.balanceInput}
              value={balance}
            />
          </Tooltip>
          {/* </Form.Item> */}
        </div>
        {balance > 0 && (
          <div className={styles.formItem}>
            <Form.Item
              validateStatus={
                formik.touched.courseSelected && formik.errors.courseSelected
                  ? "error"
                  : ""
              }
              help={
                formik.touched.courseSelected
                  ? formik.errors.courseSelected
                  : ""
              }
            >
              <label className="labeltext">Next Installment Date <span className="aesterik">*</span></label>
              <DatePicker
                style={{ width: "60%" }}
                placeholder="Next Installment Date"
                value={nextInstallmentDate}
                onChange={(date) => setNextInstallmentDate(date)}
                className={styles.datePicker}
              />
              {/* <Checkbox
                checked={remindByEmail}
                onChange={(e) => setRemindByEmail(e.target.checked)}
                style={{ marginLeft: "10px" }}
              >
                Remind by Email
              </Checkbox> */}
            </Form.Item>
          </div>
        )}





        <div className={styles.formItem}>
          <div className="text-center mt-5">
            <Button
              type="primary"
              htmlType="submit"
              className="btn btn-rounded btn-outline btn_subscription"
            // className={styles.saveButton}
            >
              Save Subscription
            </Button>
          </div>
        </div>
      </form>
    </Drawer>
  );
}
