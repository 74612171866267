import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { NavLink, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import styles from "./styles.module.css";
import { Button, Input, Space, Table, Checkbox, notification } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";

import useNotification from "../../../useNotification"; // Import the custom hook
import Swal from "sweetalert2";
import { useGlobalLoader } from "../../../Loader/GlobalLoaderContext";
import { useDownloadExcel } from "react-export-table-to-excel";
import { useReactToPrint } from "react-to-print";
import debounce from "../../../Debounce";
import jsPDF from "jspdf";
import "jspdf-autotable";
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';

const ServeyEnquires = () => {
  const navigate = useNavigate();
  useEffect(() => {
    const checkToken = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/admin/protected`,
          {
            headers: {
              Authorization: `Bearer ${Cookies.get("jwtoken")}`,
            },

            withCredentials: true, //============new
          }
        );
        const data = await response.data;
        // console.log(data);
      } catch (error) {
        navigate("/");
        // alert("Unauthorized");
      }
    };

    checkToken();
  }, []);

  const { showLoader, hideLoader } = useGlobalLoader();

  const [Enquiries, setEnquiries] = useState([]);
  const [enquiryId, setEnquiryId] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [checkedList, setCheckedList] = useState([]);
  const [indeterminate, setIndeterminate] = useState(true);
  const [checkAll, setCheckAll] = useState(false);
  const searchInput = useRef(null);
  const [data, setData] = useState([]);
  const [searchValue, setSearchValue] = useState([]);
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  const [columns, setColumns] = useState([
    { title: "Sr No.", dataIndex: "index", key: "index" },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      ...getColumnSearchProps("name"),
    },
    // { title: "Email", dataIndex: "email", key: "email" },
    // { title: "Phone", dataIndex: "phone", key: "phone" },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (email) => (
        <NavLink className="acolor" to={`mailto:${email}`}>
          {email}
        </NavLink>
      ),
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
      render: (phone) => (
        <NavLink className="acolor" to={`tel:${phone}`}>
          {phone}
        </NavLink>
      ),
    },
    {
      title: "City",
      dataIndex: "city",
      key: "city",
      sorted: true,
      sorter: (a, b) => a.city.localeCompare(b.city),
    },
    {
      title: "Reference From",
      dataIndex: "referance",
      key: "referance",
      sorted: true,
      sorter: (a, b) => a.referance.localeCompare(b.referance),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
    },
  ]);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  const { showNotificationWithMessage } = useNotification();

  const getEnquiries = async () => {
    try {
      showLoader();
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/admin/getContactEnquires`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("jwtoken")}`,
          },
          withCredentials: true,
        }
      );
      const result = response.data;
      // console.log(result);
      let finalResult = [];
      let index = 0;
      result.map((enquiry) => {
        if (enquiry.isServeyEnquiry === true) {
          finalResult.push({
            index: index + 1,
            name: enquiry.studentName,
            courseName: enquiry.title,
            email: enquiry.email,
            phone: enquiry.phone ? enquiry.phone : "Null",
            city: enquiry.city ? enquiry.city : "Null",
            key: enquiry._id,
            referance: enquiry.message ? enquiry.message : "Null",
            page: enquiry.pageName.split("/")[1],
            action: (
              <Space size="middle">
                <Button
                  type="primary"
                  className="btn-circle btn-danger btn-xs"
                  onClick={() => deleteEnquiry(enquiry._id)}
                >
                  <i className="fa fa-trash"></i>
                </Button>
              </Space>
            ),
          });
          index++;
        }
      });
      // console.log(finalResult);
      setData(finalResult);
      setEnquiries(result);
    } catch (error) {
      console.error("Error fetching products:", error);
    } finally {
      hideLoader();
    }
  };

  //delete enquiry
  const deleteEnquiry = async (id) => {
    try {
      // Show the confirmation popup
      const confirmed = await Swal.fire({
        title: "Are you sure?",
        text: "Are you sure you want to delete this enquiry?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      });
      if (!confirmed) {
        return; // If user cancels, do nothing
      }

      showLoader();
      const response = await axios.delete(
        `${process.env.REACT_APP_BACKEND_URL}/admin/DeleteContactEnquiry/${id}`,
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("jwtoken")}`,
          },
          withCredentials: true, // Send cookies with the request
        }
      );

      if (response.data) {
        getEnquiries();
        // notification.warning({
        //   message: "Record is deleted",
        // });
        showNotificationWithMessage("Enquiry is deleted", "success");
      }
    } catch (error) {
      // notification.warning({
      //   message: "Something went wrong",
      // });
      showNotificationWithMessage("Something went wrong", "error");
    } finally {
      hideLoader();
    }
  };
  const onChange = (e) => {
    if (e.target.checked) {
      setCheckedList([...checkedList, e.target.value]);
    }
    if (e.target.checked === false) {
      setCheckedList(checkedList.filter((id) => id !== e.target.value));
    }
  };

  const handleDeleteSelected = async () => {
    if (checkedList.length === 0) {
      showNotificationWithMessage("Please select atleast one enquiry", "error");
      return;
    }
    try {
      // Show the confirmation popup
      const confirmed = await Swal.fire({
        title: "Are you sure?",
        text: "Are you sure you want to delete these enquiries?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      });

      if (!confirmed) {
        return; // If user cancels, do nothing
      }

      showLoader();
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/admin/deleteMultipleContactEnquiry`,
        checkedList,
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("jwtoken")}`,
          },
          withCredentials: true, // Send cookies with the request
        }
      );

      if (response.data) {
        getEnquiries();
        setCheckedList([]);
        // notification.warning({
        //   message: "Record is deleted",
        // });
        showNotificationWithMessage("enquiry is deleted", "success");
      }
    } catch (error) {
      // notification.warning({
      //   message: "Something went wrong",
      // })
      showNotificationWithMessage("Something went wrong", "error");
    } finally {
      hideLoader();
    }
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setCheckedList(selectedRowKeys);
    },
  };

  useEffect(() => {
    getEnquiries();
  }, []);

  const tableRef = useRef(null);

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "Users table",
    sheet: "Users",
  });

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const searchserveyenquiry = debounce(async (event) => {
    try {
      const inputValue = document.querySelector('input[name="search"]').value;
      if (inputValue) {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/admin/SearchServeyEnquiry/${inputValue}`,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (response) {
          const result = response.data;
          // console.log("result", result);
          let newData = [];
          let index = 0;
          result.forEach((enquiry) => {
            if ((enquiry.isServeyEnquiry = true)) {
              newData.push({
                index: index + 1,
                name: enquiry.studentName,
                courseName: enquiry.title,
                email: enquiry.email,
                phone: enquiry.phone ? enquiry.phone : "Null",
                referance: enquiry.message ? enquiry.message : "Null",
                city: enquiry.city ? enquiry.city : "Null",
                key: enquiry._id,

                message: enquiry.message,
                page: enquiry.pageName.split("/")[1],
                action: (
                  <Space size="middle">
                    <Button
                      type="primary"
                      className="btn-circle btn-danger btn-xs"
                      onClick={() => deleteEnquiry(enquiry._id)}
                    >
                      <i className="fa fa-trash"></i>
                    </Button>
                  </Space>
                ),
              });
              index++;
            }
          });

          setData(newData);
          // console.log(response);
        }
        else {
          getEnquiries();
        }

        // input value
      }
    } catch (error) {
      showNotificationWithMessage("something went wrong", "error");
      console.error(error);
    }
  }, 2000);

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault(); // prevent default behavior
      searchserveyenquiry(searchValue);
    }
  };

  const generatePDF = () => {
    const doc = new jsPDF();
    doc.text("Servey Enquiry", 10, 10);
    // Create an array of data for the table
    const tableData = data.map((enq, index) => [
      index + 1,
      enq.name,
      enq.email,
      enq.phone,
      enq.city,
      // Add more fields as needed
    ]
    );

    // Define table columns
    const tableColumns = ["Sr.No.", "Name", "Email", "Phone", "City"]; // Add more column headers as needed

    // Set table position (x, y), header row, and data
    doc.autoTable({
      startY: 20,
      head: [tableColumns],
      body: tableData,
    });

    doc.save("servey-enquiry.pdf");
  };

  const generateExcel = () => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    const headingRow = ["Sr.No.", "Name", "Email", "Phone", "City"];

    const extractedData = [headingRow, ...data.map((enq, index) => [
      index + 1,
      enq.name,
      enq.email,
      enq.phone,
      enq.city,
      // Add more fields as needed
    ])];

    const ws = XLSX.utils.aoa_to_sheet(extractedData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const excelData = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(excelData, "all_survey_enquiry" + fileExtension);
  };



  return (
    <>
      <div className="content-wrapper">
        <div className="container-full">
          <div className="content-header">
            <div className="d-flex align-items-center">
              <div className="mr-auto">
                <h3 className="page-title">Survey Form Enquiry</h3>
                <nav>
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <NavLink to="/dashboard">Dashboard</NavLink>
                    </li>
                    <li className="breadcrumb-item" aria-current="page">
                      Enquiry
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Survey Enquiry
                    </li>
                  </ol>
                </nav>
              </div>
              <div className="mr-end">
                <div
                  onClick={handleDeleteSelected}
                  className={styles.deleteButton}
                >
                  <i class="ti-trash" style={{ marginRight: "10px" }}></i>
                  Delete
                </div>
              </div>
            </div>
          </div>
          {/* <!-- Main content --> */}
          <section className="content">
            <div className="row">
              {/* <div className="col-6">
              <button className="excel px-4" onClick={onDownload} style={{ marginTop: 50 }}>Excel</button>

<button className="excel pdf ml-3 px-4" onClick={handlePrint} style={{ marginTop: 50 }}>PDF </button>
              </div> */}
              {/* <div className="col-lg-6">
                <h3 className="page-title pt-4">Survey Enquiry</h3>
              </div> */}
              <div className="col-12">
                {/* <div
                  onClick={handleDeleteSelected}
                  className={styles.deleteButton}
                >
                  <i class="ti-trash" style={{ marginRight: "10px" }}></i>
                  Delete Selected
                </div> */}

                <div className="box" style={{ marginTop: 20 }}>
                  <div className="row py-2">
                    <div className="col-5 pt-3  pl-4 ml-4">
                      <button
                        className="excel px-4"
                        // onClick={onDownload}
                        onClick={generateExcel}
                        style={{ marginTop: 20 }}
                      >
                        <i className="fa fa-sign-in" aria-hidden="true"></i>
                        <span className="ml-2"> Excel</span>
                      </button>

                      <button
                        className="excel px-4 pdf ml-3"
                        onClick={generatePDF}
                        style={{ marginTop: 20 }}
                      >
                        <i className="fa fa-sign-in" aria-hidden="true"></i>
                        <span className="ml-2">PDF</span>
                      </button>
                    </div>

                    <div className="col-6 text-right">
                      <div
                        class="app-menu"
                        style={{ marginTop: 30, marginBottom: 30 }}
                      >
                        <div class="search-bx ml-5">
                          <div class="input-group">
                            <input
                              type="search"
                              className="form-control searchcourse"
                              name="search"
                              value={searchValue}
                              placeholder="Search"
                              aria-label="Search"
                              aria-describedby="button-addon2"
                              onChange={(e) => {
                                setSearchValue(e.target.value);
                                searchserveyenquiry(e.target.value);
                              }}
                              onKeyPress={handleKeyPress} // Add this line
                            // onKeyPress={handleKeyPress}
                            />
                            <div class="input-group-append appendsearch">
                              <button
                                class="btn"
                                type="submit"
                                id="button-addon3"
                                onClick={(e) => {
                                  e.preventDefault();
                                  searchserveyenquiry(searchValue);
                                }}

                              >

                                <i class="ti-search"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* <div className="col-2">
                        <div className="dropdown">
                          <a
                            data-toggle="dropdown"
                            href="#"
                            className="px-10 coursefilter"
                          >
                            <i class="fa fa-ellipsis-v"></i>
                          </a>
                          <div className="dropdown-menu dropdown-menu-right">
                            <a
                              className="dropdown-item labeltext"
                              onClick={onDownload}
                              href="#"
                            >
                              <i className="ti-import"></i>Excel
                            </a>

                            <a
                              className="dropdown-item labeltext droppt"
                              onClick={handlePrint}
                              href="#"
                            >
                              <i className="ti-import"></i>PDF
                            </a>
                        
                          </div>
                        </div>
                      </div> */}
                  </div>
                  <div className="box-body">
                    <div className="table-responsive">
                      <div ref={componentRef}>
                        <Table
                          className="customTable"
                          rowSelection={{ type: "checkbox", ...rowSelection }}
                          columns={columns}
                          dataSource={data}
                          pagination={{ pageSize: 7 }}
                          ref={tableRef}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* <!-- /.content --> */}
        </div>
      </div>
    </>
  );
};

export default ServeyEnquires;
