import { notification } from 'antd';
import axios from 'axios';
import { NavLink } from "react-router-dom";
import Cookies from 'js-cookie';
import React from 'react'
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useNotification from '../../../../useNotification'; // Import the custom hook
import { useEffect } from 'react';


const AddPopUpModal = () => {
  useEffect(() => {
    const checkToken = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/admin/protected`,
          {
            headers: {
              Authorization: `Bearer ${Cookies.get("jwtoken")}`,
            },

            withCredentials: true, //============new
          }
        );
        const data = await response.data;
        // console.log(data);
      } catch (error) {
        navigate("/");
        // alert("Unauthorized");
      }
    };

    checkToken();
  }, []);

  const { showNotificationWithMessage } = useNotification();

  const navigate = useNavigate();
  const [serviceImage, setServiceImage] = useState(null);

  const [errors, setErrors] = useState({
    serviceImage: "",

  });

  const validateForm = () => {
    const newErrors = {
      serviceImage: "",
    };
    let isValid = true;

    if (!serviceImage) {
      newErrors.serviceImage = "Please upload an Image";
      isValid = false;
    }
    setErrors(newErrors);
    return isValid;
  };

  const addServiceImg = async () => {

    if (!validateForm()) {
      return;
    }

    const formData = new FormData();
    formData.append("popupImage", serviceImage);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/admin/addPopup`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${Cookies.get("jwtoken")}`,
          },
          withCredentials: true, // Send cookies with the request
        }
      );
      if (response.status === 200) {
        showNotificationWithMessage('Popup added successfully', 'success');
        setTimeout(() => {
          navigate('/popUp-modal');
        }, 1000);
      }

    } catch (error) {
      showNotificationWithMessage('Error adding Popup Image', 'error');

    }
  }

  const scrollToError = (errors, addServiceImg) => {
    if (errors) {
      const errorField = Object.keys(errors)[0];
      const field = document.getElementsByName(errorField)[0];
      if (field) {
        field.scrollIntoView({ behavior: "smooth", block: "center" });
      }
      addServiceImg();
    } else {
      addServiceImg();
    }
  }

  return (
    <>
      <div className="content-wrapper">
        <div className="container-full">
          <div className="content-header">
            <div className="d-flex align-items-center">
              <div className="mr-auto">
                <h3 className="page-title">Add PopUp</h3>
                <div className="d-inline-block align-items-center">
                  <nav>
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item"><NavLink to="/dashboard">Dashboard</NavLink></li>
                      <li className="breadcrumb-item" aria-current="page"><NavLink to="/popUp-modal">PopUp</NavLink></li>
                      <li className="breadcrumb-item active" aria-current="page"><NavLink to="/dashboard">Add PopUp</NavLink></li>
                    </ol>
                  </nav>
                </div>
              </div>

            </div>
          </div>
          <section className="content pt-5">
            <div className="row">

              {/* <form>/ */}
              <div className="col-lg-12 col-12 mx-auto">
                {/* <!-- box1 --> */}

                <div className="box">

                  <div className="box-body boxpadding">
                    <div className="row">

                      <div className="col-md-12">
                        <div className="form-group">
                          <label>
                            PopUP  Image
                            <span className="text-danger ml-1">
                              {/* (Size - 1920 x 400)* */}
                            </span>
                          </label>
                          <input
                            type="file"
                            className="form-control"
                            name="serviceImage"
                            // onChange={(e) => {
                            //   setServiceImage(e.target.files[0]);
                            // }}
                            onChange={(e) => {
                              const selectedFile = e.target.files[0];
                              if (selectedFile) {
                                if (selectedFile.size > 5 * 1024 * 1024) {
                                  showNotificationWithMessage(
                                    "File size should be less than 5MB",
                                    "error"
                                  );
                                  e.target.value = null;
                                } else {
                                  setServiceImage(selectedFile);
                                }
                              }
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <!-- box1 -->
                
                  {/* <!-- action --> */}
                <div className="box-footer mb-4">
                  <button
                    type="submit"
                    className="btn btn-rounded btn_dark btn-outline btncourse"
                    // onClick={addServiceImg}
                    onClick={e => scrollToError(errors, addServiceImg)}
                    style={{ padding: 10 }}
                  >
                    <i className="ti-save-alt"></i> Save
                  </button>
                </div>

              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  )
}

export default AddPopUpModal