import React, { useEffect, useState, useRef, useMemo } from "react";
import { useParams, useNavigate } from "react-router-dom";
import JoditEditor from "jodit-react";
import axios from "axios";
import { NavLink } from "react-router-dom";
import Cookies from "js-cookie";
import { notification } from "antd";

import useNotification from '../../../useNotification'; // Import the custom hook
import { useGlobalLoader } from "../../../Loader/GlobalLoaderContext";

const UpdateBlog = () => {

  useEffect(() => {
    const checkToken = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/admin/protected`,
          {
            headers: {
              Authorization: `Bearer ${Cookies.get("jwtoken")}`,
            },

            withCredentials: true, //============new
          }
        );
        const data = await response.data;
        // console.log(data);
      } catch (error) {
        navigate("/");
        // alert("Unauthorized");
      }
    };

    checkToken();
  }, []);

  const { showLoader, hideLoader } = useGlobalLoader();
  const { showNotificationWithMessage } = useNotification();

  const editor = useRef(null);

  const [blogTitle, setBlogTitle] = useState("");
  // const [shortInfo, setShortInfo] = useState("");
  const [blogAuthor, setBlogAuthor] = useState("");
  const [blogContent, setBlogContent] = useState("");
  const [blogThumbImage, setBlogThumbImage] = useState("");
  const [blogDetailImage, setBlogDetailImage] = useState("");
  const [errors, setErrors] = useState({});
  const [blogThumbImageURL, setBlogThumbImageURL] = useState("");
  const [blogDetailImageURL, setBlogDetailImageURL] = useState("");

  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    getBlog();
  }, []);

  const getBlog = async () => {
    try {
      showLoader();
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/admin/GetEditBlog/${params.id}`,
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("jwtoken")}`,
          },
          withCredentials: true, // Send cookies with the request
        });
      const result = response.data;
      setBlogTitle(result.blogTitle);
      // setShortInfo(result.shortInfo);
      setBlogAuthor(result.blogAuthor);
      setBlogContent(result.blogContent);
      setBlogThumbImage(result.blogThumbImageURL);
      setBlogDetailImage(result.blogDetailImageURL);

      setBlogThumbImageURL(result.blogThumbImageURL);
      setBlogDetailImageURL(result.blogDetailImageURL);

    } catch (error) {
      // notification.warning({
      //   message: "something went wrong",
      // });
      showNotificationWithMessage('Something went wrong', 'error');

    }
    finally {
      hideLoader();
    }

  };

  //update blog
  const updateBlogs = async () => {
    const formData = new FormData();
    formData.append("blogTitle", blogTitle);
    // formData.append("shortInfo", shortInfo);
    formData.append("blogAuthor", blogAuthor);
    formData.append("blogContent", blogContent);
    formData.append("blogThumbImage", blogThumbImage);
    formData.append("blogDetailImage", blogDetailImage);

    const formErrors = {};

    if (!blogTitle) {
      formErrors.blogTitle = "Blog Title is required";
    }

    if (!blogAuthor) {
      formErrors.blogAuthor = "Blog Author Name is required";
    }

    if (!blogContent) {
      formErrors.blogContent = "Blog Content is required";
    }

    // if (!blogThumbImage) {
    //   formErrors.blogThumbImage = "Thumbnail Image is required";
    // }

    // if (!blogDetailImage) {
    //   formErrors.blogDetailImage = "Detail Page Image is required";
    // }

    setErrors(formErrors);

    if (Object.keys(formErrors).length > 0) {
      return;
    }


    try {
      showLoader();
      const response = await axios.put(`${process.env.REACT_APP_BACKEND_URL}/admin/UpdateBlog/${params.id}`, formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${Cookies.get("jwtoken")}`,
          },
          withCredentials: true, // Send cookies with the request
        }
      );
      if (response.data) {
        // notification.success({
        //   message: "Blog updated successfully",
        // });
        // navigate("/ViewBlogList");

        showNotificationWithMessage('Blog updated successfully', 'success');
        setTimeout(() => {
          navigate('/blog');
        }, 1000);
      }


    } catch (error) {
      // notification.warning({
      //   message: "something went wrong",
      // });
      showNotificationWithMessage('Something went wrong', 'error');

    } finally {
      hideLoader();
    }

  }

  // const editorConfig = {
  //   responsive: true,
  //   // buttons: buttons,
  //   // uploader: {
  //   //     insertImageAsBase64URI: true,
  //   // },
  // };
  const editorConfig = useMemo(() => {
    return {
      readonly: false,
      placeholder: "Start typings...",
      responsive: true,
      uploader: {
        insertImageAsBase64URI: true,
      },
    };
  }, []);

  const scrollToError = (errors, updateBlogs) => {
    if (errors) {
      const errorField = Object.keys(errors)[0];
      const field = document.getElementsByName(errorField)[0];
      if (field) {
        field.scrollIntoView({ behavior: "smooth", block: "center" });
      }
      updateBlogs();
    } else {
      updateBlogs();
    }
  }

  useEffect(() => {
    if (blogThumbImage?.size) {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(blogThumbImage);
      fileReader.onload = () => {
        setBlogThumbImageURL(fileReader.result);
      };
    }
  }, [blogThumbImage]);

  useEffect(() => {
    if (blogDetailImage?.size) {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(blogDetailImage);
      fileReader.onload = () => {
        setBlogDetailImageURL(fileReader.result);
      };
    }
  }, [blogDetailImage]);


  return (
    <>
      {/* <!-- Content Wrapper. Contains page content --> */}
      <div className="content-wrapper">
        <div className="container-full">
          <div className="content-header">
            <div className="d-flex align-items-center">
              <div className="mr-auto">
                <h3 className="page-title">Update Blog</h3>
                <div className="d-inline-block align-items-center">
                  <nav>
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item"><NavLink to="/dashboard">Dashboard</NavLink></li>
                      <li className="breadcrumb-item" aria-current="page"><NavLink to="/blog">Blog</NavLink></li>
                      <li className="breadcrumb-item active" aria-current="page">Update Blog</li>
                    </ol>
                  </nav>
                </div>
              </div>

            </div>
          </div>
          {/* <!-- Content Header (Page header) --> */}

          {/* <!-- form --> */}
          <section className="content mt-5">
            <div className="row">

              <div className="col-lg-12 col-12 mx-auto">
                {/* <!-- box1 --> */}
                {/* <form > */}
                <div className="box">

                  <div className="box-body boxpadding pt-4">
                    <div className="row pt-3">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="labeltext">Title <span className="aesterik">*</span></label>
                          <input
                            type="text"
                            name="blogTitle"
                            // className="form-control"
                            className={`form-control ${errors.blogTitle ? "is-invalid" : ""}`}
                            placeholder="Blog Title"
                            value={blogTitle}
                            onChange={(e) => {
                              setBlogTitle(e.target.value);
                              setErrors({ ...errors, blogTitle: "" });
                            }}
                          />
                          {errors.blogTitle && (
                            <span className="invalid-input">{errors.blogTitle}</span>
                          )}

                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="labeltext">Author Name <span className="aesterik">*</span></label>
                          <input
                            type="text"
                            name="blogAuthor"
                            // className="form-control"
                            className={`form-control ${errors.blogAuthor ? "is-invalid" : ""}`}
                            placeholder="Blog Author"
                            value={blogAuthor}
                            onChange={(e) => {
                              setBlogAuthor(e.target.value);
                              setErrors({ ...errors, blogAuthor: "" });
                            }}
                          />
                          {errors.blogAuthor && (
                            <span className="invalid-input">{errors.blogAuthor}</span>
                          )}
                        </div>
                      </div>


                      <div className="col-md-6 mt-2">
                        <div className="form-group">
                          <label className="labeltext">Thumbnail Image</label>
                          <div className="row">
                            <div className="col-lg-6 mt-3">
                              <input
                                type="file"
                                name="blogThumbImage"
                                className="form-control"
                                onChange={(e) => {
                                  // setBlogThumbImage(e.target.files[0]);
                                  const selectedThumbImage = e.target.files[0];
                                  if (selectedThumbImage) {
                                    if (selectedThumbImage.size > 5 * 1024 * 1024) {
                                      showNotificationWithMessage('Image size should be less than 5MB', 'error');
                                      e.target.value = null;
                                    } else {
                                      setBlogThumbImage(selectedThumbImage);
                                    }
                                  }
                                }}
                              />
                            </div>
                            <div className="col-lg-2">
                              {blogThumbImageURL && (<img style={{ maxHeight: '3rem', margin: '1rem 2rem 0 0' }} src={blogThumbImageURL} />)}
                              {/* <ErrorMessage
                              className="text-danger"
                              name="image"
                              component="div"
                            /> */}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6 mt-2">
                        <div className="form-group">
                          <label className="labeltext">Detail Page Image</label>
                          <div className="row">
                            <div className="col-lg-6 mt-3">
                              <input
                                type="file"
                                name="blogThumbImage"
                                className="form-control"
                                onChange={(e) => {
                                  // setBlogDetailImage(e.target.files[0]);
                                  const selectedDetailImage = e.target.files[0];
                                  if (selectedDetailImage) {
                                    if (selectedDetailImage.size > 5 * 1024 * 1024) {
                                      showNotificationWithMessage('Image size should be less than 5MB', 'error');
                                      e.target.value = null;
                                    } else {
                                      setBlogDetailImage(selectedDetailImage);
                                    }
                                  }
                                }}
                              />
                            </div>
                            <div className="col-lg-2">
                              {blogDetailImageURL && (<img style={{ maxHeight: '3rem', margin: '1rem 2rem 0 0' }} src={blogDetailImageURL} />)}
                              {/* <ErrorMessage
                              className="text-danger"
                              name="image"
                              component="div"
                            /> */}
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* <div className="col-md-12 mt-2">
                        <div className="form-group">
                          <label>Short Info</label>
                          <textarea
                            rows="2"
                            name="shortInfo"
                            className="form-control"
                            placeholder="short Info"
                            value={shortInfo}
                            onChange={(e) => {
                              setShortInfo(e.target.value);
                            }}
                          ></textarea>

                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
                {/* <!-- box1 -->

                  {/* // <!-- box2 -->  */}

                <div className="box">
                  <div className="box-body">
                    <div className="row pt-3">
                      <div className="col-md-12 mt-2">
                        <div className="form-group">
                          <label className="labeltext">Description <span className="aesterik">*</span></label>
                          {/* {useMemo(
                            () => ( */}
                          <JoditEditor
                            ref={editor}
                            config={editorConfig}
                            value={blogContent}
                            onChange={(newContent) => {
                              setBlogContent(newContent);
                              setErrors({ ...errors, blogContent: "" });
                            }
                            }
                          />
                        </div>
                        {errors.blogContent && (
                          <span className="invalid-input">{errors.blogContent}</span>
                        )}

                        {/* <div className="col-md-12 mt-2">
                          <div className="form-group">
                            <label>Blog Description</label>
                            <JoditEditor
                              ref={editor}
                              config={editorConfig}
                              value={blogContent}
                              onChange={(e) => {
                                setBlogContent(e);
                              }}
                            />
                            
                          </div>
                        </div> */}

                      </div>
                    </div>
                  </div>
                </div>

                {/* box3 */}


                {/* <!-- box4 --> */}









                {/* <!-- action --> */}
                <div className="box-footer mb-4 text-center">

                  <button
                    type="submit"
                    className="btn btn-rounded  btn_dark btn-outline btncourse"
                    style={{ padding: '6px 22px' }}
                    // onClick={updateBlogs}
                    onClick={e => scrollToError(errors, updateBlogs)}
                  >
                    {/* <i className="ti-save-alt"></i> */}
                    Update
                  </button>
                </div>

                {/* <!-- action --> */}

                {/* </form> */}
              </div>
            </div>
          </section>

          {/* <!-- form --> */}

          {/* 		
		<!-- /.content --> */}
        </div>
      </div>
    </>
  );
}

export default UpdateBlog