import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Cookies from 'js-cookie';
// import { notification } from "antd";

// import Cookies from 'universal-cookie';
// const cookies = new Cookies();

import useNotification from '../../useNotification'; // Import the custom hook
import { useGlobalLoader } from "../../Loader/GlobalLoaderContext"

const AdminAuthLogin = () => {
  const { showLoader, hideLoader } = useGlobalLoader();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loggedIn, setLoggedIn] = useState(false);

  const navigate = useNavigate();
  const { showNotificationWithMessage } = useNotification();

  useEffect(() => {
    const auth = Cookies.get('jwtoken');
    // console.log(auth)
    if (auth != "undefined" && auth) {
      setLoggedIn(true); // Set the state to true if the 'jwtoken' cookie exists
      navigate("/Dashboard");
    }
  }, [navigate]);

  useEffect(() => {
    const checkLoginStatus = async () => {
      try {
        const response = await axios.get('http://localhost:5000/admin/protected', {
          headers: {
            Authorization: `bearer ${JSON.parse(Cookies.get('jwtoken'))}`
          },
          withCredentials: true
        });
        setLoggedIn(true);
      } catch (error) {
        setLoggedIn(false);
      }
    };

    checkLoginStatus();
  }, []);

  const loginUser = async (e) => {
    e.preventDefault();

    try {
      showLoader();
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/admin/AdminLogin`, {
        email,
        password
      }, {
        headers: {
          "Content-Type": "application/json"
        },
        withCredentials: true    //============new 


      });

      if (response.status === 200) {

        Cookies.set('jwtoken', response.data.jwtoken); // Use response.data.token instead of 
        Cookies.set('adminId', response.data.adminId);
        // console.log(response.data.adminId);

        showNotificationWithMessage('Login Successfully', 'success');
        setTimeout(() => {
          navigate('/dashboard');
        }, 1000);
        // navigate('/Dashboard');

      } else {
        showNotificationWithMessage('Something went wrong', 'error');
      }

    } catch (error) {
      showNotificationWithMessage('You have entered an invalid email or password', 'error');
    }
    finally {
      hideLoader();
    }
  };


  return (
    <>
      {loggedIn ? (
        <div>Loading...</div>
      ) : (
        <div className="container h-p100">
          <div className="row align-items-center justify-content-md-center h-p100">
            <div className="col-12">
              <div className="row justify-content-center no-gutters">
                <div className="col-lg-5 col-md-5 col-12 ">
                  <div className="bg-white shadow-lg py-70">
                    <div className="content-top-agile ">
                      <img src="../assets/img/logo.png" alt="Learnovation" />
                      <h4 className="mt-4 loginhead" style={{ color: "#373435" }}>Login Now</h4>
                    </div>
                    <div className="px-40 py-20">
                      <form action="">
                        <div className="form-group">
                          <div className="input-group mb-3">
                            <div className="input-group-prepend">
                              <span className="input-group-text bg-transparent">
                                <i className="ti-user"></i>
                              </span>
                            </div>
                            <input
                              type="email"
                              className="form-control pl-15 bg-transparent"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                              placeholder="Email"
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="input-group mb-3">
                            <div className="input-group-prepend">
                              <span className="input-group-text  bg-transparent">
                                <i className="ti-lock"></i>
                              </span>
                            </div>
                            <input
                              type="password"
                              className="form-control pl-15 bg-transparent"
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}
                              placeholder="Password"
                            />
                          </div>
                        </div>
                        <div className="row">
                          {/* <div className="col-6">
                          <div className="checkbox" style={{ marginTop: 40 }}>
                            <input type="checkbox" id="basic_checkbox_1" />
                            <label htmlFor="basic_checkbox_1">
                              Remember Me
                            </label>
                          </div>
                        </div> */}



                          <div className="col-12 text-center">
                            <button
                              type="submit"
                              className="btn btn-danger mt-20"
                              onClick={loginUser}
                            >
                              SIGN IN
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

    </>
  );
};

export default AdminAuthLogin;
