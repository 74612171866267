import { Button, Input, Modal } from "antd";
import { DeleteOutlined, TrophyFilled } from "@ant-design/icons"; // Import the delete icon
import { useEffect, useState } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { useGlobalLoader } from "../../../Loader/GlobalLoaderContext";
import useNotification from "../../../useNotification";


export default function AddBatches({
  showBatchModal,
  setShowBatchModal,
  getBatches,
  batchNameData,
  setSelectedBatchName,
  setBatch,
  courseId,
}) {
  const [batchName, setBatchName] = useState([""]);
  const { showNotificationWithMessage } = useNotification();
  const { showLoader, hideLoader } = useGlobalLoader();

  useEffect(() => {
    if (batchNameData) {
      // console.log("batchNameDatabatchNameData", batchNameData)
      setBatchName(batchNameData.batchName)
    } else {
      setBatchName("")
    }
  }, [batchNameData])



  const handleOkClick = async () => {
    try {
      const batchData = {
        batchName: batchName,
        courseId: courseId
      }
      // console.log("batchData", batchData)
      showLoader();
      if (batchNameData) {
        const response = await axios.put(
          `${process.env.REACT_APP_BACKEND_URL}/admin/EditBatches/${batchNameData._id}`,
          batchData,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${Cookies.get("jwtoken")}`,
            },
            withCredentials: true,
          }
        );
        if (response.status === 200) {
          showNotificationWithMessage("Batch Updated Successfully", "success");
        }
        await getBatches();
        // console.log(response)
        if (response.status === 200) {
          showNotificationWithMessage("Batch Updated Successfully", "success");
        }
        setShowBatchModal(false)

      } else {

        const response = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/admin/AddBatches`,
          batchData,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${Cookies.get("jwtoken")}`,
            },
            withCredentials: true,
          }
        );
        // console.log(response);
        if (response.status === 200) {
          showNotificationWithMessage("Batch Added Successfully", "success");
          getBatches();
          setShowBatchModal(false);
        }
        setBatchName("");
      }
    } catch (error) {
      // console.log("error", error);
      if (error.response && error.response.status === 400) {
        showNotificationWithMessage(error.response.data.message, "error");
      }
      // console.log("Error adding batches", error);
    } finally {
      hideLoader();
    }
  };

  return (

    <Modal
      // title="Add Batches"
      title={batchNameData ? "Edit Batch" : "Add Batch"}
      visible={showBatchModal}
      onOk={handleOkClick}
      onCancel={() => setShowBatchModal(false)}
    >
      <div>
        <Input
          placeholder="Batch Name"
          value={batchName}
          onChange={(e) => setBatchName(e.target.value)}
          maxLength={10}
        />
      </div>
    </Modal >
  );
}
