import React, { useEffect, useState } from "react";
import axios from "axios";
import { NavLink } from "react-router-dom";
import Cookies from "js-cookie";
import { notification } from "antd";
import { useNavigate } from "react-router";
import ImageCroper from "../../ImageCroper/ImageCroper";
import useNotification from '../../../useNotification'; // Import the custom hook
import { useGlobalLoader } from "../../../Loader/GlobalLoaderContext";

const AddBanner = () => {
  useEffect(() => {
    const checkToken = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/admin/protected`,
          {
            headers: {
              Authorization: `Bearer ${Cookies.get("jwtoken")}`,
            },

            withCredentials: true, //============new
          }
        );
        const data = await response.data;
        // console.log(data);
      } catch (error) {
        navigate("/");
        // alert("Unauthorized");
      }
    };

    checkToken();
  }, []);

  const { showLoader, hideLoader } = useGlobalLoader();
  const { showNotificationWithMessage } = useNotification();

  const navigate = useNavigate();
  const [bannerPage, setBannerPage] = useState("");
  const [bannerImage, setBannerImage] = useState("");
  const [cropingImage, setCropingImage] = useState(false);
  const [aspectRatio, setAspectRatio] = useState(4.8);
  const [cropPosterImage, setPosterImageToCrop] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const addBanner = async () => {
    // if (!bannerPage || !bannerImage) {
    //   showNotificationWithMessage('All fields are required', 'error');
    //   return;
    // }
    const formData = new FormData();
    formData.append("bannerPage", bannerPage);
    formData.append("bannerImage", bannerImage);
    console.log("formData", formData)

    try {
      showLoader();
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/admin/AddBanner`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${Cookies.get("jwtoken")}`,
          },
          withCredentials: true, // Send cookies with the request
        }
      );

      if (response.status === 200) {
        showNotificationWithMessage('Banner added successfully', 'success');
        setTimeout(() => {
          navigate('/banner');
        }, 1000);
      }
    } catch (error) {
      console.error("Error adding media:", error);
      showNotificationWithMessage('Error adding banner or Please check Page Name if already exist', 'error');
    }
    finally {
      hideLoader();
    }
  };

  return (
    <>
      <div className="content-wrapper">
        <div className="container-full">
          <div className="content-header">
            <div className="d-flex align-items-center">
              <div className="mr-auto">
                <h3 className="page-title">Banner</h3>
                <div className="d-inline-block align-items-center">
                  <nav>
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item"><NavLink to="/dashboard">Dashboard</NavLink></li>
                      <li className="breadcrumb-item" aria-current="page"><NavLink to="/banner">Banner</NavLink></li>
                      <li className="breadcrumb-item active" aria-current="page">Add Banner</li>
                    </ol>
                  </nav>
                </div>
              </div>

            </div>
          </div>
          <section className="content">
            <div className="row">

              {/* <form>/ */}
              <div className="col-lg-12 col-12 mx-auto">
                {/* <!-- box1 --> */}

                <div className="box">

                  <div className="box-body pt-4">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group">
                          <label>Select Page</label>
                          <select
                            className="form-control"
                            value={bannerPage}
                            onChange={(e) => setBannerPage(e.target.value)}
                          >
                            <option selected value="">
                              --Select Page--
                            </option>
                            {/* <option>Home</option> */}
                            <option>StudentDashboard</option>
                            <option>About</option>
                            <option>Course</option>
                            <option>Blog</option>
                            <option>Contact</option>
                            <option>MyMedia</option>
                            <option>MyCourses</option>
                            <option>Blogdetail</option>
                            <option>Servicedetail</option>
                            <option>CourseDetail</option>
                            <option>Gallery</option>
                            <option>PrivacyPolicy</option>
                          </select>
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div className="form-group">
                          <label>
                            Banner Image
                            <span className="text-danger ml-1">
                              (Size - 1920 x 300)*
                            </span>
                          </label>
                          <input
                            type="file"
                            className="form-control"
                            accept="image/*"
                            name="image"
                            onChange={(e) => {
                              const selectedBannerImage = e.target.files[0];
                              if (selectedBannerImage) {
                                if (selectedBannerImage.size > 5 * 1024 * 1024) {
                                  showNotificationWithMessage('Image size should be less than 5MB', 'error');
                                  e.target.value = null;
                                } else {
                                  setBannerImage(selectedBannerImage);
                                }
                              }
                            }}
                          />

                          {/* <div className="col-lg-6 mt-3">
                            <input
                              type="file"
                              className="form-control"
                              // value={bannerImage}
                              accept="image/*"
                              onChange={(e) => {
                                // setBannerImage(e.target.files[0]);
                                const selectedBannerImage = e.target.files[0];
                                if (selectedBannerImage) {
                                  if (selectedBannerImage.size > 5 * 1024 * 1024) {
                                    showNotificationWithMessage('Image size should be less than 5MB', 'error');
                                    e.target.value = null;
                                  } else {
                                    setBannerImage(selectedBannerImage);
                                  }
                                }
                              }}
                            />
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <!-- box1 -->
                
                  {/* <!-- action --> */}
                <div className="box-footer mb-4">
                  <button
                    type="submit"
                    className="btn btn-rounded btn_dark btn-outline btncourse"
                    onClick={addBanner}
                    style={{ padding: '6px 22px' }}
                  >
                    {/* <i className="ti-save-alt"></i>  */}
                    Save
                  </button>
                </div>

                {/* <!-- action --> */}
              </div>
              {/* </form> */}
            </div>
          </section>

          {/* <!-- form --> */}

          {/* 		
		<!-- /.content --> */}
        </div>
      </div>
      {/* <!-- /.content-wrapper --> */}
    </>
  );
};

export default AddBanner;
