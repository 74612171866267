import React, { useState, useRef, useMemo, useEffect } from "react";
import { NavLink } from "react-router-dom";
import JoditEditor from "jodit-react";
import { useNavigate } from "react-router-dom";
import { ColorPicker, notification } from "antd";
import axios from "axios";
import Cookies from "js-cookie";
import ServiceCard from "../../website/Service/ServiceCard";
import { set } from "react-hook-form";
import useNotification from '../../../useNotification'; // Import the custom hook
import { useGlobalLoader } from "../../../Loader/GlobalLoaderContext";

const AddService = () => {

  useEffect(() => {
    const checkToken = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/admin/protected`,
          {
            headers: {
              Authorization: `Bearer ${Cookies.get("jwtoken")}`,
            },

            withCredentials: true, //============new
          }
        );
        const data = await response.data;
        // console.log(data);
      } catch (error) {
        navigate("/");
        // alert("Unauthorized");
      }
    };

    checkToken();
  }, []);

  const { showLoader, hideLoader } = useGlobalLoader();
  const { showNotificationWithMessage } = useNotification();

  const editor = useRef(null);

  const [blogTitle, setBlogTitle] = useState("");
  const [shortInfo, setShortInfo] = useState("");
  const [blogAuthor, setBlogAuthor] = useState("");
  const [blogContent, setBlogContent] = useState("");
  const [blogThumbImage, setBlogThumbImage] = useState("");
  const [blogDetailImage, setBlogDetailImage] = useState("");
  const [backgroundColor, setBackgroundColor] = useState("#FFAFAF");
  const [textColor, setTextColor] = useState("#000000");
  const [imageURL, setmageURL] = useState("");
  const navigate = useNavigate();

  const [textStyles, setTextStyles] = useState({
    position: "absolute",
    width: "60%",
    top: "10%",
    left: "40%",
    color: textColor,
  });

  const [imageStyles, setImageStyles] = useState({
    position: "absolute",
    top: "1%",
    left: "3%",
    width: "50%",
  });

  const [errors, setErrors] = useState({
    blogTitle: "",
    shortInfo: "",
    blogContent: "",
    blogThumbImage: "",
    blogDetailImage: "",
  });

  const validateForm = () => {
    const newErrors = {
      blogTitle: "",
      shortInfo: "",
      blogContent: "",
      blogThumbImage: "",
      blogDetailImage: "",
    };

    let isValid = true;

    if (!blogTitle) {
      newErrors.blogTitle = "Please enter a title";
      isValid = false;
    }

    if (!shortInfo) {
      newErrors.shortInfo = "Please enter a short description";
      isValid = false;
    }

    if (!blogContent) {
      newErrors.blogContent = "Please enter content";
      isValid = false;
    }

    if (!blogThumbImage) {
      newErrors.blogThumbImage = "Please upload a thumbnail image";
      isValid = false;
    }

    if (!blogDetailImage) {
      newErrors.blogDetailImage = "Please upload a detail image";
      isValid = false;
    }

    setErrors(newErrors);

    return isValid;
  };


  const handleSubmit = async (e) => {
    // e.preventDefault();

    if (!validateForm()) {
      return;
    }


    const formData = new FormData();
    formData.append("title", blogTitle);
    formData.append("shortDescrioption", shortInfo);
    formData.append("Details", blogContent);
    formData.append("image", blogThumbImage);
    formData.append("DetailsImage", blogDetailImage);
    formData.append("backgroundColor", backgroundColor);
    formData.append("textColor", textColor);
    formData.append("textStyles", JSON.stringify(textStyles));
    formData.append("imageStyles", JSON.stringify(imageStyles));

    try {

      showLoader();
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/admin/AddServices`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${Cookies.get("jwtoken")}`,
          },
          withCredentials: true, // Send cookies with the request
        }
      );
      const data = await response.data;
      // console.log(data);
      if (response.status === 200) {
        // notification.success({
        //   message: "Service added successfully",
        // });
        // navigate("/ViewServicesList");

        showNotificationWithMessage('Service added successfully', 'success');
        setTimeout(() => {
          navigate('/service');
        }, 1000);
      }
    } catch (error) {
      console.error("Error adding Service:", error);
      // notification.error({
      //   message: "Error adding Service ",
      // });
      showNotificationWithMessage('Error adding Service', 'error');

    }
    finally {
      hideLoader();
    }
  };

  const editorConfig = {
    responsive: true,
    // buttons: buttons,
    // uploader: {
    //     insertImageAsBase64URI: true,
    // },
  };

  useEffect(() => {
    if (blogThumbImage) {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(blogThumbImage);
      fileReader.onloadend = () => {
        // console.log(fileReader.result)
        setmageURL(fileReader.result);
      }
    }

  }, [blogThumbImage]);

  const scrollToError = (errors, handleSubmit) => {
    if (errors) {
      const errorField = Object.keys(errors)[0];
      const field = document.getElementsByName(errorField)[0];
      if (field) {
        field.scrollIntoView({ behavior: "smooth", block: "center" });
      }
      handleSubmit();
    } else {
      handleSubmit();
    }
  }

  return (
    <>
      <div className="content-wrapper">
        <div
          className="container-full"

        >
          <div className="content-header">
            <div className="d-flex align-items-center">
              <div className="mr-auto">
                <h3 className="page-title">Add Service</h3>
                <div className="d-inline-block align-items-center">
                  <nav>
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item"><NavLink to="/dashboard">Dashboard</NavLink></li>
                      <li className="breadcrumb-item" aria-current="page"><NavLink to="/service">Service</NavLink></li>
                      <li className="breadcrumb-item active" aria-current="page">Add Service</li>
                    </ol>
                  </nav>
                </div>
              </div>

            </div>
          </div>

          <section className="content mt-5">
            <div className="row">

              {/* <form style={{ maxWidth: "50%" }} onSubmit={handleSubmit}> */}
              <div style={{ maxWidth: "50%" }}>
                <div className="col-lg-12 col-12 mx-auto">
                  {/* <!-- box1 --> */}

                  <div className="box">

                    <div className="box-body boxpadding pt-4">
                      <div className="row pt-3 pb-3">
                        <div className="col-md-12">
                          <div className="form-group">
                            <label className="labeltext">Title <span className="aesterik">*</span></label>
                            <input
                              type="text"
                              name="blogTitle"
                              // className="form-control"
                              className={`form-control ${errors.blogTitle ? "is-invalid" : ""
                                }`}
                              placeholder="Service Title"
                              value={blogTitle}
                              onChange={(e) => {
                                setBlogTitle(e.target.value);
                                setErrors({ ...errors, blogTitle: "" });
                              }}
                            />
                            {errors.blogTitle && (
                              <div className="invalid-input">{errors.blogTitle}</div>
                            )}
                          </div>
                        </div>

                        <div className="col-md-12 mt-2">
                          <div className="form-group">
                            <label className="labeltext">Short Description <span className="aesterik">*</span></label>
                            <textarea
                              rows="2"
                              name="shortInfo"
                              // className="form-control"
                              className={`form-control ${errors.shortInfo ? "is-invalid" : ""
                                }`}
                              placeholder="short Info"
                              value={shortInfo}
                              onChange={(e) => {
                                setShortInfo(e.target.value);
                                setErrors({ ...errors, shortInfo: "" });
                              }}
                            ></textarea>
                            {errors.shortInfo && (
                              <div className="invalid-input">{errors.shortInfo}</div>
                            )}
                          </div>
                        </div>
                        <div className="col-md-12 mt-2">
                          <div className="row pt-2">
                            <div className="col-md-6 mt-2">
                              <div className="form-group">
                                <label className="labeltext">Thumbnail Background Color <span className="aesterik">*</span></label>
                                <ColorPicker
                                  format="hex"
                                  onChange={(e, hex) => setBackgroundColor(hex)}
                                  value={backgroundColor}
                                  className="form-control"
                                />
                              </div>
                            </div>

                            <div className="col-md-6 mt-2">
                              <div className="form-group">
                                <label className="labeltext">width of content box (%) <span className="aesterik">*</span></label>
                                <input
                                  type="Number"
                                  name="blogTitle"
                                  className="form-control"
                                  placeholder="Service Title"
                                  value={
                                    textStyles.width
                                      ? textStyles.width.split("%")[0]
                                      : ""
                                  }
                                  onChange={(e) => {
                                    setTextStyles((prev) => {
                                      return {
                                        ...prev,
                                        width: e.target.value + "%",
                                      };
                                    });
                                  }}
                                />
                              </div>
                            </div>

                          </div>
                          <div className="row pt-3">

                            <div className="col-md-6 mt-2">
                              <div className="form-group">
                                <label className="labeltext">Postion from Top <span className="aesterik">*</span></label>
                                <input
                                  type="Number"
                                  name="blogTitle"
                                  className="form-control"
                                  placeholder="Service Title"
                                  value={
                                    textStyles.top
                                      ? textStyles.top.split("%")[0]
                                      : ""
                                  }
                                  onChange={(e) => {
                                    setTextStyles((prev) => {
                                      return {
                                        ...prev,
                                        top: e.target.value + "%",
                                      };
                                    });
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-md-6 mt-2">
                              <div className="form-group">
                                <label className="labeltext">Postion from left <span className="aesterik">*</span></label>
                                <input
                                  type="Number"
                                  name="blogTitle"
                                  className="form-control"
                                  placeholder="Service Title"
                                  value={
                                    textStyles.left
                                      ? textStyles.left.split("%")[0]
                                      : ""
                                  }
                                  onChange={(e) => {
                                    setTextStyles((prev) => {
                                      return {
                                        ...prev,
                                        left: e.target.value + "%",
                                      };
                                    });
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <!-- box1 --> */}

                  {/* // <!-- box2 -->  */}

                  <div className="box">
                    <div className="box-body boxpadding">
                      <div className="row pt-3">
                        <div className="col-md-6 mt-2">
                          <div className="form-group">
                            <label className="labeltext">Thumbnail Image <span className="aesterik">*</span></label>
                            <input
                              type="file"
                              // name="blogThumbImage"
                              accept="image/*"
                              // className="form-control"
                              className={`form-control ${errors.blogThumbImage ? "is-invalid" : ""
                                }`}
                              onChange={(e) => {
                                // setBlogThumbImage(e.target.files[0]);
                                // setErrors({ ...errors, blogThumbImage: "" });
                                const selectedThumbImage = e.target.files[0];
                                if (selectedThumbImage) {
                                  if (selectedThumbImage.size > 5 * 1024 * 1024) {
                                    showNotificationWithMessage('Image size should be less than 5MB', 'error');
                                    e.target.value = null;
                                  } else {
                                    setBlogThumbImage(selectedThumbImage);
                                    setErrors({ ...errors, blogThumbImage: "" });
                                  }
                                }
                              }}
                            />
                            {errors.blogThumbImage && (
                              <div className="invalid-input">{errors.blogThumbImage}</div>
                            )}
                          </div>
                        </div>

                        <div className="col-md-6 mt-2">
                          <div className="form-group">
                            <label className="labeltext">Detail Page Image <span className="aesterik">*</span></label>
                            <input
                              type="file"
                              // name="blogThumbImage"
                              accept="image/*"
                              // className="form-control"
                              className={`form-control ${errors.blogDetailImage ? "is-invalid" : ""
                                }`}
                              onChange={(e) => {
                                // setBlogDetailImage(e.target.files[0]);
                                // setErrors({ ...errors, blogDetailImage: "" });
                                const selectedDetailImage = e.target.files[0];
                                if (selectedDetailImage) {
                                  if (selectedDetailImage.size > 5 * 1024 * 1024) {
                                    showNotificationWithMessage('Image size should be less than 5MB', 'error');
                                    e.target.value = null;
                                  } else {
                                    setBlogDetailImage(selectedDetailImage);
                                    setErrors({ ...errors, blogDetailImage: "" });
                                  }
                                }
                              }}
                            />
                            {errors.blogDetailImage && (
                              <div className="invalid-input">{errors.blogDetailImage}</div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="row pt-4">
                        <div className="col-md-4 mt-2">
                          <div className="form-group">
                            <label className="labeltext">width of Image (%) <span className="aesterik">*</span></label>
                            <input
                              type="Number"
                              name="blogTitle"
                              className="form-control"
                              placeholder="Service Title"
                              value={
                                imageStyles.width ?
                                  imageStyles.width.split("%")[0]
                                  : ""
                              }
                              onChange={(e) => {
                                setImageStyles((prev) => {
                                  return {
                                    ...prev,
                                    width: e.target.value + "%",
                                  };
                                })
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-md-4 mt-2">
                          <div className="form-group">
                            <label className="labeltext">Postion from Top <span className="aesterik">*</span></label>
                            <input
                              type="Number"
                              name="blogTitle"
                              className="form-control"
                              placeholder="Service Title"
                              value={
                                imageStyles.top
                                  ? imageStyles.top.split("%")[0]
                                  : ""
                              }
                              onChange={(e) => {
                                setImageStyles((prev) => {
                                  return {
                                    ...prev,
                                    top: e.target.value + "%",
                                  };
                                });
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-md-4 mt-2">
                          <div className="form-group">
                            <label className="labeltext">Postion from left <span className="aesterik">*</span></label>
                            <input
                              type="Number"
                              name="blogTitle"
                              className="form-control"
                              placeholder="Service Title"
                              value={
                                imageStyles.left
                                  ? imageStyles.left.split("%")[0]
                                  : ""
                              }
                              onChange={(e) => {
                                setImageStyles((prev) => {
                                  return {
                                    ...prev,
                                    left: e.target.value + "%",
                                  };
                                });
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="box">
                    <div className="box-body boxpadding">
                      <div className="row">
                        <div className="col-md-12 mt-2">
                          <div className="form-group">
                            <label className="labeltext">Description <span className="aesterik">*</span></label>
                            {useMemo(
                              () => (
                                <JoditEditor
                                  ref={editor}
                                  config={editorConfig}
                                  value={blogContent}
                                  onChange={(newContent) => {
                                    setBlogContent(newContent);
                                    setErrors({ ...errors, blogContent: "" });
                                  }
                                  }
                                />
                              ),
                              []
                            )}
                            {errors.blogContent && (
                              <div className="invalid-input">{errors.blogContent}</div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <!-- action --> */}
                  <div className="box-footer mb-4">
                    <button
                      type="submit"
                      // onSubmit={handleSubmit}
                      onClick={e => scrollToError(errors, handleSubmit)}
                      className="btn btn-rounded btn_dark btn-outline btncourse"
                      style={{ padding: '6px 22px' }}
                    >
                      {/* <i className="ti-save-alt"></i>  */}
                      Save
                    </button>
                  </div>

                  {/* <!-- action --> */}
                </div>
              </div>
              {/* </form> */}
              <div style={{ width: "50%" }}>
                <ServiceCard
                  title={blogTitle}
                  backgrondColor={backgroundColor}
                  img={imageURL}
                  shortdescription={
                    shortInfo
                  }
                  textStyles={{
                    ...textStyles,
                  }}
                  imageStyles={{
                    ...imageStyles,
                  }}
                />
              </div>
            </div>
          </section>
        </div>

        {/* <!-- form --> */}

        {/* 		
      <!-- /.content --> */}
      </div>
    </>
  );
};

export default AddService;
